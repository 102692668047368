import { View, StyleProp, ViewStyle } from 'react-native';

import LayoutItem from '../LayoutItem';

import { StaticPageButtonType, DoubleColumnItems } from 'types';

import { commonStyles } from 'styles';

type Props = {
  items: DoubleColumnItems;
  onPress: (data: StaticPageButtonType) => void;
  style?: StyleProp<ViewStyle>;
};

function DoubleColumnItemsLayout({
  items,
  onPress,
  style
}: Props) {

  return (
    <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.smallMarginBottom, style]}>
      {
        items.map((item, i) => (
          <View style={[commonStyles.flexWrap, i === 0 ? commonStyles.smallMarginRight : commonStyles.smallMarginLeft]} key={i}>
            <LayoutItem item={item} onPress={() => onPress(item)} />
          </View>
        ))
      }
    </View>
  );
}

export default DoubleColumnItemsLayout;
