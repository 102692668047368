import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';

const apiUrl = `${SERVER_URL}/user`;

class UserService { 
  getDashboard = async (session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${session}/dashboard`);
      return res.data;
    } catch(error) {
      console.log(`error in userService / getDashboard method`, error);
      throw error;
    }
  }
}

export default UserService;