import Contents from '../contents';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';

const formConfig: Omit<LogicProps, 'control'>[] = [
  {
    type: 'text',
    name: 'legalName',
    rules: {
      required: {
        value: true,
        message: Contents.ownershipGroupScreen.validationErrors.legalNameRequired,
      },
    },
    textInputProps: {
      label: Contents.ownershipGroupScreen.placeholders.legalName,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'ein',
    rules: {
      required: {
        value: true,
        message: Contents.ownershipGroupScreen.validationErrors.einRequired,
      },
    },
    textInputProps: {
      label: Contents.ownershipGroupScreen.placeholders.ein,
      autoComplete: false
    },
  },
  {
    type: 'email',
    name: 'email',

    rules: {
      required: {
        value: true,
        message: Contents.ownershipGroupScreen.validationErrors.emailRequired
      },
      pattern: {
        value:
          /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
        message: Contents.ownershipGroupScreen.validationErrors.emailPattern
      },
    },
    textInputProps: {
      label: Contents.ownershipGroupScreen.placeholders.email,
      autoComplete: false
    },
  }
]
export default formConfig;
