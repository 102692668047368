import { View, StyleProp, ViewStyle } from 'react-native';

import LayoutItemComponent from '../LayoutItem';

import { LayoutItem, OneColumnItem } from 'types';

import { commonStyles } from 'styles';

type Props = {
  item: OneColumnItem;
  onPress: (data: LayoutItem) => void;
  style?: StyleProp<ViewStyle>;
};

function FullWidthItemLayout({
  item,
  onPress,
  style
}: Props) {

  return (
    <View style={[commonStyles.smallMarginBottom, style]}>
      <LayoutItemComponent item={item} onPress={() => onPress(item)} />
    </View>
  );
}

export default FullWidthItemLayout;
