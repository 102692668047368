import React, { useState, useEffect, useCallback } from 'react';
import { ScrollView, View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import type { NavigationProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';
import { TextInput, Button } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import * as Localization from 'expo-localization';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import dayjs from 'dayjs';


import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import ParentContactInfo from 'components/ParentContactInfo';
import DefaultButton from 'components/Button/DefaultButton';

import { createProfileFormConfig } from 'utils/FormConfig';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';
import { UserDataType, ParentContactInformationType, ScreensParamList, UserStoreType } from 'types';

import { commonStyles, componentsDefaultStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'createProfile'>;
  store: { userStore: UserStoreType };
};

function CreateProfile({
  navigation: { navigate },
  store: {
    userStore
  }
}: Props) {
  const [formConfig, setFormConfig] = useState<Omit<LogicProps, 'control'>[]>([...createProfileFormConfig.names,
    createProfileFormConfig.phoneNumber, createProfileFormConfig.email, createProfileFormConfig.timezone])
  const [birthDate, setBirthDate] = useState<Date | undefined>(undefined);
  const [birthDateString, setBirthDateString] = useState<string>('');
  const [underAge, setUnderAge] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [parentContactInfo, setParentContactInfo] = useState<ParentContactInformationType>({
    parentName: '',
    parentPhoneNumber: '',
    parentEmail: ''
  });

  const { user, updateUser, session } = userStore;
  const {control, setFocus, handleSubmit, reset, formState: { isDirty, isValid }} = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      timezone: ''
    },
    mode: 'onChange',
  });
  const toast = useToast();


  const onDismissSingle = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpen(false);
      setBirthDate(params.date);
    },
    [setOpen, setBirthDate]
  );

  const navigateAfterUpdate = () => {
    if (!user.emailVerified) {
      navigate('verificationForm', { type: 'email', value: user.email });
      return;
    }
    if (!user.phoneNumberVerified) {
      navigate('verificationForm', { type: 'phone', value: user.phoneNumber });
      return;
    }
    navigate('dashboard');
  }

  const isFormValid = () => {
    if (!birthDate) {
      toast.show(Contents.profileScreen.validationErrors.birthDate, { type: 'error' });
      return false;
    }
    if (underAge) {
      if (!parentContactInfo.parentName) {
        toast.show(Contents.profileScreen.validationErrors.parentName, { type: 'error' });
        return false;
      }
      if (!parentContactInfo.parentPhoneNumber) {
        toast.show(Contents.profileScreen.validationErrors.parentPhoneNumber, { type: 'error' });
        return false;
      }
      if (!parentContactInfo.parentEmail) {
        toast.show(Contents.profileScreen.validationErrors.parentEmail, { type: 'error' });
        return false;
      }
    }
    return true;
  }
  const updateProfile = async (body: any) => {
    try {
      if (isFormValid() === false) return;

      let newData: UserDataType = {
        name: [body.firstName, body.lastName],
        phoneNumber: body.phoneNumber,
        email: body.email,
        timezone: body.timezone,
        updatedAt: new Date(),
      };

      if (birthDate) newData = {...newData, birthDate};
      if (underAge) newData = {...newData, ...parentContactInfo};
      await updateUser(user.id, newData, session);

      toast.show(Contents.createProfileScreen.toast.updateSuccess, { type: 'success' });
      navigateAfterUpdate();
    } catch (error) {
      console.log('Update profile error', error);
      toast.show(Contents.createProfileScreen.toast.updateError, { type: 'error' });
      return;
    }
  };

  const setupFormConfiguration = () => {
    let modifiedPhoneNumberConfig = {...createProfileFormConfig.phoneNumber};
    let modifiedEmailConfig = {...createProfileFormConfig.email};
    if (!user.phoneNumber) {
      modifiedPhoneNumberConfig = {
        ...modifiedPhoneNumberConfig,
        textInputProps: {
          label: Contents.createProfileScreen.placeholders.phoneNumber,
          autoComplete: false,
          disabled: false
        }
      }
    }
    if (!user.email) {
      modifiedEmailConfig = {
        ...modifiedEmailConfig,
        textInputProps: {
          label: Contents.createProfileScreen.placeholders.email,
          autoComplete: false,
          disabled: false
        }
      }
    }
    setFormConfig([...createProfileFormConfig.names, modifiedPhoneNumberConfig, modifiedEmailConfig, createProfileFormConfig.timezone]);
  }

  const initForm = () => {
    setupFormConfiguration();

    reset({
      firstName: user.name ? user.name[0] : '',
      lastName: user.name ? user.name[1] : '',
      email: user.email,
      phoneNumber: user.phoneNumber,
      timezone: user.timezone ? user.timezone : Localization.timezone
    });
    if (user.birthDate && dayjs(birthDate).isValid()) setBirthDate(dayjs(user.birthDate).toDate());
    setParentContactInfo({
      parentName: user.parentName,
      parentPhoneNumber: user.parentPhoneNumber,
      parentEmail: user.parentEmail
    })
  }

  useEffect(() => {
    setBirthDateString(birthDate ? dayjs(birthDate).format('DD/MM/YYYY') : '');
    if (birthDate) {
      const workableDate = dayjs(birthDate).add(18, 'year');
      setUnderAge(workableDate > dayjs());
    }
  }, [birthDate]);

  useEffect(() => {
    if (user) {
      initForm();
    }
  }, [user]);

  return (
    <View style={commonStyles.viewport}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
          <View style={commonStyles.container}>
            <HeadViewWithSubTitle
              title={ Contents.createProfileScreen.txt.header }
              subTitle={ Contents.createProfileScreen.txt.subheader }
              />
            <View style={commonStyles.formWrapper}>
              <View style={commonStyles.flexGrow}>
                <FormBuilder
                  control={control}
                  setFocus={setFocus}
                  formConfigArray={formConfig}
                />
                <TouchableWithoutFeedback onPress={() => setOpen(true)}>
                  <TextInput
                    mode='outlined'
                    label={ Contents.profileScreen.placeholders.birthDate }
                    value={birthDateString}
                    />
                </TouchableWithoutFeedback>
                <DatePickerModal
                  locale="en"
                  mode="single"
                  visible={open}
                  onDismiss={onDismissSingle}
                  date={ birthDate }
                  onConfirm={onConfirmSingle}
                  />

                {
                  underAge &&
                    <ParentContactInfo
                      parentContactInfo={parentContactInfo}
                      setParentContactInfo={setParentContactInfo}
                    />
                }
              </View>
              <View style={commonStyles.buttonContainer}>
                <DefaultButton
                  labelStyle={[!isValid ? componentsDefaultStyles.DisabledButtonLabel : componentsDefaultStyles.ButtonLabel]}
                  disabled={!isValid || !birthDate}
                  onPress={handleSubmit(updateProfile)}>
                  { Contents.common.btn.save }
                </DefaultButton>
              </View>
            </View>
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

export default withErrorBoundary(inject('store')(observer(CreateProfile)));
