import { useEffect, useState, useCallback } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import { Button } from 'react-native-paper';
import { NavigationProp, RouteProp, useIsFocused } from '@react-navigation/native';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import DropDown from 'react-native-paper-dropdown';
import { useToast } from 'react-native-toast-notifications';
import { inject, observer } from 'mobx-react';
import AwesomeAlert from 'react-native-awesome-alerts';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';
import BackButton from 'components/Button/BackButton';
import Loading from 'components/Loading';

import BaseDataService from 'lib/Services/baseData.service';
import UserTaskService from 'lib/Services/userTask.service';
import UserStoreService from 'lib/Services/userStore.service';
import { ScreensParamList, GroupStoreType, OptionType, Store, ConfirmationDialogType, UserStoreType, RouteStackParamList } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupUserForm'>;
  route: RouteProp<RouteStackParamList, 'groupUsersList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

const userGroupDataService = new BaseDataService('user_group');
const userTaskService = new UserTaskService();
const userStoreService = new UserStoreService();

const groupDataService = new BaseDataService('group');

function GroupUserForm(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route } = props;
  const { params: { groupId } } = route;
  const { store: { groupStore, userStore  } } = props;
  const { activeGroup, activeGroupUser, fetchGroupUsers, roles, managers, groupStores, isCurrentUserManager, isCurrentUserOwner } = groupStore;
  const { session } = userStore;

  const [loading, setLoading] = useState<boolean>(false);
  const [onboardingStatus, setOnboardingStatus] = useState<string>('');
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState<boolean>(false);
  const [storeDropdownValues, setStoreDropdownValues] = useState<OptionType<string>[]>([]);
  const [selectedStores, setSelectedStores] = useState<string>('');
  const [formConfig, setFormConfig] = useState<Omit<LogicProps, 'control'>[]>([]);
  const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogType>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [ableToUpdate, setAbleToUpdate] = useState<boolean>(false);
  const {control, setFocus, handleSubmit, reset, formState: { isValid }} = useForm({
    defaultValues: {
      roleId: activeGroupUser?.roleId,
    },
    mode: 'onChange',
  });
  const toast = useToast();
  const isFocused = useIsFocused();

  // - Get all tasks list
  // - Get tasks selected for group
  const init = useCallback(async () => {
    if (!activeGroupUser) return;
    setLoading(true);
    setAbleToUpdate(false);
    let roleOptions = roles.map((role: any) => ({value: role.id , label: role.name}))
    roleOptions = [{ value: '', label: '' }, ...roleOptions];

    setFormConfig([{
      type: 'select',
      name: 'roleId',
      defaultValue: activeGroupUser?.roleId,
      textInputProps: {
        label: Contents.group.placeholders.role,
        autoComplete: false,
      },
      options: roleOptions,
    }]);

    const data = await userTaskService.list(activeGroupUser.userId, activeGroupUser.groupId, session);
    if (data && Object.keys(data).length > 0) {
      const unfinishedTasks = Object.keys(data).map((key: string) => data[key].done === false);
      setOnboardingStatus(unfinishedTasks.length > 0 ? 'Onboarding' : 'Crew')
    } else {
      setOnboardingStatus('');
    }
    reset({ roleId: activeGroupUser.roleId || '' });

    const userStoreList = await userStoreService.list(activeGroupUser.userId, activeGroupUser.groupId, session);
    const storeIds = userStoreList.map((store: any) => store.storeId);
    setSelectedStores(storeIds.join(','));

    if (activeGroupUser.isManager)
      setAbleToUpdate(isCurrentUserOwner);
    else
      setAbleToUpdate(isCurrentUserManager || isCurrentUserOwner);

    setLoading(false);
  }, [activeGroupUser]);

  // Save Button Click event handler
  const onUpdateUserForm = async (body: any) => {
    setLoading(true);
    try {
      await userGroupDataService.update(activeGroupUser.id, body, session)
      const stores = selectedStores.split(',').filter(store => !!store && store.length);
      await userStoreService.saveStores(activeGroupUser.userId, activeGroupUser.groupId, stores);
      toast.show(Contents.group.toast.userUpdateSuccess, { type: 'success' });
    } catch (error) {
      console.log('Update user error', error);
      return;
    }
    setLoading(false);
  };

  /* User row action handlers, openining confirmation dialog */
  const onTriggerSetManager = () => {
    setConfirmationDialogType('setManager');
    setConfirmationDialogMessage(Contents.group.confirm.setAsManager);
  }

  const onTriggerRevokeManager = () => {
    setConfirmationDialogType('revokeManager');
    setConfirmationDialogMessage(Contents.group.confirm.revokeManager);
  }

  const onTriggerDeleteUser = () => {
    setConfirmationDialogType('delete');
    setConfirmationDialogMessage(Contents.group.confirm.removeUser);
  }


  // Main User Action handler
  const onHandleDefaultUserActions = async() => {
    setLoading(true);
    const managerIds = managers.map(manager => manager.id);
    switch(confirmationDialogType) {
      case 'delete':
        await userGroupDataService.delete(activeGroupUser.id, session);
        break;
      case 'setManager':
        let newManagerIds = [...new Set([...managerIds, activeGroupUser.userId])];
        await groupDataService.update(activeGroup.id, { managers: newManagerIds }, session);
        break;
    }
    afterAction();
  }

  const onRevokeManager = async () => {
    setLoading(true);
    const managerIds = managers.map(manager => manager.id);
    let updatedManagerIds = managerIds.filter(managerId => managerId !== activeGroupUser.userId);
    await groupDataService.update(activeGroup.id, { managers: updatedManagerIds }, session);
    afterAction();
  }

  const afterAction = async() => {
    fetchGroupUsers(groupId, session);
    setConfirmationDialogType('');
    navigate('groupUsersList', { groupId });
  }

  useEffect(() => {
    if (groupStores) {
      const options = groupStores.map((store: Store) => ({ label: store.name, value: store.id }));
      setStoreDropdownValues(options);
    }
  }, [groupStores]);

  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);

  if (!activeGroupUser) return null;

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle
          title={ Contents.group.header.editGroupUserRole }

          />
        <View style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical, commonStyles.flexGrow]}>
          <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
            <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
              Status
            </Text>
            <Text style={commonStyles.baseFontSize}>
              { activeGroupUser.user.name.join(' ') || '' }
            </Text>
          </View>
          <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
            <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
              Email
            </Text>
            <Text style={commonStyles.baseFontSize}>
              { activeGroupUser.user.email }
            </Text>
          </View>
          <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
            <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
              Phone
            </Text>
            <Text style={commonStyles.baseFontSize}>
              { activeGroupUser.user.phoneNumber }
            </Text>
          </View>
          <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
            <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
              Status
            </Text>
            <Text style={commonStyles.baseFontSize}>
              { onboardingStatus }
            </Text>
          </View>
          <View style={[commonStyles.formWrapper, commonStyles.basePaddingHorizontal, commonStyles.flexGrow]}>
            {
              ableToUpdate &&
                <>
                  <FormBuilder
                    control={control}
                    setFocus={setFocus}
                    formConfigArray={formConfig}
                  />
                  <DropDown
                    label={'Access to Stores'}
                    mode={'outlined'}
                    visible={showMultiSelectDropDown}
                    showDropDown={() => setShowMultiSelectDropDown(true)}
                    onDismiss={() => setShowMultiSelectDropDown(false)}
                    value={selectedStores}
                    setValue={setSelectedStores}
                    list={storeDropdownValues}
                    multiSelect
                  />
                </>
            }
          </View>
          <View style={commonStyles.buttonContainer}>
            {
              isCurrentUserOwner ?
                activeGroupUser.isManager ?
                    <DefaultButton onPress={onTriggerRevokeManager} disabled={!isValid} fullWidth>
                      { Contents.group.btn.revokeManager }
                    </DefaultButton>
                  :
                    <DefaultButton onPress={onTriggerSetManager} disabled={!isValid} fullWidth>
                      { Contents.group.btn.setAsManager }
                    </DefaultButton>
              :
                <></>
            }
            {
              ableToUpdate &&
              <DefaultButton onPress={handleSubmit(onUpdateUserForm)} disabled={!isValid} fullWidth>
                { Contents.common.btn.save }
              </DefaultButton>
            }
            {
              /* Only Manager or Owner can remove non-manager users */
              ableToUpdate &&
                <DangerousActionButton onPress={onTriggerDeleteUser} fullWidth>
                  { Contents.group.btn.removeUser }
                </DangerousActionButton>
            }
          </View>
        </View>
        <BackButton />
        <AwesomeAlert
          show={!!activeGroupUser && (confirmationDialogType === 'setManager' || confirmationDialogType === 'delete')}
          showProgress={false}
          title={ Contents.store.confirm.title }
          message={ confirmationDialogMessage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogType('');
          }}
          onConfirmPressed={onHandleDefaultUserActions}
        />
        <AwesomeAlert
          show={confirmationDialogType === 'revokeManager'}
          showProgress={false}
          title={ Contents.store.confirm.title }
          message={ confirmationDialogMessage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogType('');
          }}
          onConfirmPressed={onRevokeManager}
        />
        { loading ? <Loading /> : <></> }
      </KeyboardAvoidingView>
    </View>
  );
}

const GroupUserFormWithStore = withErrorBoundary(inject('store')(observer(GroupUserForm)));
export default GroupUserFormWithStore;
