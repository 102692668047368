import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';

import MainNavigator from './MainNavigator';
import RightDrawerContent from './RightDrawerContent';
const Drawer = createDrawerNavigator();

export default function MiscNavigator() {
  return (
    <NavigationContainer>
      <Drawer.Navigator
        id='RightDrawer'
        screenOptions={{ headerShown: false, drawerPosition: 'right' }}
        drawerContent={props => <RightDrawerContent {...props} />}
        drawerType="back"
        drawerPosition="right">
        <Drawer.Screen name="Home" component={MainNavigator} />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};
