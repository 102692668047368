import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';

const apiUrl = `${SERVER_URL}/userStore`;

class UserStoreService {
  list = async (userId: string, groupId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/list/${userId}/${groupId}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in UserStore / list method`, error);
      return null;
    }
  }


  saveStores = async (userId: string, groupId: string, stores: string[], session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/${userId}/${groupId}`, { stores }, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in UserStore / saveStores method`, error);
      return null;
    }
  }

}

export default UserStoreService;