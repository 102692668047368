import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';
const apiUrl = `${SERVER_URL}/page`;

class PageService {
  getPages = async (type: string, typeId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${type}/${typeId}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in PageService / getPages method`, error);
      return null;
    }
  }

  createPage = async (type: string, typeId: string, newData: Object, session: string) => {
    try {
      const url = `${apiUrl}/${type}/${typeId}`;
      const res = await axios.put(url, newData, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in PageService createPage method`, error);
      return null;
    }
  };

  updatePage = async(type: string, typeId: string, id: string, newData: Object, session: string) => {
    try {
      const url = `${apiUrl}/${type}/${typeId}/${id}`;
      const res = await axios.put(url, newData, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in PageService updatePage method`, error);
      return null;
    }
  }

  deletePage = async(type: string, typeId: string, id: string, session: string) => {
    try {
      const url = `${apiUrl}/${type}/${typeId}/${id}`;
      const res = await axios.delete(url, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in PageService deletePage method`, error);
      return null;
    }
  }

}

export default PageService;