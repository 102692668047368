import { StyleSheet, Dimensions, Platform } from 'react-native';

export default StyleSheet.create({
	noMarginVertical: {
		marginTop: '0',
		marginBottom: '0',

	},
	formWrapper: {
		marginTop: 80,
	},
	subtitle: {
		fontSize: '15px',
		textAlign: 'center',
		marginTop: '20px'
	},
	button: {
		width: Platform.OS === 'web' ? '200px' : 200,
		alignSelf: 'center'
	},
	input: {
		textAlign: 'center'
	},
	confirmation: {
		marginTop: '40px',
		textAlign: 'center'
	},
	subheadingView: {
		marginTop: 10,
		marginBottom: 50
	},
	setupOwnerGroupButton: {
		width: 250,
		marginTop: 30,
		alignSelf: 'center'
	}
});
