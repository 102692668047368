import { useState, useEffect } from 'react';
import { View, Text, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native'
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import Markdown from 'react-native-markdown-display';
import dayjs from 'dayjs';

import DefaultButton from 'components/Button/DefaultButton';
import Loading from 'components/Loading';

import { Task, User, UserStoreType } from 'types';
import UserTaskService from 'lib/Services/userTask.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';


import Contents from 'utils/contents';

type Props = {
  task: Task,
  user: User,
  forceRefresh: (updatedTask: Task) => void,
  store: { userStore: UserStoreType };
};


const userTaskService = new UserTaskService();

function IFrameTaskScreen(props: Props) {
  const { task, user, store, forceRefresh, open } = props;
  const { userStore } = store;
  const { session } = userStore;
  const [underAge, setUnderAge] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [downloadURL, setDownloadURL] = useState<string>('');

  const navigation = useNavigation();
  const toast = useToast();

  const errorHandler = (error: any | null) => {
    console.error('Confidentality Agreement callEndpoint', error);
    setLoading(false);
    toast.show(Contents.onboarding.toast.commonError, { type: 'error' });
    return;
  }

  // Before complete Button
  const onCallEndpoint = async () => {
    if (task.endpoint && !task.done) {
      setLoading(true);
      let res;
      try {
        res = await userTaskService.callEndpoint(task.endpoint, session);
      } catch(error) {
        errorHandler(error);
        return;
      }

      if (!res) {
        errorHandler('BAD API');
        return;
      }

      if (res.sign_url) {
        setLoading(false);
        navigation.navigate('nativeWebView', { label: task.name, target: res.sign_url });
      } else {
        if (!task.done && res.signed) {
          const res = await userTaskService.markDone(task.user_task_id, task.id, session);
          if (res.done) {
            toast.show(Contents.onboarding.toast.alreadySigned);
            forceRefresh( {...task, done: true} );
          }
        }
        setLoading(false);
      }
    }
  }

  // After complete button click handler
  const onDownload = async () => {
    if (downloadURL) {
      Linking.openURL(downloadURL)
    } else {
      setLoading(true);
      const res = await userTaskService.callEndpoint(task.endpoint, session);
      if (res && res.download_url) {
        Linking.openURL(res.download_url);
        setDownloadURL(res.download_url);
      }
      setLoading(false)
    }
  }

  const init = async () => {
    if (!open || !task || !task.active || task.done ||  !task.endpoint || !task.auto_run) return;
    setLoading(true);
    try {
      const res = await userTaskService.callEndpoint(task.endpoint, session);
      if (res) setDownloadURL(res.download_url);
    } catch(error) {
      errorHandler(error);
      return;
    }
    setLoading(false);
  }

  useEffect(() => {
    if (user && user.birthDate && dayjs(user.birthDate).isValid()) {
      const workableDate = dayjs(user.birthDate).add(18, 'year');
      setUnderAge(workableDate > dayjs());
    }
  }, [user?.birthDate]);

  useEffect(() => {
    init();
  }, [task]);

  return (
    <View style={[commonStyles.basePaddingVertical, commonStyles.basePaddingHorizontal, commonStyles.relative]}>

      { (!task.done && task.description) &&
          <Markdown style={commonStyles.markdown}>{task.description}</Markdown>
      }

      { (task.done && task.description_after) &&
          <Markdown style={commonStyles.markdown}>{task.description_after}</Markdown>
      }
      {
        (!task.done && task.before_complete_btn) &&
        <DefaultButton style={[commonStyles.baseMarginTop, commonStyles.smallMarginBottom]} onPress={onCallEndpoint}>
          { underAge ? 'Send Agreement To Guardian' : task.before_complete_btn }
        </DefaultButton>
      }
      {
        (task.done && task.after_complete_btn) &&
        <DefaultButton style={[commonStyles.baseMarginTop, commonStyles.smallMarginBottom]} onPress={onDownload}>
          { task.after_complete_btn }
        </DefaultButton>
      }
      {
        isLoading && <Loading />
      }
    </View>
  );
}

const IFrameTaskScreenWithStore = withErrorBoundary(inject('store')(observer(IFrameTaskScreen)));
export default IFrameTaskScreenWithStore
