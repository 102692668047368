import {StyleProp, ViewStyle, TouchableOpacity} from 'react-native';
import {Button} from 'react-native-paper';

import {Children} from 'types';
import {commonStyles, componentsDefaultStyles} from 'styles';
import styles from "../../Screens/Common/styles";

type Props = {
    onPress: () => void;
    style?: StyleProp<ViewStyle>;
    disabled?: boolean;
    fullWidth?: boolean;
    mode?: string;
    children: Children;
};

function DefaultButton(
    {
        onPress,
        style,
        mode = 'contained',
        children,
        disabled = false,
        fullWidth = false
    }: Props) {

    return (
        <TouchableOpacity onPress={onPress}>
            <Button
                mode={mode}
                style={[componentsDefaultStyles.Button, commonStyles.smallMarginBottom, 
                    disabled ? commonStyles.buttonDisabled : '',
                    fullWidth ? commonStyles.fullWidth : '',
                    style]}
                labelStyle={[
                    disabled ? commonStyles.buttonDisabled : commonStyles.flexWrap
                ]}
                onPress={onPress}>
                {children}
            </Button>
        </TouchableOpacity>
    );
}

export default DefaultButton;
