import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import { inject, observer } from 'mobx-react';
import {View, Animated, Platform} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import type {RouteProp} from '@react-navigation/native';
import {WebView} from 'react-native-webview';

import GlobalLoading from 'components/GlobalLoading';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import {commonStyles} from 'styles';
import {ScreensParamList, RouteStackParamList, UserStoreType} from 'types';

type Props = {
    navigation: NavigationProp<ScreensParamList, 'nativeWebView'>;
    route: RouteProp<RouteStackParamList, 'nativeWebView'>;
    store: { userStore: UserStoreType };
};


const NativeWebView = (props: Props): JSX.Element => {
    const {route, store} = props;
    let {params: {target}} = route;
    const { userStore: { user } } = store;

    const zeroOpacity = new Animated.Value(0);
    const [opacity, setOpacity] = useState(zeroOpacity);
    const [loading, setLoading] = useState<boolean>(false);
    const isFocused = useIsFocused();
    const targetRef = useRef();

    const onLoad = () => {
        setLoading(false);
        Animated.timing(opacity, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    }


    useEffect(() => {
        if (targetRef.current !== target) {
            targetRef.current = target;
            setOpacity(zeroOpacity); 
            setLoading(true);
        }
    }, [isFocused, target]);


    if (target.includes('jotform.com')) {

        let to_add = '?name[0]='+user.name[0]+'&name[1]='+user.name[1]
        if (user.phoneNumber && user.phoneType == 'mobile' && user.phoneVerified) {
            to_add += '&cell='+ user.phoneNumber;
        }
        if (user.email && user.emailVerified) {
            to_add += '&email='+ user.email;
        }
        target = target + to_add
    }

    return (
        <View style={[commonStyles.viewport]}>
            <Animated.View testId='NativeWebViewContainer' style={{opacity, flexGrow: 1, width: '100%'}}>
                {
                    (Platform.OS === 'web') ?
                        <iframe src={target} style={styles} onLoad={onLoad}/>
                        :
                        <WebView
                            source={{uri: target}}
                            originWhitelist={['*']}
                            javaScriptEnabled
                            sharedCookiesEnabled/>
                }
            </Animated.View>
            {loading && <GlobalLoading/>}
        </View>
    );

};

const styles: CSSProperties = {
    height: '100%',
    width: '100%',
    border: "none"
};

const NativeWebViewWithStore = withErrorBoundary(inject('store')(observer(NativeWebView)));
export default NativeWebViewWithStore;
