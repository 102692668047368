import { Text } from "react-native";
import SkeletonLoader from "expo-skeleton-loader";

import CardSkeleton from './CardSekelton'

import { commonStyles } from 'styles';

type GroupStoreViewSkeletonProps = {
  heading: string,
}

const GroupStoreViewSkeleton: React.FC<GroupStoreViewSkeletonProps> = ({ heading }) => (
  <SkeletonLoader style={[commonStyles.noPaddingTop]}>
    <SkeletonLoader.Container style={[commonStyles.baseMarginBottom]}>
      <Text style={[commonStyles.headText]}>
        { heading }
      </Text>
    </SkeletonLoader.Container>
    <SkeletonLoader.Container style={[commonStyles.textCenter, commonStyles.baseMarginBottom]}>
      <SkeletonLoader.Item style={{ width: 250, height: 20, marginHorizontal: 'auto' }} />
    </SkeletonLoader.Container>
    
    <CardSkeleton />
    <CardSkeleton />
    <CardSkeleton />
    
  </SkeletonLoader>
);

export default GroupStoreViewSkeleton;