export default {
  txt: {
    header: 'Let\'s get you logged in!',
  },
  placeholders: {
    verification: 'Verification Code'
  },
  btn: {
    reset: 'Reset',
    resendCode: 'Resend Code',
    confirm: 'Verify Code'
  },
  toast: {
    verifyUserSessionSuccess: 'SUCCESS! Store the details in the session and log the user in!'
  }
}
