import { StyleSheet } from 'react-native';
import { Colors } from 'styles';

export default StyleSheet.create({
  borderedForm: {
    borderColor: Colors.primaryGreyColor,
    borderWidth: 1,
    borderStyle: 'solid'
  }
});
