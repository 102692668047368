import { useState, useEffect } from 'react';
import { Platform, KeyboardAvoidingView, View } from 'react-native';
import { Text, TextInput, Button } from 'react-native-paper';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import validator from 'validator';
import DefaultButton from 'components/Button/DefaultButton';

import AuthService from '../../lib/Services/auth.service';
import {
	ScreensParamList,
	UserStoreType,
	RouteStackParamList
} from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';
import styles from './styles';

type Props = {
	navigation: NavigationProp<ScreensParamList, 'verificationForm'>;
	route: RouteProp<RouteStackParamList, 'verificationForm'>;
	store: { userStore: UserStoreType };
};

const authService = new AuthService();

function VerificationForm(props: Props) {
	const {
		navigation: { navigate },
		store: {
			userStore: { user }
		},
		route: {
			params: { type, value }
		}
	} = props;

	const toast = useToast();
	const [verificationDisabled, setVerificationDisabled] = useState(true);
	const [verificationType, setVerificationType] = useState('');
	const [verificationText, setVerificationText] = useState('');

	const verifyVerificationType = function (text: string) {
		setVerificationText(text);
		if (type) {
			setVerificationDisabled(false);
			return; // If vertification type comes from props, we don't bother to verifyVerificationType
		}
		if (validator.isEmail(text)) {
			setVerificationType('email');
			setVerificationDisabled(false);
		} else if (validator.isMobilePhone(text)) {
			setVerificationType('phone');
			setVerificationDisabled(false);
		} else {
			setVerificationType('');
			setVerificationDisabled(true);
		}
	};

	const onSubmit = async () => {
		if (!verificationType) return false;
		const res = await authService.sendAuthToken(
			verificationType,
			verificationText
		);
		console.log({ res });
		if (res.status === 'pending') {
			toast.show(
				Contents.verificationFormScreen.txt['code_sent_' + verificationType],
				{ type: 'warning' }
			);
			navigate('confirmationCode', { verificationType, verificationText });
		} else {
			toast.show(res.message, { type: 'warning' });
		}
	};

	useEffect(() => {
		setVerificationType(type);
		if (type) setVerificationDisabled(false);
	}, [type]);

	useEffect(() => {
		setVerificationText(value);
	}, [value]);
	return (
		<View style={commonStyles.viewport}>
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
				style={commonStyles.keyboardAvoidingView}>
				<View style={[commonStyles.formContainer, commonStyles.containerVerticalCenter]}>
					<View style={[commonStyles.headViewWithNoMargin]}>
						<Text style={commonStyles.headText}>
							{user
								? Contents.verificationFormScreen.txt.verification_header
								: Contents.verificationFormScreen.txt.header}
						</Text>
					</View>
					<View style={[styles.formWrapper]}>
						<TextInput
							mode='outlined'
							left={
								verificationType === 'email' ? (
									<TextInput.Icon name={verificationType} />
								) : verificationType === '' ? (
									<TextInput.Icon name='door' />
								) : (
									<TextInput.Icon name='phone' />
								)
							}
							label={
								verificationType === ''
									? Contents.verificationFormScreen.placeholders
											.verificationType
									: Contents.verificationFormScreen.placeholders[
											verificationType
									  ]
							}
							value={verificationText}
							onChangeText={(str) => verifyVerificationType(str)}
							onSubmitEditing={onSubmit}
							returnKeyType='done'
						/>
					</View>
					<DefaultButton
						onPress={onSubmit}
						style={[commonStyles.largeMarginTop, { alignSelf: 'center' }]}
						disabled={verificationDisabled}>
						{Contents.verificationFormScreen.btn.verify}
					</DefaultButton>
				</View>
			</KeyboardAvoidingView>
		</View>
	);
}

const VerificationFormWithStore = withErrorBoundary(inject('store')(observer(VerificationForm)));
export default VerificationFormWithStore;
