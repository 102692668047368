import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { useNavigation, ParamListBase } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { inject, IWrappedComponent, observer } from 'mobx-react';

import Contents from 'utils/contents';
import { Group, GroupStoreType, UserStoreType } from 'types';
import { commonStyles } from 'styles';
import InvitedService from '../lib/Services/invited.service';
import UserTaskService from '../lib/Services/userTask.service';


interface OwnProps {
  group?: Group;
};
interface StoreProps {
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
}

type Props = OwnProps & StoreProps;
const invitedService = new InvitedService();
const userTaskService = new UserTaskService();

function DashboardInviteCardComponent({
  group,
  store: {
    userStore: { user, setLoading, session },
    groupStore: { fetchGroup }
  }
}: Props) {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()


  const onAcceptInvitation = async () => {
    if (group) {
      setLoading(true);
      const res = await invitedService.acceptInvite(group.id, user.id, session);
      if (res.accepted) {
        fetchGroup(group.id, session);
        await userTaskService.setupOnboarding(user.id, group.id, session);
        setLoading(false);
        navigation.navigate('groupOnboarding', { groupId: group.id })
      } else
        setLoading(false);
    }
  }

  return (
    <View style={[commonStyles.basePaddingHorizontal, commonStyles.baseMarginBottom]} testID="dashboard-invite-card">
      <View style={[commonStyles.shadowedView, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical, commonStyles.whiteBackground]}>
        <View style={commonStyles.spaceBetween}>
          <Text style={commonStyles.normalText}>{ `You have been invited as one of the crew for ${group?.legalName}` }</Text>
        </View>
        <View style={commonStyles.flexEnd}>
          <TouchableWithoutFeedback onPress={ onAcceptInvitation }>
            <Text style={commonStyles.buttonText}>{ Contents.dashboardScreen.cardAction.accept }</Text>
          </TouchableWithoutFeedback>
        </View>
      </View>
    </View>
  );
}
const DashboardInviteCard = inject('store')(observer(DashboardInviteCardComponent));
export default DashboardInviteCard as typeof DashboardInviteCard & IWrappedComponent<Props>;
