import { View, ScrollView } from 'react-native';
import type { NavigationProp } from '@react-navigation/native';

import { ColumnItemType } from 'types';
import SectionLayout from 'components/ItemLayout/SectionLayout';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';

const blocksData: ColumnItemType[] = [
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.ballingTimesReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/13', linkType: 'internal' },
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.mixingTimesReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/14', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.cashTipReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/13', linkType: 'internal' },
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.cashDepositReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/14', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.managerialReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/13', linkType: 'internal' },
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.shiftLeadReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/14', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.shipmentReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/13', linkType: 'internal' },
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.reimbursementRequest,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/14', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.ingredientsCalendar,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/13', linkType: 'internal' },
      {
        type: 'link', text: Contents.staticPageScreen.button.managementTools.MStats,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/14', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'half',
    items: {
      type: 'link', text: Contents.staticPageScreen.button.managementTools.cookieCrewInternal,
      link: 'https://www.jotform.com/app/cookiecrew/crewapp/55', linkType: 'internal'
    }
  },
];

const managementToolsSectionData = {
  title: Contents.dashboardScreen.text.managementTools,
  description: Contents.dashboardScreen.text.managementToolsHelp,
  blocksData
}

function ManagementTools() {
  return (
    <View style={[commonStyles.viewport]}>
      <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
        <View style={commonStyles.container}>
          <SectionLayout sectionData={managementToolsSectionData} />
        </View>
      </ScrollView>
    </View>
  );
}

export default withErrorBoundary(ManagementTools);
