import { observable, action, computed, makeObservable, runInAction } from 'mobx';

import StoreService from '../lib/Services/store.service';
import PageService from '../lib/Services/page.service';
import RoleService from '../lib/Services/role.service';

import { Store, PageRecordType, SectionRecordType, StoreUser, StoreRole } from 'types';

import { fillUserCollection, sortStoreUsers } from 'utils';

class StoreStore {
  storeService: StoreService;
  pageService: PageService;
  roleService: RoleService;
  @observable activeStore: Store | null = null;
  @observable pages: PageRecordType[] = [];
  @observable activePage: PageRecordType | null = null;
  @observable activeStoreUser: StoreUser | null = null;
  @observable roles: StoreRole[] = [];
  @observable isCurrentUserManager: boolean = false;
  @observable isCurrentUserOwner: boolean = false;
  @observable activeStoreUsers: StoreUser[] = [];
  @observable activeStoreOwners: StoreUser[] = [];
  @observable activeStoreManagers: StoreUser[] = [];

  
  constructor() {
    makeObservable(this);
    this.storeService = new StoreService();
    this.pageService = new PageService();
    this.roleService = new RoleService();
  }
  fetchStore = async (storeId: string, session: string) => {
    try {
      const storeDetail = await this.storeService.fetchStoreDetail(storeId, session);
      runInAction(() => {
        this.activeStore = storeDetail;
        this.isCurrentUserManager = storeDetail.isManager;
        this.isCurrentUserOwner = storeDetail.isOwner;
      });
    } catch(error) {
      console.log("error in StoreStore / fetchStore", error);
    }
  };

  fetchStoreUsers = async (storeId: string, session: string) => {
    try {
      let storeUsers = await this.storeService.getStoreUsers(storeId, session);
      storeUsers = storeUsers.sort(sortStoreUsers);
      let owners: StoreUser[] = [], managers: StoreUser[] = [];
      if (this.activeStore && storeUsers) {
        if (this.activeStore.owners) {
          owners = fillUserCollection(this.activeStore.owners, storeUsers, 'userId');
          owners = owners.sort(sortStoreUsers);
        }
        if (this.activeStore.managers) {
          managers = fillUserCollection(this.activeStore.managers, storeUsers, 'userId');
          owners = owners.sort(sortStoreUsers);
        }
      }
      runInAction(() => {
        this.activeStoreUsers = storeUsers;
        this.activeStoreOwners = owners;
        this.activeStoreManagers = managers;
      });
    } catch(error) {
      console.log("error in StoreStore / fetchStoreUsers", error);
    }
  }

  fetchStorePages = async (storeId: string, session: string) => { 
    const storePages = await this.pageService.getPages('store', storeId, session);
    const pages: PageRecordType[] = Object.keys(storePages)
      .map(key => ({ id: key, ...storePages[key] }));
    this.pages = pages;
  };

  @action setActivePage = async (pageRecord: PageRecordType | null, session: string, toUpdate: boolean = false) => {
    this.activePage = pageRecord ? { ...pageRecord } : null;
    if (toUpdate) {
      if (!this.activeStore || !pageRecord) return;
      await this.pageService.updatePage('store', this.activeStore.id, pageRecord.id, pageRecord, session);
    }
  };

  setActivePageById = async(pageId: string, session: string) => {
    const storeId = this.activeStore?.id;
    if (!storeId) return;
    let pages: PageRecordType[] = this.pages;
    if (!this.pages || this.pages.length < 1) {
      const storePages = await this.pageService.getPages('store', storeId, session);
      pages = Object.keys(storePages).map(key => ({ id: key, ...storePages[key] }));
      if (!pages || pages.length < 1) return;
      this.pages = pages;
    }

    console.log('we have pages', pages)
    const page = pages.find((p: PageRecordType) => p.id === pageId);
    if (page) this.activePage = page;
  }

  @action setActiveStoreUser = async (storeUser: StoreUser | null, toUpdate: boolean = false) => {
    let isManager = false;
    if (this.activeStore?.managers) {
      const managerIds = this.activeStore?.managers;
      if (storeUser?.userId) isManager = managerIds.includes(storeUser?.userId);
    }
    
    this.activeStoreUser = storeUser ? { ...storeUser, isManager } : null;
  };

  fetchRoles = async(session: string) => {
    const storeId = this.activeStore?.id;
    if (!storeId) return;
    const roles = await this.roleService.getRolesList('storeId', storeId, session);
    runInAction(() => {
      this.roles = roles;
    })
  }


  updateActiveSection = async (activeSection: SectionRecordType, session: string) => {
    if (!this.activeStore || !this.activePage || !this.activePage.section) return;
    const updatedSections = this.activePage.section.map((s: SectionRecordType) => s.slug === activeSection.slug ? activeSection : s);
    const updatedPage = await this.pageService.updatePage('store', this.activeStore.id, this.activePage.id, {...this.activePage, section: updatedSections}, session);
    runInAction(() => {
      this.activePage = updatedPage;
    });
  }

}

export default StoreStore;
