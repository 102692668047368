import React, { useEffect, useState, useCallback } from 'react';
import { View, ScrollView, KeyboardAvoidingView, Platform } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { TextInput } from 'react-native-paper';
import { useToast } from 'react-native-toast-notifications';

import RolesTreeComponent from 'components/RolesTreeComponent';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';
import BackButton from 'components/Button/BackButton';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';

import TreeService from 'lib/Services/tree.service';
import BaseDataService from 'lib/Services/baseData.service';
import RoleService from 'lib/Services/role.service';
import useGroupPages from 'lib/Hooks/useGroupPages';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, TreeElement, NodeElement, GroupStoreType } from 'types';
import { commonStyles } from 'styles';
import Contents from 'utils/contents';


const roleService = new RoleService();
const roleDataService = new BaseDataService('roles');

type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupRoleForm'>;
  route: RouteProp<RouteStackParamList, 'groupRoleForm'>;
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};



function GroupRoleForm(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { groupId, roleId, pages, name } } } = props;
  const { store: { userStore: { session }, groupStore: { activeGroup } } } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [roleName, setRoleName] = useState<string>('');
  const [rolesTree, setRolesTree] = useState<TreeElement[]>([]);
  const [nodesList, setNodesList] = useState<NodeElement[]>([]);
  const [selectedNodesList, setSelectedNodesList] = useState<NodeElement[]>([]);

  const { groupPages } = useGroupPages(groupId);

  const toast = useToast();

  const init = useCallback(async () => {
    if (!groupPages) return;
    const { tree, nodes } = TreeService.generateTree(groupPages);
    setRolesTree(tree);
    setNodesList(nodes);

    // Handle params from router, for editing role case.
    const pagesObject = pages ? pages.reduce((acc, page) => ({...acc, [page.id]: page}), {}) : {};
    const { nodes: selectedNodes } = TreeService.generateTree(pagesObject);
    setSelectedNodesList(selectedNodes);

    setRoleName(name || '');
  }, [groupPages, pages, name]);

  const onSave = async () => {
    if (!groupPages) return;
    const mappedPages = TreeService.convertTreeToRecords(rolesTree, selectedNodesList, groupPages);
    setLoading(true);
    if (roleId) {
      // Update Role
      try {
        const res = await roleDataService.update(roleId, { group: groupId, name: roleName, pages: mappedPages, roleId }, session);
        if (res) { // TODO: Better check for rest action success
          toast.show(Contents.group.toast.roleUpdateSuccess, { type: 'success' });
        } else {
          toast.show(Contents.group.toast.apiError, { type: 'error' });
        }
      } catch(error) {
        console.log("Error", error);
        toast.show(Contents.group.toast.apiError, { type: 'error' });
      }
    } else {
      // Create a new role
      try {
        if (!roleName) {
          toast.show(Contents.group.toast.roleNameRequired, { type: 'error' });
          return;
        }
        const res = await roleService.createRole({ group: groupId, name: roleName, pages: mappedPages }, session);
        if (res) {
          toast.show(Contents.group.toast.roleCreateSuccess, { type: 'success' });
          navigate('groupRolesList', { groupId });
        } else {
          toast.show(Contents.group.toast.apiError, { type: 'error' });
        }
      } catch(error) {
        console.log("Error", error);
        toast.show(Contents.group.toast.apiError, { type: 'error' });
      }
    }
    setLoading(false);
  }

  const onDelete = async () => {
    if (!roleId) return;
    setLoading(true);
    // Create a new role
    try {
      const res = await roleDataService.delete(roleId, session);
      if (res) {
        toast.show(Contents.group.toast.roleDeleteSuccess, { type: 'success' });
        navigate('groupRolesList', { groupId });
      } else {
        toast.show(Contents.group.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.group.toast.apiError, { type: 'error' });
    }
    setLoading(false);
  }
  useEffect(() => {
    init();
  }, [init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle title={ Contents.group.header.rolesForm }  />
        {
          loading ?
            <GenericViewSkeleton />
          :
            <>
              <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
                <View style={commonStyles.container}>
                  <TextInput
                    mode='outlined'
                    style={{ marginBottom: 15 }}
                    label={Contents.group.placeholders.roleName}
                    value={roleName}
                    onChangeText={(str) => setRoleName(str)}
                  />
                  <RolesTreeComponent
                    rolesTree={rolesTree}
                    nodesList={nodesList} selectedNodesList={selectedNodesList}
                    updateSelectedNodesList={setSelectedNodesList}
                  />
                  <View style={commonStyles.buttonContainer}>
                    <DefaultButton onPress={onSave} fullWidth>
                      { roleId ? Contents.common.btn.update : Contents.common.btn.save }
                    </DefaultButton>
                    {
                      roleId ?
                      <DangerousActionButton onPress={onDelete} fullWidth>
                        { Contents.common.btn.delete }
                      </DangerousActionButton>
                      :
                      <></>
                    }
                  </View>
                </View>
              </ScrollView>
              <BackButton />
            </>
          }
      </KeyboardAvoidingView>
    </View>
  )
}

const GroupRoleFormWithStore = withErrorBoundary(inject('store')(observer(GroupRoleForm)));
export default GroupRoleFormWithStore
