import { View, TouchableOpacity } from 'react-native';
import { Divider, Text } from 'react-native-paper';
import DefaultButton from '../Components/Button/DefaultButton'
import { commonStyles } from 'styles';
import { LayoutItem } from 'types';
import Markdown from 'react-native-markdown-display';

type Props = {
  item: LayoutItem;
  onPress?: () => void;
};


function LayoutItemComponent(props: Props) {
  const { onPress, item } = props;
  const { type, text } = item;

  if (type === 'link' || type === 'innerLink')
    return (
      <View style={[commonStyles.blackBackground, commonStyles.fullWidth, commonStyles.smallBorderRadius,
        commonStyles.flex, commonStyles.flexGrow, commonStyles.itemsCenter, commonStyles.justifyCenter]}>
        <TouchableOpacity style={[commonStyles.selfCenter, commonStyles.fullWidth, commonStyles.flexRow, commonStyles.smallPaddingVertical, commonStyles.basePaddingHorizontal]}
        onPress={onPress}>
          <Text style={[commonStyles.flexWrap, commonStyles.selfCenter,
            commonStyles.whiteText, commonStyles.textCenter, commonStyles.baseFontSize, commonStyles.baseLineHeight]}>
            { text }
          </Text>
        </TouchableOpacity>
      </View>
    );

  if (type === 'divider')
    return (
      <Divider />
    );
// This is a text item!
  return (
    <Markdown style={[commonStyles.markdown, commonStyles.smallMarginBottom,commonStyles.flexWrap]}>{ text }</Markdown>
  );
}

export default LayoutItemComponent
