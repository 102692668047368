import { View, Text, TouchableWithoutFeedback } from 'react-native';
import { DashboardGroupStoreCardProps } from 'types';
import { commonStyles } from 'styles';

export default function DashboardGroupStoreCard({
  title,
  button,
  onPress
}: DashboardGroupStoreCardProps) {
  return (
    <View style={[commonStyles.basePaddingHorizontal, commonStyles.baseMarginBottom]} testID="dashboard-group-store-card">
      <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.spaceBetween, commonStyles.whiteBackground,
        commonStyles.shadowedView, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical]}>
        <View style={commonStyles.spaceBetween}>
          <Text style={commonStyles.normalText}>{ title }</Text>
        </View>
        <View style={commonStyles.flexEnd}>
          <TouchableWithoutFeedback onPress={onPress}>
            <Text style={commonStyles.buttonText}>{button?.toUpperCase()}</Text>
          </TouchableWithoutFeedback>
        </View>
      </View>
    </View>
  );
}
