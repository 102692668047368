import React from 'react';
import { View } from 'react-native';
import TreeView from 'react-native-final-tree-view'

import { AntDesign } from '@expo/vector-icons';

import { TreeElement, NodeElement } from 'types';
import { commonStyles } from 'styles';
import TreeService from '../lib/Services/tree.service';

import CheckBox from './Checkbox';


type Props = {
  rolesTree: TreeElement[];
  nodesList: NodeElement[];
  selectedNodesList: NodeElement[];
  updateSelectedNodesList: (list: NodeElement[]) => void;
};



export default function RolesTreeComponent(props: Props) {

  const { rolesTree, nodesList, selectedNodesList, updateSelectedNodesList } = props;

  const onToggleSelection = (element: TreeElement) => {
    const node = TreeService.getNodesById(element.id, nodesList);
    if (node) {
      const selectedNode = TreeService.getNodesById(element.id, selectedNodesList);
      if (!selectedNode) { 
        console.log('selected node', selectedNode)
        // Should handle deselecting process in this case
        const parentNodes = TreeService.getParentNodes(node, nodesList);
        updateSelectedNodesList([...new Set([...parentNodes, node, ...selectedNodesList])]);
      } else {
        // Should handle selecting process in this case
        const childrenNodes = TreeService.getChildrenNodes(node, nodesList);
        const filteredNodes = selectedNodesList.filter((node: NodeElement) => {
          const childNode = TreeService.getNodesById(node.nodeId, childrenNodes);
          return (!childNode && node.nodeId !== element.id);
        });
        updateSelectedNodesList(filteredNodes);
      }
    }
  }

  const isSelected = (element: TreeElement) => {
    const selectedNode = TreeService.getNodesById(element.id, selectedNodesList);
    return !!selectedNode;
  }

  console.log('selected nodes list', selectedNodesList)

  return (
    <View style={[commonStyles.flexGrow]}>
      {
        rolesTree && 
        <TreeView
          data={rolesTree} // defined above
          renderNode={({ node, level, isExpanded, hasChildrenNodes }) => {
            return (
              <View style={[
                commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter,
                { paddingLeft: 20* level }
              ]} key={node.id}>
                <View style={commonStyles.smallPaddingHorizontal}>
                  {
                    hasChildrenNodes ? 
                      (isExpanded ? <AntDesign name="minussquare" size={24} color="black" /> : <AntDesign name="plussquare" size={24} color="black" /> ) 
                    :
                      <AntDesign name="minus" size={24} color="black" />
                  }
                </View>
                <CheckBox label={node.name} onPress={() => onToggleSelection(node)} status={ isSelected(node) ? "checked" : "unchecked" } />
              </View>
            )
          }}
          initialExpanded={true}
        />
      }
    </View>
  )
}