import { useEffect, useState, useCallback } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView, Platform, TouchableOpacity } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { AntDesign, FontAwesome5, Fontisto } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useToast } from 'react-native-toast-notifications';


import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';

import InvitedService from 'lib/Services/invited.service';
import BaseDataService from 'lib/Services/baseData.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, GroupUser, GroupStoreType, User, ConfirmationDialogType, UserStoreType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupUsersList'>;
  route: RouteProp<RouteStackParamList, 'groupUsersList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

const invitedService = new InvitedService();
const groupDataService = new BaseDataService('group');
const userGroupDataService = new BaseDataService('user_group');

function GroupAdminList(props: Props) {
  const { route } = props;
  const { params: { groupId } } = route;
  const isFocused = useIsFocused();
  const { navigation: { navigate, goBack } } = props;
  const { store: {
    groupStore: { activeGroup, fetchGroupUsers, isCurrentUserManager, isCurrentUserOwner, activeGroupUser, setActiveGroupUser, owners, managers, groupUsers },
    userStore: { session }
  } } = props;

  const [groupUsersList, setGroupAdminList] = useState<GroupUser[]>([]);
  const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogType>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [activeUser, setActiveUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  // - Get all tasks list
  // - Get tasks selected for group
  const init = useCallback(async () => {
    // No permissions to browse users list and search for users
    if (!isCurrentUserManager && !isCurrentUserOwner) {
      toast.show(Contents.common.toast.insufficientPermission, { type: 'danger' });
      goBack();
      return;
    }
    setLoading(true)
    await fetchGroupUsers(groupId, session);
    setLoading(false);
  }, [groupId]);

  useEffect(() => {
    const managerIds = managers.map((manager: GroupUser) => manager.userId);

    console.log('group users', groupUsers)

    if (groupUsers && groupUsers.length > 0) {
      let list = groupUsers
        .filter((groupUser: User) => managerIds.includes(groupUser.id) === false)
        .sort((a: User, b: User) => a.name > b.name ? 1 : -1);
      setGroupAdminList(list);
    } else {
      setGroupAdminList([]);
    }
  }, [groupUsers, managers]);


  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
      {
        loading ?
          <GroupStoreViewSkeleton heading={Contents.group.header.admins} />
        :
          <>
            <HeadViewWithSubTitle
              title={ Contents.group.header.admins }

              />
            <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
              <View style={commonStyles.container}>
                {
                  <View style={commonStyles.baseMarginBottom}>
                    {
                      (owners && owners.length > 0) ?
                        owners.map(groupUser => (
                          <View key={groupUser.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                            <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ groupUser.user.name.join(' ') }</Text>
                          </View>
                        ))
                      :
                      <Text style={commonStyles.baseFontSize}>{ Contents.group.text.usersList.noOwnerExists }</Text>
                    }
                  </View>
                }
              </View>
            </ScrollView>
            <View style={commonStyles.buttonContainer}>
              <DefaultButton onPress={() => navigate('groupPromoteOwner', { groupId })} fullWidth>
                { Contents.group.btn.addOwner }
              </DefaultButton>
            </View>
            <BackButton />
          </>
        }
      </KeyboardAvoidingView>
    </View>
  );
}
const GroupAdminListWithStore = withErrorBoundary(inject('store')(observer(GroupAdminList)));
export default GroupAdminListWithStore
