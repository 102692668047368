import { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableWithoutFeedback, ScrollView } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import AwesomeAlert from 'react-native-awesome-alerts';

import DefaultButton from 'components/Button/DefaultButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';
import BackButton from 'components/Button/BackButton';
import StatisticsCard from 'components/StatisticsCard';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';

import withErrorBoundary from 'lib/HoC/withErrorBoundary';
import {
	GroupStoreType,
	ScreensParamList,
	RouteStackParamList,
	UserStoreType
} from 'types';

import { getStoreName } from 'utils';
import Contents from 'utils/contents';
import { commonStyles } from 'styles';
import styles from './styles';


type Props = {
	navigation: NavigationProp<ScreensParamList, 'groupOwnerView'>;
	route: RouteProp<RouteStackParamList, 'groupOwnerView'>;
	store: { groupStore: GroupStoreType; userStore: UserStoreType };
};

function GroupOwnerView(props: Props) {
	const {
		navigation: { navigate, goBack }
	} = props;
	const {
		route: {
			params: { groupId }
		}
	} = props;
	const {
		store: { groupStore, userStore }
	} = props;
	const { activeGroup, fetchGroup, fetchGroupUsers, fetchGroupStores, archiveGroup, owners, groupStores } = groupStore;
	const { session } = userStore;
	const isFocused = useIsFocused();
	const toast = useToast();

	const [confirmToArchiveGroup, setConfirmToArchiveGroup] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);

	const onOpenStore = (storeId: string) => {
    navigate('storeView', { groupId, storeId });
	}

	const onArchiveGroup = async() => {
		try {
			await archiveGroup(session);
			toast.show(
				Contents.group.toast.archiveSuccess,
				{ type: 'success' }
			);
			setConfirmToArchiveGroup(false)
			navigate('dashboard');
		} catch(error) {
			console.log('Error in archive group', error);
			toast.show(
				Contents.group.toast.archiveError,
				{ type: 'warning' }
			);
		}
	}

	// Fetch group data
	const init = useCallback(async () => {
		if (session) {
			if (!activeGroup || activeGroup.id !== groupId) setLoading(true);
			await fetchGroup(groupId, session);
			await fetchGroupUsers(groupId, session);
			await fetchGroupStores(groupId, session);
			setLoading(false);
		}
	}, [session, groupId, activeGroup?.id]);

	useEffect(() => {
		isFocused && init();
	}, [isFocused, init]);


	return (
		<View style={[commonStyles.viewport]}>
			{
				(!activeGroup || activeGroup?.id !== groupId || loading) ?
					<GroupStoreViewSkeleton heading={Contents.group.header.groupOwnerView} />
				:
					<View style={[commonStyles.container]}>
						<HeadViewWithSubTitle title={ Contents.group.header.groupOwnerView } subTitle={ activeGroup?.legalName || '' } />
						<ScrollView style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.shadowedView, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical]}
							contentContainerStyle={commonStyles.flexGrow}>
							<View style={commonStyles.flexGrow}>
								<View style={styles.innerView}>
									{activeGroup.statistics && (
										<StatisticsCard
											invited={activeGroup.statistics.invited}
											onBoarding={activeGroup.statistics.onboarding}
											done={activeGroup.statistics.done}
										/>
									)}
									<View style={[commonStyles.otherView, commonStyles.smallMarginBottom]}>
										<Text style={commonStyles.otherText}>
											{Contents.group.text.groupViewStore}
										</Text>
										<TouchableWithoutFeedback
											onPress={() => navigate('storeForm', { groupId, storeId: '' })}>
											<View style={styles.addBtnVw}>
												<Text style={styles.btnTxt}>ADD</Text>
											</View>
										</TouchableWithoutFeedback>
									</View>
									{activeGroup &&
										groupStores &&
										groupStores.length > 0 &&
										groupStores.map((store: any) => (
											<StatisticsCard
												key={store.id}
												name={getStoreName(store)}
												invited={store.statistics?.invited}
												onBoarding={store.statistics?.onboarding}
												done={store.statistics?.done}
												onPress={() => onOpenStore(store.id)}
											/>
										))}
								</View>
							</View>
							<View style={commonStyles.buttonContainer}>
								<DefaultButton onPress={() => navigate('groupUsersList', { groupId })} fullWidth>
									{Contents.group.btn.users}
								</DefaultButton>
								<DefaultButton onPress={() => navigate('groupAdminList', { groupId })} fullWidth>
									{ Contents.group.btn.admins }
								</DefaultButton>
								<DefaultButton onPress={() => navigate('groupTaskSetting', { groupId })} fullWidth>
									{Contents.group.btn.onboardingTaskSetting}
								</DefaultButton>
								<DefaultButton onPress={() => navigate('groupPageSettingList', { groupId })} fullWidth>
									{Contents.group.btn.pageSetting}
								</DefaultButton>
								<DefaultButton onPress={() => navigate('groupRolesList', { groupId })} fullWidth>
									{Contents.group.btn.roleManagement}
								</DefaultButton>
								<DangerousActionButton onPress={() => setConfirmToArchiveGroup(true)} fullWidth>
									{ Contents.common.btn.archive }
								</DangerousActionButton>
							</View>
						</ScrollView>
						<BackButton />
					</View>
			}
			<AwesomeAlert
				show={confirmToArchiveGroup}
				title={ Contents.group.confirm.title }
				message={ Contents.group.confirm.archiveGroup }
				closeOnTouchOutside={true}
				closeOnHardwareBackPress={false}
				showCancelButton={true}
				showConfirmButton={true}
				confirmButtonColor="#DD6B55"
				onCancelPressed={() => {
					setConfirmToArchiveGroup(false);
				}}
				onConfirmPressed={onArchiveGroup}
			/>
		</View>
	);
}

const GroupOwnerViewWithStore = withErrorBoundary(inject('store')(observer(GroupOwnerView)));
export default GroupOwnerViewWithStore;
