import { useEffect, useState, useCallback } from 'react';
import { View, Text, KeyboardAvoidingView, ScrollView, Platform, TouchableOpacity } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { TextInput } from 'react-native-paper';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import AwesomeAlert from 'react-native-awesome-alerts';
import { AntDesign, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { default as slugify } from 'slugify';


import GroupElementForm from 'components/ElementForm';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';

import { GroupStoreType, UserStoreType, ScreensParamList, RouteStackParamList, ElementRecordType, OptionType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';
import styles from '../styles';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupSectionForm'>;
  route: RouteProp<RouteStackParamList, 'groupSectionForm'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

function GroupSectionForm(props: Props) {
  const { navigation: { goBack } } = props;
  const { route } = props;
  const { params: { activeSection } } = route;
  const { store: { groupStore, userStore } } = props;
  const { activeGroup, updateActiveSection, activePage, pages } = groupStore;
  const { session } = userStore;

  const toast = useToast();

  const [isAdding, setAdding] = useState<boolean>(false);
  const [sectionTitle, setSectionTitle] = useState<string>('');
  const [sectionDescription, setSectionDescription] = useState<string>('');
  const [elementText, setElementText] = useState<string>('');
  const [elementList, setElementList] = useState<ElementRecordType[]>([]);
  const [activeElement, setActiveElement] = useState<ElementRecordType | null>(null);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState<boolean>(false);
  const [pagesAutocompleteOptions, setPagesAutocompleteOptions] = useState<OptionType<string>[]>([])

  const init = useCallback(async () => {
    if (activeSection) {
      setSectionTitle(activeSection.title);
      setSectionDescription(activeSection.description);
      setElementList(activeSection.elements || []);
    }
  }, [activeSection]);

  const onSave = async () => {
    await updateActiveSection({ ...activeSection, title: sectionTitle, description: sectionDescription, elements: elementList }, session);
    toast.show(Contents.group.toast.sectionUpdateSuccess, { type: 'success' });
  }

  const onAddElement = async() => {
    if (!elementText) return;
    const newElement: ElementRecordType = { slug: slugify(elementText), text: elementText, type: 'text' };
    setElementList([...elementList, newElement]);
    setElementText('');
    setAdding(false);
    toast.show(Contents.group.toast.elementCreateSuccess, { type: 'success' });
  }

  const onOpenElementForm = (element: ElementRecordType) => {
    setActiveElement(element);
  }

  const onDeleteElement = () => {
    const elements = elementList.filter(e => e.slug !== activeElement?.slug);
    setElementList(elements);
    setActiveElement(null);
    setConfirmationDialogVisible(false)
  }

  const onUpdateElement = (element: ElementRecordType) => {
    const elements = elementList.map(e => e.slug === element.slug ? element : e);
    setElementList(elements);
    setActiveElement(null)
  }

  const renderItem = ({ item, drag, isActive }: RenderItemParams<ElementRecordType>) => {
    return (
      <View style={[ (activeElement?.slug === item.slug) ? styles.borderedForm : {} ]}>
        <View style={[commonStyles.smallPaddingHorizontal, commonStyles.smallPaddingVertical,
          commonStyles.flex, commonStyles.itemsCenter, commonStyles.spaceBetween]}>
          <TouchableOpacity onLongPress={drag} style={commonStyles.flexGrow}>
            <Text>{ item.text }</Text>
          </TouchableOpacity>
          {
            (activeElement?.slug !== item.slug) &&
            <>
              <Ionicons name='settings' size={24} style={commonStyles.smallPaddingHorizontal} color='black' onPress={() => onOpenElementForm(item)}/>
              <AntDesign name='delete' size={24} color='black' onPress={() => { setActiveElement(item); setConfirmationDialogVisible(true); }}/>
            </>
          }
          {
            (activeElement?.slug === item.slug) &&
            <MaterialCommunityIcons name='cancel' size={24} color='black' onPress={() => setActiveElement(null)}/>
          }
        </View>
        {
          (activeElement?.slug === item.slug) && <GroupElementForm element={item} onUpdateElement={onUpdateElement} pageOptions={pagesAutocompleteOptions} />
        }
      </View>
    );
  }

  useEffect(() => {
    init();
  }, [init, route]);

  useEffect(() => {
    if (sectionTitle && elementList && (sectionTitle !== activeSection.title || JSON.stringify(activeSection.elements) !== JSON.stringify(elementList)))
      onSave();
  }, [sectionTitle, JSON.stringify(elementList)]);


  useEffect(() => {
    if (pages && pages.length > 0 && activePage) {
      const options = pages.filter((page: PageRecordType) => page.id !== activePage.id)
        .sort((a: PageRecordType, b: PageRecordType) => a.title > b.title ? 1 : -1)
        .map((page: PageRecordType) => {
          return { label: page.title, value: page.id };
        });
      setPagesAutocompleteOptions(options)
    }
  }, [pages, activePage]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle title={ Contents.group.header.sectionForm }  />
        <ScrollView style={commonStyles.flatView} contentContainerStyle={ commonStyles.flexGrow }>
          <View style={commonStyles.container}>

            <TextInput
              mode='outlined'
              style={{ marginBottom: 15 }}
              label={Contents.group.placeholders.sectionTitle}
              value={sectionTitle}
              onChangeText={(str) => setSectionTitle(str)}
            />
            <TextInput
              mode='outlined'
              style={{ marginBottom: 15 }}
              label={Contents.group.placeholders.sectionDescription}
              value={sectionDescription}
              onChangeText={(str) => setSectionDescription(str)}
            />
            <View style={[commonStyles.flexGrow, commonStyles.basePaddingBottom]}>
              {
                isAdding ?
                  <View style={[commonStyles.flex, commonStyles.spaceBetween, commonStyles.itemsCenter]}>
                    <TextInput
                      mode='outlined'
                      style={commonStyles.flexGrow}
                      label={Contents.group.placeholders.elementText}
                      placeholder={ Contents.group.placeholders.elementName}
                      value={elementText}
                      onChangeText={(str) => setElementText(str)}
                    />
                    <TouchableOpacity onPress={onAddElement} style={[commonStyles.smallPaddingHorizontal]}>
                      <AntDesign name='check' size={24} color='black' />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => setAdding(false)} style={[commonStyles.smallPaddingHorizontal]}>
                      <AntDesign name='close' size={24} color='black' />
                    </TouchableOpacity>
                  </View>
                :
                  <DefaultButton onPress={() => setAdding(true)} fullWidth>
                    { Contents.group.btn.addElement }
                  </DefaultButton>
              }
              {
                <DraggableFlatList
                  data={elementList}
                  onDragEnd={({ data }) => setElementList(data)}
                  keyExtractor={(section) => section.slug}
                  renderItem={renderItem}
                />
              }
            </View>
            <View style={commonStyles.buttonContainer}>
              <DefaultButton onPress={onSave} fullWidth>
                { activeSection ? Contents.common.btn.update : Contents.common.btn.save }
              </DefaultButton>
            </View>
          </View>
        </ScrollView>
        <BackButton />

        <AwesomeAlert
          show={confirmationDialogVisible}
          showProgress={false}
          title={ Contents.group.confirm.title }
          message={ Contents.group.confirm.removeElement }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogVisible(false);
            setActiveElement(null);
          }}
          onConfirmPressed={() => {
            onDeleteElement();
          }}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

const GroupSectionFormWithStore = withErrorBoundary(inject('store')(observer(GroupSectionForm)));
export default GroupSectionFormWithStore
