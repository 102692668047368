export default {
  text: {
    groupViewStore: 'STORES',
    groupViewOwners: 'Owners'
  },
  placeholders: {
    elementText: 'Element Text',
    elementLink: 'Element Link',
    elementType: 'Element Type',
  },
  toast: {
    apiError: 'There seems to be a problem with the server, please try again later or contact administrator.',
    insufficientPermission: 'Insufficient permission!'
  },
  btn: {
    update: 'Update',
    save: 'Save',
    delete: 'Delete',
    back: 'Back',
    reset: 'Reset',
    cancel: 'Cancel',
    archive: 'Archive'
  },
};
