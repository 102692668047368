import Contents from '../contents';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';

const formConfig: Omit<LogicProps, 'control'>[] = [
  {
    type: 'text',
    name: 'firstName',

    rules: {
      required: {
        value: true,
        message: Contents.profileScreen.validationErrors.firstNameRequired,
      },
    },
    textInputProps: {
      label: Contents.profileScreen.placeholders.firstName,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'lastName',
    textInputProps: {
      label: Contents.profileScreen.placeholders.lastName,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'phoneNumber',
    rules: {
      required: {
        value: true,
        message: Contents.profileScreen.validationErrors.phoneNumber,
      },
    },
    textInputProps: {
      label: Contents.profileScreen.placeholders.phoneNumber,
      autoComplete: false
    },
  },
  {
    type: 'email',
    name: 'email',

    rules: {
      required: {
        value: true,
        message: Contents.profileScreen.validationErrors.emailRequired,
      },
      pattern: {
        value:
          /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
          message: Contents.profileScreen.validationErrors.emailPattern
      },
    },
    textInputProps: {
      label: Contents.profileScreen.placeholders.email,
      autoComplete: false
    },
  },

];
export default formConfig;
