import {useState, useEffect, useCallback} from 'react';
import {View, KeyboardAvoidingView, Platform, ScrollView} from 'react-native';
import type {NavigationProp} from '@react-navigation/native';
import {inject, observer} from 'mobx-react';
import {FormBuilder} from 'react-native-paper-form-builder';
import {useForm} from 'react-hook-form';
import {useToast} from 'react-native-toast-notifications';
import {TextInput} from 'react-native-paper';
import {DatePickerModal} from 'react-native-paper-dates';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import dayjs from 'dayjs';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import ParentContactInfo from 'components/ParentContactInfo';
import DefaultButton from 'components/Button/DefaultButton';

import {profileFormConfig} from 'utils/FormConfig';
import {
  UserDataType,
  ParentContactInformationType,
  ScreensParamList,
  UserStoreType
} from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import {commonStyles } from 'styles';
import Contents from 'utils/contents';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'profile'>;
  store: { userStore: UserStoreType };
};

function Profile(props: Props) {
  const {
    navigation: {navigate, goBack},
    store: {
      userStore
    }
  } = props;
  const {user, fetchUser, updateUser, session} = userStore;
  const {control, setFocus, handleSubmit, reset, formState: {isValid}} = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    mode: 'onChange',
  });
  const [birthDate, setBirthDate] = useState<Date | undefined>(undefined);
  const [birthDateString, setBirthDateString] = useState<string>('');
  const [underAge, setUnderAge] = useState<boolean>(false);
  const [parentContactInfo, setParentContactInfo] = useState<ParentContactInformationType>({
    parentName: '',
    parentPhoneNumber: '',
    parentEmail: ''
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const onDismissSingle = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpen(false);
      setBirthDate(params.date);
    },
    [setOpen, setBirthDate]
  );

  const isFormValid = () => {
    if (!birthDate) {
      toast.show(Contents.profileScreen.validationErrors.birthDate, {type: 'error'});
      return false;
    }
    if (underAge) {
      if (!parentContactInfo.parentName) {
        toast.show(Contents.profileScreen.validationErrors.parentName, {type: 'error'});
        return false;
      }
      if (!parentContactInfo.parentPhoneNumber) {
        toast.show(Contents.profileScreen.validationErrors.parentPhoneNumber, {type: 'error'});
        return false;
      }
      if (!parentContactInfo.parentEmail) {
        toast.show(Contents.profileScreen.validationErrors.parentEmail, {type: 'error'});
        return false;
      }
    }
    return true;
  }
  const updateProfile = async (body: any) => {
    try {
      if (isFormValid() === false) return;

      let newData: UserDataType = {
        name: [body.firstName, body.lastName],
        phoneNumber: body.phoneNumber,
        email: body.email,
        updatedAt: new Date(),
      };
      if (birthDate) newData = {...newData, birthDate};
      if (underAge) newData = {...newData, ...parentContactInfo};

      if (body.email !== user?.email) newData.emailVerified = false;
      if (body.phoneNumber !== user?.phoneNumber) newData.phoneVerified = false;
      await updateUser(user.id, newData, session);

      if (newData.emailVerified === false) {
        navigate('verificationForm', {type: 'email', value: body.email});
        return;
      }
      if (newData.phoneVerified === false) {
        navigate('verificationForm', {type: 'phone', value: body.phoneNumber});
        return;
      }
      toast.show(Contents.profileScreen.toast.updateSuccess, {type: 'success'});
    } catch (error) {
      console.log('Update profile error', error);
      return;
    }
  };

  const init = async () => {
    if (!user) {
      navigate('welcome');
      return
    }

    await fetchUser(user.id, session);
  }

  const updateFormAndAuthCheck = () => {
    if (!user.emailVerified) {
      navigate('verificationForm', {type: 'email', value: user.email});
      return;
    }
    if (!user.phoneNumberVerified) {
      navigate('verificationForm', {type: 'phone', value: user.phoneNumber});
      return;
    }
    reset({
      firstName: user.name ? user.name[0] : '',
      lastName: user.name ? user.name[1] : '',
      email: user.email,
      phoneNumber: user.phoneNumber,
    });
    if (!!user.birthDate && dayjs(user.birthDate).isValid()) setBirthDate(new Date(user.birthDate));
    setParentContactInfo({
      parentName: user.parentName || '',
      parentPhoneNumber: user.parentPhoneNumber || '',
      parentEmail: user.parentEmail || '',
    });
  }

  useEffect(() => {
    if (!!birthDate && dayjs(birthDate).isValid()) {
      setBirthDateString(birthDate ? dayjs(birthDate).format('DD/MM/YYYY') : '');
      const workableDate = dayjs(birthDate).add(18, 'year');
      setUnderAge(workableDate > dayjs());
    } else
      setUnderAge(false);
  }, [birthDate]);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (user) {
      updateFormAndAuthCheck();
    }
  }, [JSON.stringify(user)]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
          <View style={commonStyles.container}>
            <HeadViewWithSubTitle title={Contents.profileScreen.txt.header} />
            {
              loading ?
                <GenericViewSkeleton/>
                :
                <View style={commonStyles.formWrapper}>
                  <FormBuilder
                    control={control}
                    setFocus={setFocus}
                    formConfigArray={profileFormConfig}
                  />
                  <TouchableWithoutFeedback onPress={() => setOpen(true)}>
                    <TextInput
                      mode='outlined'
                      label={Contents.profileScreen.placeholders.birthDate}
                      value={birthDateString}
                    />
                  </TouchableWithoutFeedback>
                  <DatePickerModal
                    locale="en"
                    mode="single"
                    visible={open}
                    onDismiss={onDismissSingle}
                    date={birthDate}
                    onConfirm={onConfirmSingle}
                  />

                  {
                    underAge &&
                    <ParentContactInfo
                      parentContactInfo={parentContactInfo}
                      setParentContactInfo={setParentContactInfo}
                    />
                  }
                  <DefaultButton
                    style={[{alignContent: 'right'}, commonStyles.baseMarginTop]}
                    disabled={!isValid || !birthDate}
                    onPress={handleSubmit(updateProfile)}>
                    {Contents.common.btn.save}
                  </DefaultButton>
                </View>
            }
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

const ProfileWithStore = withErrorBoundary(inject('store')(observer(Profile)));
export default ProfileWithStore;
