import { useState } from 'react';
import { View, Text } from 'react-native';
import { useNavigation, ParamListBase } from '@react-navigation/native';
import type { NavigationProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import DashboardGroupStoreCard from 'components/DashboardGroupStoreCard';
import AccordionListItem from 'components/AccordionListItem';
import GlobalLoading from 'components/GlobalLoading';

import UserTaskService from 'lib/Services/userTask.service';
import { UserStoreType, GroupStoreType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';

type Props = {
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};
type ViewType = 'user' | 'manager'
const userTaskService = new UserTaskService();

function DashboardManager({
  store: {
    userStore: { dashboard, session },
    groupStore: { fetchRole }
  }
}: Props) {
  const navigation = useNavigation<NavigationProp<ParamListBase>>()
  const [activeView, setActiveView] = useState<ViewType>('manager');
  const [loading, setLoading] = useState(false);

  const onOpenEndUserGroup = async (userGroup: any) => {
    const { groupId, roleId, userId } = userGroup
    if (roleId) fetchRole(roleId, session);

    // Check for user tasks
    setLoading(true);
    const data = await userTaskService.list(userId, groupId, session);
    setLoading(false);
    if (data && Object.keys(data).length > 0) {
      const unfinishedTasks = Object.keys(data).map((key: string) => data[key].done === false);
      if (unfinishedTasks && unfinishedTasks.length > 0) {
        navigation.navigate('groupOnboarding', { groupId });
        return;
      }
    }
    navigation.navigate('endUserGroupPageList', { groupId });
  }

  const onOpenEndUserStore = (userStore: any) => {
    if (userStore?.roleId) fetchRole(userStore?.roleId, session);
    navigation.navigate('endUserStorePageList', { groupId: userStore.groupId, storeId: userStore.storeId });
  }

  const onOpenManagerGroup = (groupId: string) => {
    navigation.navigate('groupManagerView', { groupId, asGroup: true });
  }

  const onOpenManagerStore = (storeId: string) => {
    navigation.navigate('managerStoreView', { storeId, asGroup: true });
  }
  console.log('this is the current dashboard manager', dashboard)

  return (
    <>
      <AccordionListItem title={Contents.dashboardScreen.text.user}
        open={activeView === 'user'}
        toggleOpen={() => setActiveView('user')}>
        {
          (dashboard && dashboard.user_groups && dashboard.user_groups.length > 0) && (
          <>
            <Text style={[commonStyles.baseFontSize, commonStyles.baseMarginHorizontal, commonStyles.boldText, commonStyles.smallMarginBottom]}>
              {Contents.dashboardScreen.text.group}
            </Text>
            {
              dashboard.user_groups.map((user_group: any) => (
              <DashboardGroupStoreCard
                key={user_group.group.id}
                title={user_group.group.legalName}
                button='Open'
                onPress={() => onOpenEndUserGroup(user_group)}
              />
              ))
            }
          </>)
        }
        {
          (dashboard && dashboard.user_stores && dashboard.user_stores.length > 0) && (
            <>
              <Text style={[commonStyles.baseFontSize, commonStyles.baseMarginHorizontal, commonStyles.boldText, commonStyles.smallMarginBottom]}>
                {Contents.dashboardScreen.text.store}
              </Text>
              {
                dashboard.user_stores.map((user_store: any) => (
                  <DashboardGroupStoreCard
                    key={user_store.store.id}
                    title={user_store.store.name}
                    button='Open'
                    onPress={() => onOpenEndUserStore(user_store)}
                    />
                  ))
              }
            </>
          )
        }

      </AccordionListItem>
      <AccordionListItem title={Contents.dashboardScreen.text.manager}
        open={activeView === 'manager'}
        toggleOpen={() => setActiveView('manager')}>
        {
          dashboard && dashboard.manager_groups && dashboard.manager_groups.length > 0 &&
          <View style={commonStyles.otherView}>
            <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
              {Contents.dashboardScreen.text.managerGroup}
            </Text>
            {
              dashboard.manager_groups.map((manager_group: any) => (
                <DashboardGroupStoreCard
                  key={manager_group.group.id}
                  title={manager_group.group.legalName}
                  button='Open'
                  onPress={() => onOpenManagerGroup(manager_group.group.id)}
                />)
              )
            }
          </View>
        }
        {
          dashboard && dashboard.manager_stores && dashboard.manager_stores.length > 0 &&
          <View style={commonStyles.otherView}>
            <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
              {Contents.dashboardScreen.text.store}
            </Text>
            {
              dashboard.manager_stores.map((manager_store: any) => (
                <DashboardGroupStoreCard
                  key={manager_store.store.id}
                  title={manager_store.store.name}
                  button='Open'
                  onPress={() => onOpenManagerStore(manager_store.store.id)}
                />)
              )
            }
          </View>
        }
      </AccordionListItem>
      { loading && <GlobalLoading /> }
    </>
  );
}

const DashboardManagerWithStore = withErrorBoundary(inject('store')(observer(DashboardManager)));
export default DashboardManagerWithStore;
