import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';

class BaseDataService {
  protected name: string = '';
  protected dataApiUrl: string = '';

  constructor(type: string) {
    this.dataApiUrl = `${SERVER_URL}/data/${type}`;
    this.name = type;
  }

  getList = async (session: string) => {
    try {
      const res = await axios.get(`${this.dataApiUrl}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in ${this.name} getList method`, error);
      return null;
    }
  }

  create = async (newData: Object, session: string) => {
    try {
      const res = await axios.put(`${this.dataApiUrl}`, newData, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in ${this.name} create method`, error);
      return null;
    }
  };

  get = async (id: string, session: string) => {
    try {
      const res = await axios.get(`${this.dataApiUrl}/${id}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in ${this.name} get method`, error);
      return null;
    }
  }

  update = async(id: string, newData: Object, session: string) => {
    try {
      const res = await axios.put(`${this.dataApiUrl}/${id}`, newData, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in ${this.name} update method`, error);
      return null;
    }
  }

  delete = async(id: string, session: string) => {
    try {
      const res = await axios.delete(`${this.dataApiUrl}/${id}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in ${this.name} update method`, error);
      return null;
    }
  }
}

export default BaseDataService;