import { Dimensions } from 'react-native';
import Constants from "expo-constants";

//const inProduction = manifest.packagerOpts == null;
const inProduction = process.env.NODE_ENV === 'production';
const inExpo = Constants.manifest && Constants.manifest.debuggerHost;
const inBrowser = typeof document !== 'undefined';

export const apiDomain =
	inProduction ? document.location.hostname+ '/api/app'
		: inExpo ? Constants.manifest.debuggerHost!.split(`:`).shift() + ':8000'
			: inBrowser ? document.location.hostname + ':8000'
				: 'unknown';

// console.log('apiDomain:', apiDomain);

const protocol = inProduction ? 'https' : 'http'
const SERVER_URL = `${protocol}://${apiDomain}`;

//colors
const white = '#FFFFFF';
const primary = '#2db3e2';
const primaryHover = '#007e97';
const placeholder = '#7C7A7A99';
const gold = '#DAAB03';
const green = '#59DA03';
const aqua = '#03DAC5';
const radish = '#C5228D';
const red = '#FA0808';

const MOBILE_MAX_VW = 480;
const DESKTOP_CONTAINER_WIDTH = 1024;
const { width, height } = Dimensions.get('screen');
export {
	width,
	height,
	white,
	primary,
	placeholder,
	gold,
	green,
	aqua,
	radish,
	red,
	MOBILE_MAX_VW,
	DESKTOP_CONTAINER_WIDTH,
	SERVER_URL
};
