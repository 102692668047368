import React, { useState, useEffect } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform } from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import validator from 'validator';
import { useToast } from 'react-native-toast-notifications';

import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';

import { ScreensParamList, RouteStackParamList, UserStoreType, OptionType, Store, User, GroupStoreType, StoreStoreType } from 'types';
import BaseDataService from 'lib/Services/baseData.service';
import InvitedService from 'lib/Services/invited.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles, componentsDefaultStyles } from 'styles';
import DangerousActionButton from '../../../Components/Button/DangerousActionButton';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeLookupInviteUser'>;
  route: RouteProp<RouteStackParamList, 'storeLookupInviteUser'>;
  store: { userStore: UserStoreType, storeStore: StoreStoreType };
};

const invitedDataService = new BaseDataService('invited');
const invitedService = new InvitedService();


function StoreLookupInviteUser(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { groupId, storeId } } } = props;
  const { store: { userStore, storeStore } } = props;
  const { user, session } = userStore;
  const { activeStore } = storeStore;
  const toast = useToast();

  const [inviteeUser, setInviteeUser] = useState<User | null>(null);
  const [responseMessage, setResponseMessage] = useState<string>('');

  const [verificationType, setVerificationType] = useState('');
  const [verificationText, setVerificationText] = useState('');

  const verifyVerificationType = function (text: string) {
    setVerificationText(text);
    if (validator.isEmail(text)) {
      setVerificationType('email');
    } else if (validator.isMobilePhone(text)) {
      setVerificationType('phoneNumber');
    } else {
      setVerificationType('');
    }
    // Reset response mesage when you update the lookup text
    setResponseMessage('');
  }

  const onLookup = async () => {
    const result = await invitedService.lookupForStore(groupId, storeId, verificationType, verificationText, session);
    if (!result) return;
    if (result.isGroupMember === false) {
      setResponseMessage(Contents.store.text.lookupInvite.notGroupMember);
      setInviteeUser(result.user);
    } else {
      if (result.lookup === false) {
        setInviteeUser(result.user);
        setResponseMessage(result.message || '');
      } else {
        setResponseMessage(Contents.store.text.lookupInvite.alreadyStoreMember);
      }
    }
  }

  const onInviteUser = async () => {
    if (!inviteeUser) return;

    let result;
    if (responseMessage === Contents.store.text.lookupInvite.notGroupMember) {
      const uid = user.id;
      const stores = [storeId];
      result = await invitedDataService.create({ userId: inviteeUser.id, group: groupId, stores, invitedBy: uid, createdAt: new Date() }, session);
    } else
      result = await invitedService.inviteToStore(groupId, storeId, inviteeUser.id, session);

    if (result.status === 'success') {
      toast.show(Contents.store.toast.appendToStoreSuccess, { type: 'success' });
    } else {
      toast.show(result.message, { type: 'error' });
    }
  };

  const onResetForm = () => {
    setVerificationText('');
    setVerificationType('');
  }

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle title={ Contents.store.header.lookupInviteUser } subTitle={activeStore.name} />
        <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
          <View style={commonStyles.container}>
            <TextInput
              mode='outlined'
              left={
                verificationType === 'email' ? <TextInput.Icon name={verificationType} /> : verificationType === '' ?
                  <TextInput.Icon name='door' /> : <TextInput.Icon name='phone' />
              }
              label={
                verificationType === '' ? Contents.lookupInviteUserScreen.placeholders.lookup : Contents.lookupInviteUserScreen.placeholders[verificationType]
              }
              value={verificationText}
              onChangeText={(str) => verifyVerificationType(str)}
              onSubmitEditing={onLookup}
              returnKeyType='done'
              style={commonStyles.baseMarginBottom}
            />
            <DefaultButton onPress={onLookup} fullWidth>
              {Contents.lookupInviteUserScreen.btn.lookup}
            </DefaultButton>
            {
              responseMessage ?
              <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter, commonStyles.basePaddingVertical]}>
                <Text style={[commonStyles.baseFontSize, commonStyles.smallPaddingHorizontal]}>{ responseMessage }</Text>
              </View>
              :
                inviteeUser ?
                  <View>
                    <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter, commonStyles.basePaddingVertical]}>
                      <Text style={[commonStyles.baseFontSize, commonStyles.smallPaddingHorizontal]}>Name</Text>
                      <Text style={[commonStyles.baseFontSize, commonStyles.smallPaddingHorizontal]}>{ inviteeUser.name.join(' ') }</Text>
                    </View>
                  </View>
                :
                  <></>
            }
          </View>
          <View style={commonStyles.buttonContainer}>
            {
              inviteeUser ?
                <DefaultButton disabled={!inviteeUser || !verificationType} onPress={onInviteUser} fullWidth>
                  { Contents.store.btn.inviteToStore }
                </DefaultButton>
                :
                <DefaultButton onPress={() => navigate('inviteNewUser', { groupId })} fullWidth>
                  { Contents.lookupInviteUserScreen.btn.newInvite }
                </DefaultButton>
            }
            {
              verificationType ?
                <DangerousActionButton onPress={onResetForm}>
                  {Contents.common.btn.reset}
                </DangerousActionButton>
                :
                <></>
            }
          </View>
        </ScrollView>
        <BackButton />
      </KeyboardAvoidingView>
    </View>
  );
}

export default withErrorBoundary(inject('store')(observer(StoreLookupInviteUser)));
