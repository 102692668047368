import { useState, useEffect, useRef, FC } from 'react';
import { View, Text, TouchableWithoutFeedback, StyleSheet, Animated, Easing } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Children } from 'types';
import { commonStyles } from 'styles';

type AccordionListItemProps = {
  title: string;
  children: Children;
  open: boolean;
  toggleOpen: () => void
}

const AccordionListItem: FC<AccordionListItemProps> = ({ title, children, open, toggleOpen }) => {
  const [iconColor, setIconColor] = useState<string>('white');
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState<number>(0);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    toggleOpen();
  };

  useEffect(() => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true
      }).start();
    }
  }, [open]);



  return (
    <>
      <TouchableWithoutFeedback onPress={() => toggleListItem()}>
        <View style={[styles.titleContainer]}>
          <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter]}>
            <Text style={[styles.titleText, open ? commonStyles.boldText : {}]}>{title}</Text>
          </View>
          <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
            <MaterialIcons name="keyboard-arrow-down" size={20} color={iconColor} />
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={commonStyles.basePaddingVertical}
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }>
          {children}
        </View>
      </Animated.View>
    </>
  );
};
export default AccordionListItem;

const styles = StyleSheet.create({
  bodyBackground: {
    backgroundColor: '#EFEFEF',
    overflow: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    paddingLeft: '1.5rem',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#EFEFEF',
    backgroundColor: '#666666'
  },
  titleText: {
    marginLeft: 10,
    color: '#DDDDDD'
  },
  bodyContainer: {
    padding: '1rem',
    paddingLeft: '1.5rem',
    textAlign: 'center',
    bottom: 0,
  },
});