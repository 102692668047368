import React, { useEffect, useState, useCallback } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform, ActivityIndicator } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { inject, observer } from 'mobx-react';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';

import { ScreensParamList, RouteStackParamList, StoreRole, StoreStoreType, UserStoreType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeRolesList'>;
  route: RouteProp<RouteStackParamList, 'storeRolesList'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};

function StoreRolesList(props: Props) {
  const { route } = props;
  const { params: { storeId } } = route;

  const { navigation: { navigate } } = props;

  const { store: { storeStore, userStore } } = props;
  const { activeStore, fetchRoles, roles: storeRoles } = storeStore;
  const { session } = userStore;

  const isFocused = useIsFocused();
  const [loading, setLoading] = useState<boolean>(false);


  const onAddRole = () => {
    navigate('storeRoleForm', { storeId });
  }

  const onOpenRole = (role: StoreRole) => {
    navigate('storeRoleForm', { storeId, roleId: role.id, pages: role.pages, name: role.name });
  }

  const init = useCallback(async () => {
    if (activeStore?.id !== storeId) setLoading(true);
    await fetchRoles(session);
    setLoading(false);
  }, [session, fetchRoles]);

  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
      {
        loading ?
          <GroupStoreViewSkeleton heading={ Contents.store.header.rolesList } />
        :
          <>
            <HeadViewWithSubTitle
              title={ Contents.store.header.rolesList }
              subTitle={ activeStore.name || '' }
              />
            <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
              <View style={commonStyles.container}>
                <View style={commonStyles.flexGrow}>
                  {
                    storeRoles.map(storeRole => (
                      <TouchableOpacity onPress={() => onOpenRole(storeRole)} key={storeRole.id}>
                        <Text style={[commonStyles.listItem, commonStyles.primaryText, commonStyles.normalText]}>
                          { storeRole.name }
                        </Text>
                      </TouchableOpacity>
                    ))
                  }
                </View>
                <View style={commonStyles.buttonContainer}>
                  <DefaultButton onPress={onAddRole} fullWidth>
                    { Contents.store.btn.addRole }
                  </DefaultButton>
                </View>
              </View>
            </ScrollView>
            <BackButton />
          </>
        }
      </KeyboardAvoidingView>
    </View>
  );
}

const StoreRolesListWithStore = withErrorBoundary(inject('store')(observer(StoreRolesList)));
export default StoreRolesListWithStore
