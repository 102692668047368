import React from "react";
import { Text } from "react-native";
import SkeletonLoader from "expo-skeleton-loader";
import { commonStyles } from 'styles';

const pages = new Array(4).fill(null);

type UserViewPagesLisLayoutProps = {
  heading: string,
}

const UserViewPagesLisLayout: React.FC<UserViewPagesLisLayoutProps> = ({ heading }) => (
  <SkeletonLoader style={[commonStyles.baseMarginBottom, commonStyles.flexGrow]}>
    <SkeletonLoader.Container style={[commonStyles.baseMarginBottom]}>
      <Text style={[commonStyles.headText]}>
        { heading }
      </Text>
    </SkeletonLoader.Container>
    <SkeletonLoader.Container style={[commonStyles.textCenter, commonStyles.baseMarginBottom]}>
      <SkeletonLoader.Item style={{ width: 250, height: 20, marginHorizontal: 'auto' }} />
    </SkeletonLoader.Container>
    <SkeletonLoader boneColor='#6300ee' highlightColor='#4287f5' style={[commonStyles.basePaddingVertical, commonStyles.basePaddingHorizontal, commonStyles.flexGrow, commonStyles.normalBorderRadius, commonStyles.whiteBackground]}>
      <SkeletonLoader.Container>
        {
          pages.map((_, i) => (<SkeletonLoader.Container key={i}>
            <SkeletonLoader.Item style={{ width: 200, height: 15, marginBottom: i !== pages.length - 1 ? 20 : 0 }} />
          </SkeletonLoader.Container>
          ))
        }
      </SkeletonLoader.Container>
    </SkeletonLoader>
    
  </SkeletonLoader>
);

export default UserViewPagesLisLayout;
