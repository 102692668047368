import { View } from 'react-native';
import { Text, TextInput } from 'react-native-paper';

import { ParentContactInformationType } from 'types';
import Contents from 'utils/contents';

import { commonStyles } from 'styles';

type Props = {
    parentContactInfo: ParentContactInformationType;
    setParentContactInfo: (info: ParentContactInformationType) => void
};

export default function ParentContactInfo(props: Props) {
    const { parentContactInfo, setParentContactInfo } = props;

    const updateParentContactInfo = (name: string, value: string) => {
        setParentContactInfo({
            ...parentContactInfo,
            [name]: value
        })
    }

    return (
        <View style={commonStyles.viewport}>
            <Text style={commonStyles.subHeaderText}>The emergency contact must be your parent or guardian.</Text>
            <TextInput
                mode='outlined'
                style={{ marginBottom: 15 }}
                label={Contents.profileScreen.placeholders.parentName}
                value={parentContactInfo.parentName}
                onChangeText={(str) => updateParentContactInfo('parentName', str)}
                returnKeyType='done'
            />
            <TextInput
                mode='outlined'
                style={{ marginBottom: 15 }}
                label={Contents.profileScreen.placeholders.parentPhoneNumber}
                value={parentContactInfo.parentPhoneNumber}
                onChangeText={(str) => updateParentContactInfo('parentPhoneNumber', str)}
                returnKeyType='done'
            />
            <TextInput
                mode='outlined'
                style={{ marginBottom: 15 }}
                label={Contents.profileScreen.placeholders.parentEmail}
                value={parentContactInfo.parentEmail}
                onChangeText={(str) => updateParentContactInfo('parentEmail', str)}
                returnKeyType='done'
            />
        </View>
    );
}
