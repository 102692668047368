/* External Dependencies */
import useSWRNative from '@nandorojo/swr-react-native'
/* Helper, Misc Data */
import { SERVER_URL } from 'utils/Constants';
import { fetchData } from 'utils';

const apiUrl = `${SERVER_URL}/page`;

function useStorePages(storeId: string) {
  const url = `${apiUrl}/store/${storeId}`;
  const { data: storePages } = useSWRNative(url, fetchData, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });
  return { storePages };
}

export default useStorePages;