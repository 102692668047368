export default {
  txt: {
    header: 'Create Ownership Group',
    paragraph: 'Let\'s get you setup quick!'
  },
  placeholders: {
    legalName: 'Legal Entity Name',
    ein: 'EIN',
    email: 'Email'
  },
  validationErrors: {
    legalNameRequired: '',
    einRequired: '',
    emailRequired: '',
    emailPattern: 'Email is invalid',
  },
  btn: 'Setup Owner Group'
}