import React, { useEffect, useState } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { inject, observer } from 'mobx-react';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';

import { ScreensParamList, RouteStackParamList, GroupRole, GroupStoreType, UserStoreType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupRolesList'>;
  route: RouteProp<RouteStackParamList, 'groupRolesList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};


function GroupRolesList(props: Props) {
  const { route } = props;
  const { params: { groupId } } = route;

  const { navigation: { navigate, goBack } } = props;

  const { store: { groupStore, userStore } } = props;
  const { activeGroup, fetchRoles, roles: groupRoles } = groupStore;
  const { session } = userStore;

  const [loading, setLoading] = useState<boolean>(false);

  // - Get all tasks list
  // - Get tasks selected for group
  const init = async () => {
    setLoading(true);
    fetchRoles(session);
    setLoading(false);
  }

  const onAddRole = () => {
    navigate('groupRoleForm', { groupId });
  }

  const onOpenRole = (role: GroupRole) => {
    navigate('groupRoleForm', { groupId, roleId: role.id, pages: role.pages, name: role.name });
  }

  useEffect(() => {
    init();
  }, [route]);

  return (
    <View style={[commonStyles.viewport]}>
      {
        loading ?
          <GenericViewSkeleton />
        :
          <>
            <HeadViewWithSubTitle title={ Contents.group.header.rolesList }  />
            <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
              <View style={commonStyles.container}>
                <View style={[commonStyles.flexGrow]}>
                  { !!groupRoles &&
                    groupRoles.map((groupRole: GroupRole) => (
                      <TouchableOpacity onPress={() => onOpenRole(groupRole)} key={groupRole.id}>
                        <Text style={[commonStyles.listItem, commonStyles.primaryText, commonStyles.normalText]}>
                          { groupRole.name }
                        </Text>
                      </TouchableOpacity>
                    ))
                  }
                </View>
                <View style={[commonStyles.buttonContainer]}>
                  <DefaultButton onPress={onAddRole} fullWidth>
                    { Contents.group.btn.addRole }
                  </DefaultButton>
                </View>
              </View>
            </ScrollView>
            <BackButton />
          </>
      }
    </View>
  );
}

const GroupRolesListWithStore = withErrorBoundary(inject('store')(observer(GroupRolesList)));
export default GroupRolesListWithStore
