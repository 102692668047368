import * as formStyles from './form';

const componentsDefaultStyles = {
    ScrollView: {
        padding: 20,
    },
    Text: {
        // fontFamily: 'Montserrat_500Medium'
    },
    Button: {
        ...formStyles.Button,
        boxSizing: 'border-box'
    },
    DangerButton: {
        ...formStyles.Button,
        ...formStyles.DangerButton
    },
    ButtonLabel: {
        ...formStyles.ButtonLabel,
    },
    DisabledButtonLabel: {
        ...formStyles.DisabledButtonLabel,
    },
    WhiteButtonLabel: {
        ...formStyles.WhiteButtonLabel,
    },
    Headline: {
        textAlign: 'center',
        // fontFamily: 'Montserrat_500Medium',
        fontWeight: 'bold',
        // fontSize: '2em',
        width: 'auto',
        marginBottom: 20
    },
    Subheading: {
        marginTop: 10,
        textAlign: 'center',
        // fontFamily: 'Montserrat_500Medium'
    },
}
export default componentsDefaultStyles;
