import React from "react";
import { ViewStyle } from "react-native";
import SkeletonLoader from "expo-skeleton-loader";
import { commonStyles } from 'styles';

type CardSkeletonProps = {
  size?: number,
  style?: ViewStyle,
}
const CardSkeleton: React.FC<CardSkeletonProps> = ({
}) => (
  <SkeletonLoader boneColor="#aaaaaa" style={[commonStyles.basePaddingVertical, commonStyles.basePaddingHorizontal, commonStyles.whiteBackground, commonStyles.baseMarginHorizontal, commonStyles.baseMarginBottom]}>
    <SkeletonLoader.Container style={[commonStyles.textCenter]}>
      <SkeletonLoader.Item style={{ width: 250, height: 20, marginHorizontal: 'auto' }} />
    </SkeletonLoader.Container>
  </SkeletonLoader>
);

export default CardSkeleton;