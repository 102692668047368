import * as React from 'react';
import { View, TouchableOpacity, Text, ViewStyle } from 'react-native';
import { Checkbox } from 'react-native-paper';


type CheckBoxProps = {
  label: string;
  status: 'checked' | 'unchecked';
  onPress?: () => void;
  wrapperStyle?: ViewStyle;
  suffixComponent?: React.ReactNode;
  disabled?: boolean;
  onLongPress?: () => void;
}
function CheckBox({ label, status, onPress, wrapperStyle, suffixComponent, disabled, onLongPress }: CheckBoxProps) {
  return (
    <View style={[{ flexDirection: 'row', alignItems: 'center' }, wrapperStyle]}>
      <TouchableOpacity 
        onLongPress={onLongPress}
        onPress={onPress} disabled={disabled} style={[{ flexDirection: 'row', alignItems: 'center' }]}>
        <Checkbox status={status} />
        <Text style={{ fontWeight: 'bold' }}>{label}</Text>
      </TouchableOpacity>
      { suffixComponent }
    </View>
  );
}

export default CheckBox;