import { OptionType } from 'types';
import Contents from '../contents';
const elementTypeOptionsList: OptionType<string>[] = [
  { label: 'Text', value: 'text' },
  { label: 'Link', value: 'link' },
  { label: 'Inner Link', value: 'innerLink' },
];

const elementFormConfig = [
  {
    name: 'elementType',
    type: 'select',
    textInputProps: {
      label: 'Element Type',
    },
    rules: {
      required: {
        value: true,
        message: 'Element Type is required',
      },
    },
    options: elementTypeOptionsList
  },
  {
    type: 'text',
    name: 'elementText',
    textInputProps: {
      label: Contents.common.placeholders.elementText,
      autoComplete: false
    },
  },
]

export const elementLinkConfig = {
  type: 'text',
  name: 'elementLink',
  textInputProps: {
    label: Contents.common.placeholders.elementLink,
    autoComplete: false
  },
};

export default elementFormConfig;
