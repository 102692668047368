import { View, Text } from 'react-native';
import type { NavigationProp } from '@react-navigation/native';
import ItemLayout from './ItemLayout';
import { commonStyles } from 'styles';
import Markdown from "react-native-markdown-display";

type Props = {
  sectionData: SectionLayoutType
};

function SectionLayout(props: Props) {
  const { sectionData } = props;
  const { title, description, blocksData } = sectionData;

  return (
    <View style={[commonStyles.baseMarginBottom]}>
      {
        title ?
          description ?
            <>
              <Text style={[commonStyles.subLargeFontSize, commonStyles.boldText]}>
                { title }
              </Text>
              <Markdown style={[commonStyles.markdown]}>{ description }</Markdown>
            </>
          :
            <Text style={[commonStyles.subLargeFontSize, commonStyles.boldText, commonStyles.baseMarginBottom]}>
              { title }
            </Text>
        :
          <></>
      }
      <ItemLayout itemsData={blocksData} />
    </View>
  );
}

export default SectionLayout;
