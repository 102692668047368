import { AsyncTrunk } from 'mobx-sync';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { configure } from 'mobx';
import UserStore from './user.class';
import GroupStore from './group.class';
import StoreStore from './store.class';

export class RootStore {
  userStore;
  groupStore;
  storeStore;
  constructor() {
    this.userStore = new UserStore();
    this.groupStore = new GroupStore();
    this.storeStore = new StoreStore();
  }
}
configure({
  useProxies: 'never',
  enforceActions: 'always'
});
export const rootStore = new RootStore();
export const trunk = new AsyncTrunk(rootStore, {
  storage: AsyncStorage
});
export default { rootStore, trunk };
