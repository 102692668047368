import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';

const apiUrl = `${SERVER_URL}/invited`;

class InvitedService {
  lookup = async (groupId: string, type: string, value: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/lookup_to_invite/${groupId}/${type}/${value}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in Invited lookup method`, error);
      return null;
    }
  }
  acceptInvite = async (groupId: string, userId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/accept_invite/${userId}/${groupId}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in Invited acceptInvite method`, error);
      return null;
    }
  }
  lookupForStore = async (groupId: string, storeId: string, type: string, value: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/lookup_to_invite_store/${groupId}/${storeId}/${type}/${value}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in Invited lookupForStore method`, error);
      return null;
    }
  }
  inviteToStore = async (groupId: string, storeId: string, userId: string, session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/invite_to_store/${groupId}/${storeId}/${userId}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in Invited inviteToStore method`, error);
      return null;
    }
  }
  sendInvitationMessage = async(session: string, targetUserId: string, text: string) => {
    try {
      const res = await axios.get(`${apiUrl}/send_invitation_message/${session}/${targetUserId}/${text}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in Invited inviteToStore method`, error);
      return null;
    }
    
  }
}

export default InvitedService;