import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';
import { OrderEntry } from '../Types';
const apiUrl = `${SERVER_URL}/groupTask`;

class GroupTaskService {
  initialize = async (groupId: string, session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/${groupId}/initializeCollection`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupTaskService / initialize method`, error);
      return null;
    }
  }

  getTasksInGroup = async (groupId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${groupId}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupTaskService / getTasksInGroup method`, error);
      return null;
    }
  }

  activate = async (groupId: string, ids: string[], session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/${groupId}/activate`, { ids }, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupTaskService / activate method`, error);
      return null;
    }
  }

  deactivate = async (groupId: string, ids: string[], session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/${groupId}/deactivate`, { ids }, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupTaskService / deactivate method`, error);
      return null;
    }
  }

  updateOrder = async (groupId: string, orderEntries: OrderEntry[], session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/${groupId}/updateOrder`, { orderEntries }, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupTaskService / updateOrder method`, error);
      return null;
    }
  }
}

export default GroupTaskService;