export default {
  text: {
    crewTools: 'Crew tools',
    crewToolsHelp: 'Please keep us in the loop'
  },
  button: {
    timesheetCorrection: 'Timesheet Correction',
    incidentReport: 'Accident / Incident Report',
    desiredHoursRequest: 'Desired Hours Request',
    reimbursementRequest: 'Reimbursement Request',
    taxExemptionForm: 'Tax Exemption Form',
    companyFeedbackForm: 'Company Feedback Form',
    cashTipReport: 'Cash Tip Report',
  },  
}