import {
    useFonts,
    Montserrat_400Regular,
    Montserrat_500Medium,
    Montserrat_600SemiBold,
    Montserrat_700Bold,
    Montserrat_300Light, Montserrat_100Thin
} from '@expo-google-fonts/montserrat';

let customFonts = {
    'Montserrat_400Regular': Montserrat_400Regular,
    'Montserrat_500Medium': Montserrat_500Medium,
    'Montserrat_600SemiBold': Montserrat_600SemiBold,
    'Montserrat_700Bold': Montserrat_700Bold,
    'Montserrat_300Light': Montserrat_300Light,
    'Montserrat_100Thin': Montserrat_100Thin
}

const _fontConfig = {
    regular: {
        fontFamily: 'Montserrat_400Regular',
        // fontWeight: 400,
    },
    medium: {
        fontFamily: 'Montserrat_500Medium',
        // fontWeight: 500,
    },
    light: {
        fontFamily: 'Montserrat_300Light',
        // fontWeight: 300,
    },
    thin: {
        fontFamily: 'Montserrat_100Thin',
        // fontWeight: 100,
    },
};

const fontConfig = {
    ios: _fontConfig,
    android: _fontConfig,
    web: _fontConfig
};



export { fontConfig, customFonts, useFonts };
