import { observable, action, computed, makeObservable, runInAction } from 'mobx';

import UserService from '../lib/Services/user.service';
import AuthService from '../lib/Services/auth.service';
import BaseDataService from '../lib/Services/baseData.service';
import { User, Task } from 'types';

const isManager = (data: any, userId: string) => {
  if (data && data.managers) return data.managers.includes(userId);
  return false;
}

// 
const replaceWithData = (originalRecord: any, lookupData: any[], sourceKey: string, targetKey: string) => {
  if (originalRecord[sourceKey]) {
    const foundMapping = lookupData.find(item => item.id === originalRecord[sourceKey])
    return {...originalRecord, [targetKey]: foundMapping };
  }
  return originalRecord;
}

class UserStore {
  userService: UserService;
  baseDataService: BaseDataService;
  authService: AuthService;
  @observable
  user: User | null = null;
  @observable
  session: string = '';
  @observable
  dashboard: Object | null = {};
  @observable
  activeTask: Task | null = null;
  @observable
  loading: boolean = false;

  constructor() {
    makeObservable(this);
    this.userService = new UserService();
    this.authService = new AuthService();
    this.baseDataService = new BaseDataService('user');
  }

  @computed get getUser() {
    return this.user;
  }

  @action addUser = (user: User) => {
    this.user = user;
  };

  @action setSession = (session: string) => {
    this.session = session;
  };

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  }

  @action setActiveTask = (task: Task) => {
    this.activeTask = task;
  }

  @computed get fetchSession() {
    return this.session;
  };

  @action verifyUserSession = async (session: string) => {
    const data = await this.authService.getSessionDetail(session);
    runInAction(() => {
      this.user = {...this.user, ...data};
    });
  };

  @action updateUser = async(userId: string, newData: User, session: string) => {
    const data = await this.baseDataService.update(userId, newData, session);
    
    runInAction(() => {
      this.user = {...this.user, ...data};
    });
  }
  fetchUser = async(userId: string, session: string) => {
    const data = await this.baseDataService.get(userId, session);
    runInAction(() => {
      this.user = {...this.user, ...data};
    });
  };
  
  @action fetchDashboard = async() => {
    if (!this.user) return;
    let data:any;
    try {
      data = await this.userService.getDashboard(this.session);
    } catch(error) {
      console.log('inside userStore / fetchDashboard', error)
      throw error;
    }
    const { groups, stores } = data;
    let user_groups = data.user_groups;
    let user_stores = data.user_stores;
    let manager_groups = [];
    const userId = this.user.id;
    if (user_groups) {
      user_groups = user_groups
        .map((user_group: any) => {
          const record = replaceWithData(user_group, groups, 'groupId', 'group');
          record.isManager = isManager(record.group, userId);
          return record;
        })
        .filter((user_group: any) => (user_group.group && user_group.group.archived !== true));
      manager_groups = user_groups.filter((user_group: any) => user_group.isManager);
      user_groups = user_groups.filter((user_group: any) => !user_group.isManager);
    }

    let manager_stores = [];
    if (user_stores) {
      user_stores = user_stores
        .map((user_store: any) => {
          const record = replaceWithData(user_store, stores, 'storeId', 'store');
          record.isManager = isManager(record.store, userId);
          return record;
        })
        .filter((user_store: any) => user_store.store && user_store.store.archived !== true)
      manager_stores = user_stores.filter((user_store: any) => user_store.isManager);
      user_stores = user_stores.filter((user_store: any) => !user_store.isManager);
    }

    let group_tasks = data.group_tasks;
    if (group_tasks) group_tasks = group_tasks.map((group_task: any) => replaceWithData(group_task, groups, 'groupId', 'group'))
    
    let invited_list = data.invited_list;
    if (invited_list) invited_list = invited_list.map((invited: any) => replaceWithData(invited, groups, 'group', 'group'))
    runInAction(() => {
      this.dashboard = { ...data, userId, user_groups, user_stores, group_tasks, invited_list, manager_groups, manager_stores };
    });
  };

  @action logout = async() => {
    await this.authService.logout();
    runInAction(() => {
      this.session = '';
      this.user = null;
      this.dashboard = null;
    });
  }
}

export default UserStore;
