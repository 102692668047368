import { useEffect, useState, useCallback } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView, Platform, TouchableOpacity } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { AntDesign, FontAwesome5, Fontisto } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useToast } from 'react-native-toast-notifications';


import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';


import InvitedService from 'lib/Services/invited.service';
import BaseDataService from 'lib/Services/baseData.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, GroupUser, GroupStoreType, User, ConfirmationDialogType, UserStoreType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';
import { primary } from '../../../Utils/Constants';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupUsersList'>;
  route: RouteProp<RouteStackParamList, 'groupUsersList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

const invitedService = new InvitedService();
const groupDataService = new BaseDataService('group');
const userGroupDataService = new BaseDataService('user_group');

function GroupPromoteOwner(props: Props) {
  const { route } = props;
  const { params: { groupId } } = route;
  const isFocused = useIsFocused();
  const { navigation: { navigate, goBack } } = props;
  const { store: {
    groupStore: { activeGroup, fetchGroupUsers, isCurrentUserManager, isCurrentUserOwner, activeGroupUser, setActiveGroupUser, owners, groupUsers },
    userStore: { session }
  } } = props;

  const [groupUsersList, setGroupUsersList] = useState<GroupUser[]>([]);
  const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogType>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  // - Get all tasks list
  // - Get tasks selected for group
  const init = useCallback(async () => {
    // No permissions to browse users list and search for users
    if (!isCurrentUserManager && !isCurrentUserOwner) {
      toast.show(Contents.common.toast.insufficientPermission, { type: 'danger' });
      goBack();
      return;
    }
    setLoading(true)
    await fetchGroupUsers(groupId, session);
    setLoading(false);
  }, [groupId]);

  const onEditUserRole = (groupUser: GroupUser) => {
    setActiveGroupUser(groupUser);
    navigate('groupUserForm', { groupId });
  }

  /* User row action handlers, openining confirmation dialog */
  const onTriggerSetOwner = (groupUser: GroupUser) => {
    setActiveGroupUser(groupUser);
    setConfirmationDialogType('setOnwer');
    setConfirmationDialogMessage(Contents.group.confirm.setAsOwner);
  }


  // Main User Action handler
  const onHandleDefaultUserActions = async() => {
    if (!activeGroupUser || !activeGroup) return;
    setLoading(true);
    const ownerIds = owners.map(owner => owner.userId);

    let newOwnerIds = [...new Set([...ownerIds, activeGroupUser.userId])];
    await groupDataService.update(activeGroup.id, { owners: newOwnerIds }, session);

    init();
    setConfirmationDialogType('');
  }


  useEffect(() => {
    if (groupUsers && groupUsers.length > 0) {
      let list = groupUsers.sort((a: User, b: User) => a.name > b.name ? 1 : -1);
      setGroupUsersList(list);
    } else {
      setGroupUsersList([]);
    }
  }, [groupUsers]);


  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
      {
        loading ?
          <GroupStoreViewSkeleton heading={Contents.group.header.admins} />
        :
          <>
            <HeadViewWithSubTitle
              title={ Contents.group.header.users }

              />
            <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
              <View style={commonStyles.container}>
                <View style={commonStyles.baseMarginBottom}>
                  <View style={commonStyles.baseMarginBottom}>
                    {
                      groupUsersList && groupUsersList.length > 0 ?
                        groupUsersList.map(groupUser => (
                          <View key={groupUser.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                            <TouchableOpacity onPress={() => onEditUserRole(groupUser)}>
                              <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ groupUser.user.name.join(' ') }</Text>
                            </TouchableOpacity>
                            <View style={[commonStyles.flex, commonStyles.flexRow]}>
                              {
                                isCurrentUserOwner &&
                                <Fontisto name="key" size={24} color={primary} style={commonStyles.smallMarginRight}
                                  onPress={() => onTriggerSetOwner(groupUser)} />
                              }
                            </View>
                          </View>
                        ))
                        :
                          <Text style={commonStyles.baseFontSize}>{ Contents.group.text.usersList.noUserExists }</Text>
                      }
                  </View>

                </View>
              </View>
            </ScrollView>
            <BackButton />
            <AwesomeAlert
              show={!!activeGroupUser && (confirmationDialogType === 'setOwner')}
              showProgress={false}
              title={ Contents.store.confirm.title }
              message={ confirmationDialogMessage }
              closeOnTouchOutside={true}
              closeOnHardwareBackPress={false}
              showCancelButton={true}
              showConfirmButton={true}
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setConfirmationDialogType('');
                setActiveGroupUser(null);
              }}
              onConfirmPressed={onHandleDefaultUserActions}
            />
          </>
        }
      </KeyboardAvoidingView>
    </View>
  );
}
const GroupPromoteOwnerWithStore = withErrorBoundary(inject('store')(observer(GroupPromoteOwner)));
export default GroupPromoteOwnerWithStore
