import { useEffect, useState, useCallback } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import UserViewPagesLisLayout from 'components/Skeleton/UserViewPagesLisLayout';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import BackButton from 'components/Button/BackButton';

import useGroupPages from 'lib/Hooks/useGroupPages';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, StoreStoreType, PageRecordType, UserStoreType, StoreUser } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'endUserStorePageList'>;
  route: RouteProp<RouteStackParamList, 'endUserStorePageList'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};

function EndUserStorePageList(props: Props) {
  const { route } = props;
  const { params: { groupId, storeId } } = route;
  const { navigation: { navigate, goBack } } = props;

  const { store: { storeStore, userStore } } = props;
  const { fetchStore, fetchStorePages, activeStore, setActivePage, roles, pages: storePages } = storeStore;
  const { session, dashboard } = userStore;

  const { groupPages } = useGroupPages(groupId);

  const isFocused = useIsFocused();

  const [pageList, setPageList] = useState<PageRecordType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const onOpenPage = (page: PageRecordType) => {
    setActivePage(page);
    navigate('storePageView', { storeId });
  }

  // - Get all tasks list
  // - Get tasks selected for store
  const init = useCallback(async () => {
    setLoading(true);
    await fetchStore(storeId, session);
    await fetchStorePages(storeId, session);
    setLoading(false);
  }, [session, storeId]);


  const buildPageList = useCallback(() => {
    const userStores = dashboard.user_stores;
    const storeUser = userStores.find((userStore: StoreUser) => userStore.storeId === storeId);
    if (storeUser) {
      let role;
      if (storeUser.roleId && roles) role = roles.find((r: any) => r.roleId === storeUser.roleId);

      if (role) {
        setPageList(role?.pages || []);
      } else {
        if (storePages && storePages.length > 0) {
          setPageList(storePages);
        } else {
          if (groupPages) setPageList(Object.values(groupPages));
        }
      }
    }
  }, [dashboard, groupPages, storePages, roles]);

  useEffect(() => {
    if (isFocused && activeStore && dashboard && activeStore.id === storeId) {
      buildPageList();
    }
  }, [dashboard, activeStore?.id, isFocused, storeId, buildPageList]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <View style={[commonStyles.viewport]} testID='end-user-store-page-list'>
      {
        loading ? <UserViewPagesLisLayout heading={ Contents.store.header.endUserStorePagesList } />
        :
        <>
          <HeadViewWithSubTitle title={ Contents.store.header.endUserStorePagesList }
            subTitle={ activeStore && activeStore.id === storeId ? activeStore.name : '' } />
          <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
            <View style={commonStyles.container}>
            {
              pageList.map((page: PageRecordType) => (
                <TouchableOpacity key={page.id} onPress={() => onOpenPage(page)}>
                  <Text style={[commonStyles.smallMarginVertical, commonStyles.primaryText]}>{ page.title }</Text>
                </TouchableOpacity>
              ))
            }
            </View>
          </ScrollView>
          <BackButton />
        </>
      }
    </View>
  );
}

const EndUserStorePageListWithStore = withErrorBoundary(inject('store')(observer(EndUserStorePageList)));
export default EndUserStorePageListWithStore
