import { Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native'

import { commonStyles } from 'styles';
import Contents from 'utils/contents';

function BackButton() {
  const navigation = useNavigation();
  const { goBack } = navigation;
  return (
    <Button style={commonStyles.backBtn} onPress={goBack} labelStyle={commonStyles.primaryText}>
      {Contents.common.btn.back}
    </Button>
  );
}

export default BackButton;
