/* External Dependencies */
import axios from 'axios';
/* Helper, Misc Data */
import { SERVER_URL } from 'utils/Constants';


const apiUrl = `${SERVER_URL}/group`;

class GroupService {
  fetchGroupDetail = async (groupId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${groupId}/detail/${session}`);
      return res.data;
    } catch(error) {
      console.log(`error in GroupService / fetchGroupDetail method`, error);
      return null;
    }
  }

  getGroupUsers = async (groupId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${groupId}/users`, {
        headers: {
          'ocSession': session
        }
      });
      const users = res.data.filter((user:any) => user?.user?.email);
      return users;
    } catch(error) {
      console.log(`error in GroupService / getGroupUsers method`, error);
      return null;
    }
  }

  getGroupStores = async (groupId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${groupId}/stores`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupService / getGroupStores method`, error);
      return null;
    }
  }

  getCurrentRole = async (groupId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${groupId}/current_role`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupService / getCurrentRole method`, error);
      return null;
    }
  }

  archive = async (groupId: string, session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/${groupId}/archive`, {}, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in GroupService / archive method`, error);
      return null;
    }
  }
}

export default GroupService;