/* External Dependencies */
import axios from 'axios';
/* Helper, Misc Data */
import { SERVER_URL } from 'utils/Constants';


const apiUrl = `${SERVER_URL}/store`;

class StoreService {
  fetchStoreDetail = async (storeId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${storeId}/detail/${session}`);
      return res.data;
    } catch(error) {
      console.log(`error in StoreService / fetchStoreDetail method`, error);
      return null;
    }
  }

  getStoreUsers = async (storeId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/${storeId}/users`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in StoreService / getStoreUsers method`, error);
      return null;
    }
  }

  removeStoreFromGroupUser = async(groupId: string, storeId: string, userId: string) => {
    try {
      const res = await axios.delete(`${apiUrl}/users/${groupId}/${userId}/${storeId}`);
      return res.data;
    } catch(error) {
      console.log(`error in StoreService / removeStoreFromGroupUser method`, error);
      return null;
    }
  }
}

export default StoreService;