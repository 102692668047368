import SkeletonLoader from "expo-skeleton-loader";
import { commonStyles } from 'styles';

const GenericViewSkeleton: React.FC<GroupStoreViewSkeletonProps> = () => (
  <SkeletonLoader style={[commonStyles.noPaddingTop, commonStyles.flexGrow, commonStyles.largeMarginBottom]}>
    <SkeletonLoader.Container style={[commonStyles.basePaddingVertical, commonStyles.basePaddingHorizontal, commonStyles.flexGrow, commonStyles.normalBorderRadius, commonStyles.whiteBackground]}>
      <SkeletonLoader.Item style={{ height: 30, marginHorizontal: 'auto', marginBottom: 10 }} />
      <SkeletonLoader.Item style={{ height: 30, marginHorizontal: 'auto', marginBottom: 10 }} />
      <SkeletonLoader.Item style={{ height: 30, marginHorizontal: 'auto', marginBottom: 10 }} />
    </SkeletonLoader.Container>    
  </SkeletonLoader>
);

export default GenericViewSkeleton;