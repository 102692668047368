import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import { TextInput, Button } from 'react-native-paper';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import DropDown from 'react-native-paper-dropdown';
import validator from 'validator';
import { useToast } from 'react-native-toast-notifications';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';

import { ScreensParamList, RouteStackParamList, UserStoreType, OptionType, Store, User, GroupStoreType } from 'types';
import BaseDataService from 'lib/Services/baseData.service';
import InvitedService from 'lib/Services/invited.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import styles from '../styles';
import { commonStyles } from 'styles';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupLookupInviteUser'>;
  route: RouteProp<RouteStackParamList, 'groupLookupInviteUser'>;
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};

const invitedDataService = new BaseDataService('invited');
const invitedService = new InvitedService();


function GroupLookupInviteUser(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { groupId } } } = props;
  const { store: { userStore, groupStore } } = props;
  const { user, session } = userStore;
  const { activeGroup, groupStores } = groupStore;
  const toast = useToast();

  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState<boolean>(false);
  const [storeDropdownValues, setStoreDropdownValues] = useState<OptionType<string>[]>([]);
  const [selectedStores, setSelectedStores] = useState<string>('');
  const [inviteeUser, setInviteeUser] = useState<User>();

  const [verificationType, setVerificationType] = useState('');
  const [verificationText, setVerificationText] = useState('');

  const verifyVerificationType = function (text: string) {
    setVerificationText(text);
    if (validator.isEmail(text)) {
      setVerificationType('email');
    } else if (validator.isMobilePhone(text)) {
      setVerificationType('phoneNumber');
    } else {
      setVerificationType('');
    }
  }

  const onLookup = async () => {
    const result = await invitedService.lookup(groupId, verificationType, verificationText, session);
    if (result) {
      setInviteeUser(result.user);
      if (result.lookup === false) {
        toast.show(result.message, { type: 'warning' });
      }
    }
  }

  const onInviteUser = async () => {
    if (!inviteeUser) return;
    const uid = user.id;
    const stores = selectedStores.trim().split(',').filter(storeId => storeId.length > 0);
    const result = await invitedDataService.create({ userId: inviteeUser.id, group: groupId, stores, invitedBy: uid, createdAt: new Date() }, session);
    // TODO: Should send invitation email or SMS.
    if (result && result.status === 'success') {
      const res = await invitedService.sendInvitationMessage(session, inviteeUser.id, `You have been invited to ${activeGroup.legalName}, owned by PWT Holdings, LLC`);
      if (res.status === 'success') {
        toast.show(Contents.lookupInviteUserScreen.toast.invitationSentSuccess + inviteeUser.name[0], { type: 'success' });
        return;
      }
    }
    toast.show(result.message, { type: 'error' });
  };

  const onResetForm = () => {
    setVerificationText('');
    setVerificationType('');
  }

  useEffect(() => {
    if (groupStores) {
      const options = groupStores.map((store: Store) => ({ label: store.name, value: store.id }));
      setStoreDropdownValues(options);
    }
  }, [groupStores]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle
          title={ Contents.group.header.inviteUser }

          />
        <View style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical, commonStyles.flexGrow]}>
          <View style={[commonStyles.flexGrow]}>
            <TextInput
              mode='outlined'
              left={
                verificationType === 'email' ? <TextInput.Icon name={verificationType} /> : verificationType === '' ?
                  <TextInput.Icon name='door' /> : <TextInput.Icon name='phone' />
              }
              label={
                verificationType === '' ? Contents.lookupInviteUserScreen.placeholders.lookup : Contents.lookupInviteUserScreen.placeholders[verificationType]
              }
              value={verificationText}
              onChangeText={(str) => verifyVerificationType(str)}
              onSubmitEditing={onLookup}
              returnKeyType='done'
              style={commonStyles.baseMarginBottom}
            />
            <DefaultButton onPress={onLookup} fullWidth>
              {Contents.lookupInviteUserScreen.btn.lookup}
            </DefaultButton>
            {
              inviteeUser &&
              <View>
                <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter, commonStyles.basePaddingVertical]}>
                  <Text style={[commonStyles.baseFontSize, styles.labelView, commonStyles.smallPaddingHorizontal]}>Name</Text>
                  <Text style={[commonStyles.baseFontSize, commonStyles.smallPaddingHorizontal]}>{ inviteeUser.name.join(' ') }</Text>
                </View>
                <DropDown
                  label={'Access to Stores'}
                  mode={'outlined'}
                  visible={showMultiSelectDropDown}
                  showDropDown={() => setShowMultiSelectDropDown(true)}
                  onDismiss={() => setShowMultiSelectDropDown(false)}
                  value={selectedStores}
                  setValue={setSelectedStores}
                  list={storeDropdownValues}
                  multiSelect
                />
              </View>
            }
          </View>


          <View style={[commonStyles.buttonContainer]}>
            {
              inviteeUser ?
                  <DefaultButton onPress={onInviteUser} fullWidth disabled={!inviteeUser || !verificationType}>
                    {Contents.lookupInviteUserScreen.btn.invite}
                  </DefaultButton>
                :
                  <DefaultButton onPress={() => navigate('inviteNewUser', { groupId })} fullWidth>
                    {Contents.lookupInviteUserScreen.btn.newInvite}
                  </DefaultButton>
            }
            {
              verificationType ?
                <Button style={[commonStyles.backBtn, commonStyles.smallMarginBottom]} onPress={onResetForm} fullWidth>
                  {Contents.common.btn.reset}
                </Button>
                :
                <></>
            }
          </View>
        </View>
        <BackButton />
      </KeyboardAvoidingView>
    </View>
  );
}

export default withErrorBoundary(inject('store')(observer(GroupLookupInviteUser)));
