import { StyleSheet, Dimensions, Platform, StatusBar } from 'react-native';
import { white, primary, MOBILE_MAX_VW, DESKTOP_CONTAINER_WIDTH } from 'utils/Constants';
const { width, height } = Dimensions.get('screen');
import componentsDefaultStyles from 'utils/Styles/componentsDefault';
import { Colors } from 'styles';
import { markdown } from './Task/markdown';

export default StyleSheet.create({
  ...componentsDefaultStyles,
  addBtnVw: {
    borderColor: primary,
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 3
  },
  btnVw: {
    width: Platform.OS === 'web' && width > MOBILE_MAX_VW ? '40%' : '70%',
    borderColor: primary,
    borderWidth: 1,
    borderRadius: 4,
    paddingHorizontal: 19,
    marginBottom: 15
  },
  btnTxt: {
    fontSize: 12,
    fontWeight: '500',
    textAlign: 'center',
    color: primary
  },
  innerView: {
    width: Platform.OS === 'web' && width > MOBILE_MAX_VW ? '90%' : '100%',
    alignSelf: 'center'
  },
  otherView: {
    width: '100%',
    paddingHorizontal: 10,
    alignSelf: 'center',
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  otherText: {
    fontSize: 16,
    fontWeight: '700'
  },
  btnBlock: {
    width: '100%',
    alignItems: 'center',
    marginTop: 30
  },
  smallButtonText: {
    fontSize: 12
  },
  onboardingWrapper: {
    minHeight: '60vh',
    maxWidth: '600px',
    alignSelf: 'center',
    marginBottom: 20
  },
  labelView: {
    width: '40%'
  },
  borderedForm: {
    borderColor: Colors.primaryGreyColor,
    borderWidth: 1,
    borderStyle: 'solid'
  },


  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: '#F194FF',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
  // Blockquotes

  blockquote: {marginBottom:10},

});
