import axios from 'axios';
import { GroupUser, StoreUser } from 'types';

export const fetchData = (apiFullURL: string) => axios.get(apiFullURL).then(res => res.data)
export const fetchDataWithToken = (apiFullURL: string, tokenStr: string) => axios.get(apiFullURL, { headers: {'Authorization' : tokenStr} }).then(res => res.data)


export const getStoreName = (store: any) => {
  const storeParts: string[] = [];
  let name = '';
  if (store['state']) {
    name += store['state']+' ';
  }
  if (store['city']) {
    name += store['city']+' ';
  }
  if (store['storeSlug']) {
    name += '['+store['storeSlug']+']';
  }
  console.log(store);
  return name;
}

export const isEmpty = (value: any) => {
  return value === undefined || value === null || value === NaN
    || (typeof value === 'object' && Object.keys(value).length === 0 )
    || (typeof value === 'string' && value.trim().length === 0);
}

export const fillCollection = (idsArray: string[], sourcesArray: any[], idField: string = 'id') => {
  const collection = sourcesArray.filter(record => idsArray.indexOf(record[idField] || '') !== -1);
  return collection;
}

export const fillUserCollection = (idsArray: string[], sourcesArray: any[], idField: string = 'id') => {
  const collection = sourcesArray
    .filter(record => idsArray.indexOf(record[idField] || '') !== -1)
    .sort((a: GroupUser, b: GroupUser) => a.user.name > b.user.name ? 1 : -1);
  return collection;
}

export const getStoreUserName = (storeUser: StoreUser) => {
  return storeUser.user && storeUser.user.name && Array.isArray(storeUser.user.name) ? storeUser.user.name.join(' ') : ''
}

export const sortStoreUsers = (a: StoreUser, b: StoreUser) => {
  const aName = getStoreUserName(a);
  const bName = getStoreUserName(b);
  return aName > bName ? 1 : -1;
}
