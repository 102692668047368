import { View, Text } from 'react-native';
import { useNavigation, ParamListBase } from '@react-navigation/native';
import type { NavigationProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import DashboardGroupStoreCard from 'components/DashboardGroupStoreCard';

import { UserStoreType, GroupStoreType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';

type Props = {
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};

function DashboardUser({
  store: {
    userStore: { dashboard, session },
    groupStore: { fetchRole }
  }
}: Props) {
  const navigation = useNavigation<NavigationProp<ParamListBase>>()


  const onOpenNormalGroup = (userGroup: any) => {
    if (userGroup?.roleId) fetchRole(userGroup?.roleId, session);
    navigation.navigate('endUserGroupPageList', { groupId: userGroup.groupId });
  }

  const onOpenNormalStore = (userStore: any) => {
    if (userStore?.roleId) fetchRole(userStore?.roleId, session);
    navigation.navigate('endUserStorePageList', { storeId: userStore.storeId });
  }

  return (
    <>
      {
        (dashboard && dashboard.user_groups && dashboard.user_groups.length > 0) &&
        (
          <View style={commonStyles.otherView}>
            <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
              {Contents.dashboardScreen.text.group}
            </Text>
            {
              dashboard.user_groups.map((user_group: any) => (
                <DashboardGroupStoreCard
                  key={user_group.group.id}
                  title={user_group.group.legalName}
                  button='Open'
                  onPress={() => onOpenNormalGroup(user_group)}
                />
              ))
            }
          </View>
        )
      }
      {
        (dashboard && dashboard.user_stores && dashboard.user_stores.length > 0) &&
        (
          <View style={commonStyles.otherView}>
            <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
              {Contents.dashboardScreen.text.store}
            </Text>
            {
              dashboard.user_stores.map((user_store: any) => (
                <DashboardGroupStoreCard
                  key={user_store.store.id}
                  title={user_store.store.name}
                  button='Open'
                  onPress={() => onOpenNormalStore(user_store)}
                />
              ))
            }
          </View>
        )
      }
    </>
  );
}

const DashboardUserWithStore = withErrorBoundary(inject('store')(observer(DashboardUser)));

export default DashboardUserWithStore;
