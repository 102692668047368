const lookupInviteUserScreen = {
  txt: {
    header: 'Lookup'
  },
  placeholders: {
    lookup: 'Lookup',
    phone: 'Mobile Phone',
    email: 'Email Address',
    confirm: 'Conformation Code'
  },
  btn: {
    lookup: 'Lookup',
    invite: 'Invite to Group',
    newInvite: 'Invite a new User',
  },
  toast: {
    invitationSentSuccess: 'An invitation has been successfully sent to '
  }
};

export default lookupInviteUserScreen;