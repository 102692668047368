import verificationFormScreen from './verificationFormScreen';
import confirmationCodeScreen from './confirmationCodeScreen';
import createProfileScreen from './createProfileScreen';
import profileScreen from './profileScreen';
import common from './common';
import group from './group';
import store from './store';
import onboarding from './onboarding';
import ownershipGroupScreen from './ownershipGroupScreen';
import lookupInviteUserScreen from './lookupInviteUserScreen';
import inviteNewUserScreen from './inviteNewUserScreen';
import dashboardScreen from './dashboardScreen';
import crewToolsScreen from './crewToolsScreen';
import staticPageScreen from './staticPageScreen';

export default {
  'title': 'CookieCrew',
  internalUseOnly: '*For internal use only*',
  common,
  verificationFormScreen,
  confirmationCodeScreen,
  createProfileScreen,
  profileScreen,
  ownershipGroupScreen,
  lookupInviteUserScreen,
  inviteNewUserScreen,
  welcomeScreen: {
    wtx: 'Bake the world a better place!',
    wst: 'Bake the world a better place!',
    btx: 'Login / Register'
  },

  notInvitedScreen: {
    txt: {
      welcome: 'Welcome!',
      paragraph:
        'You haven\'t yet been invited to a store. Please request a manager/owner to add you!',
      navigate: 'Store owners Click Here'
    }
  },
  ownerNotInvitedScreen: {
    txt: {
      header: 'Why hello!',
      paragraph: 'Your account has not yet been added to an ownership group.',
      middlePara:
        'Reach out to the manager of your owner group or create a new group below.',
      navigate: 'Create an Ownership Group'
    }
  },
  dashboardScreen,
  crewToolsScreen,
  staticPageScreen,
  group,
  store,
  onboarding,
  statisticsCard: {
    txt: {
      invited: 'Invited',
      onBoarding: 'Onboarding',
      done: 'Done'
    }
  },
};
