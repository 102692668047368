import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { View, ScrollView, Image, Text, KeyboardAvoidingView, Platform, Dimensions, StyleSheet, Animated, Easing, TouchableOpacity } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import DraggableFlatList, { RenderItemParams, OpacityDecorator } from 'react-native-draggable-flatlist';
import { inject, observer } from 'mobx-react';
import { Ionicons } from '@expo/vector-icons';
import SortableList from 'react-native-sortable-list';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';

import { ScreensParamList, RouteStackParamList, StoreStoreType, UserStoreType, PageRecordType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';
const window = Dimensions.get('window');


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storePageList'>;
  route: RouteProp<RouteStackParamList, 'storePageList'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};


function Row(props) {
  const { active, data, onEditRow } = props;

  const activeAnim = useRef(new Animated.Value(0));
  const style = useMemo(
    () => ({
      ...Platform.select({
        ios: {
          transform: [
            {
              scale: activeAnim.current.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 1.07],
              }),
            },
          ],
          shadowRadius: activeAnim.current.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 10],
          }),
        },

        android: {
          transform: [
            {
              scale: activeAnim.current.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 1.07],
              }),
            },
          ],
          elevation: activeAnim.current.interpolate({
            inputRange: [0, 1],
            outputRange: [2, 6],
          }),
        },
      }),
    }),
    [],
  );
  useEffect(() => {
    Animated.timing(activeAnim.current, {
      duration: 300,
      easing: Easing.bounce,
      toValue: Number(active),
      useNativeDriver: true,
    }).start();
  }, [active]);

  return (
    <Animated.View style={[commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical, commonStyles.flex, commonStyles.itemsCenter, commonStyles.spaceBetween,
      commonStyles.shadowedView, commonStyles.baseMarginBottom]}>
      <Text>{ data.text }</Text>
      <TouchableOpacity onPress={() => onEditRow(data.id )} style={commonStyles.largeMarginRight}>
        <Ionicons name='settings' size={24} color='black' />
      </TouchableOpacity>
    </Animated.View>
  );
}

function StorePageSetting(props: Props) {
  const { route } = props;
  const { params: { storeId } } = route;
  const { navigation: { navigate, goBack } } = props;

  const { store: { storeStore, userStore } } = props;
  const { activeStore, fetchStorePages, setActivePage, pages } = storeStore;
  const { session } = userStore;

  const [pageData, setPageData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);


  const updateSetting = async () => {
    setLoading(true);
    setLoading(false);
  };

  // - Get all tasks list
  // - Get tasks selected for store
  const init = async () => {
    setLoading(true);
    await fetchStorePages(storeId, session);
    setLoading(false);
  }

  const onToNewPage = () => {
    setActivePage(null);
    navigate('storePageForm', { storeId });
  }

  const setupPageData = (pages: PageRecordType[]) => {
    if (pages && pages.length > 0) {
      const pData = pages.reduce((acc: any, cur: PageRecordType) => ({...acc, [cur.id]: { text: cur.title, id: cur.id }}), {})
      setPageData(pData);
    }
  }

  useEffect(() => {
    setupPageData(pages)
  }, [pages])


  useEffect(() => {
    init();
  }, [route]);

  const renderRow = useCallback(({data, active}) => {
    return <Row data={data} active={active} onEditRow={() => navigate('storePageForm', { storeId, pageId: data.id })}/>;
  }, []);

  const onChangeOrder = (nextOrder: any) => {
    console.log('on change order', nextOrder)

  }

  return (
    <View style={[commonStyles.viewport]}>
    {
      loading ?
        <GroupStoreViewSkeleton heading={Contents.store.header.pageSetting} />
      :
      <>
        <HeadViewWithSubTitle
          title={ Contents.store.header.pageSetting }
          subTitle={ activeStore.name || '' }
          />
        <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
          <View style={commonStyles.container}>
            <View style={commonStyles.flexGrow}>
              <SortableList
                style={styles.list}
                contentContainerStyle={styles.contentContainer}
                data={pageData}
                renderRow={renderRow}
                onChangeOrder={onChangeOrder}
              />
            </View>
            <View style={commonStyles.buttonContainer}>
              <DefaultButton disabled={loading} onPress={onToNewPage} fullWidth>
              { Contents.store.btn.addPage }
              </DefaultButton>
              <DefaultButton disabled={loading} onPress={updateSetting} fullWidth>
              { Contents.common.btn.update }
              </DefaultButton>
            </View>
          </View>
        </ScrollView>
        <BackButton />

      </>
    }
    </View>
  );
    {/*

    {
      loading ?
        <GroupStoreViewSkeleton heading={contents.store.header.pageSetting} />
      :
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{ flex: 1 }}
        >
          <HeadViewWithSubTitle
            title={ contents.store.header.pageSetting }
            subTitle={ activeStore.name || '' }
            />
          <View style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical]}
            contentContainerStyle={commonStyles.flexGrow}>
            <View style={[commonStyles.formWrapper, commonStyles.basePaddingBottom, commonStyles.flexGrow]}>
              <SortableList
                style={styles.list}
                contentContainerStyle={styles.contentContainer}
                data={data}
                renderRow={renderRow}
              />
              {/*
              <DraggableFlatList
                data={pagesList}
                onDragEnd={({ data }) => setPagesList(data)}
                keyExtractor={(page) => page.id}
                renderItem={renderItem}
              />
              </View>
              <View style={commonStyles.baseMarginTop}>
              <DefaultButton disabled={loading} onPress={onToNewPage}>
              { contents.store.btn.addPage }
              </DefaultButton>
              <DefaultButton disabled={loading} onPress={updateSetting}>
              { contents.common.btn.update }
              </DefaultButton>
              </View>
              </View>

              </KeyboardAvoidingView>
            }
            </View>
          */}
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee',
    ...Platform.select({
      ios: {
        paddingTop: 20,
      },
    }),
  },
  title: {
    fontSize: 20,
    paddingVertical: 20,
    color: '#999999',
  },
  list: {
    flex: 1,
  },
  contentContainer: {
    width: window.width,
    ...Platform.select({
      ios: {
        paddingHorizontal: 30,
      },
      android: {
        paddingHorizontal: 0,
      },
    }),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
    height: 80,
    flex: 1,
    marginTop: 7,
    marginBottom: 12,
    borderRadius: 4,
    ...Platform.select({
      ios: {
        width: window.width - 30 * 2,
        shadowColor: 'rgba(0,0,0,0.2)',
        shadowOpacity: 1,
        shadowOffset: {height: 2, width: 2},
        shadowRadius: 2,
      },
      android: {
        width: window.width - 30 * 2,
        elevation: 0,
        marginHorizontal: 30,
      },
    }),
  },
  image: {
    width: 50,
    height: 50,
    marginRight: 30,
    borderRadius: 25,
  },
  text: {
    fontSize: 24,
    color: '#222222',
  },
});

const StorePageSettingWithStore = withErrorBoundary(inject('store')(observer(StorePageSetting)));
export default StorePageSettingWithStore
