export default {
  header: {
    groupOwnerView: 'Manage',
    groupPageView: 'Pages',
    groupPageList: 'Pages',
    endUserGroupPageList: 'Pages',
    users: 'Group Users',
    admins: 'Group Admins',
    pageSetting: 'Group Page Setting',
    taskSetting: 'Group Task Setting',
    rolesList: 'Group Roles List',
    rolesForm: 'Group Roles Form',
    pagesForm: 'Group Pages Form',
    sectionForm: 'Group Section Form',
    elementForm: 'Group Element Form',
    onboarding: 'Onboarding',
    editGroupUserRole: 'Edit User',
    inviteUser: 'Invite User'
  },
  text: {
    groupViewStore: 'STORES',
    groupViewOwners: 'Owners',
    usersList: {
      managers: 'Managers',
      owners: 'Owners',
      users: 'Users',
      noOwnerExists: 'There is no owner in group yet.',
      noManagerExists: 'There is no manager in group yet.',
      noUserExists: 'There is no user in group yet.'
    },
  },
  placeholders: {
    role: 'Role',
    roleName: 'Role Name',
    pageName: 'Page Name',
    sectionTitle: 'Section Title',
    sectionDescription: 'Section Description',
    elementName: 'Element Name'
  },
  toast: {
    roleUpdateSuccess: 'The role has been updated successfully',
    roleCreateSuccess: 'The role has been created successfully',
    roleDeleteSuccess: 'The role has been deleted successfully',
    roleNameRequired: 'The role name is required.',
    pageUpdateSuccess: 'The page has been updated successfully',
    pageCreateSuccess: 'The page has been created successfully',
    pageDeleteSuccess: 'The page has been deleted successfully',
    defaultPageSuccess: 'The page has been marked as default page successfully',
    sectionUpdateSuccess: 'The section has been updated successfully',
    sectionCreateSuccess: 'The section has been created successfully',
    sectionDeleteSuccess: 'The section has been deleted successfully',
    elementUpdateSuccess: 'The element has been updated successfully',
    elementCreateSuccess: 'The element has been created successfully',
    elementDeleteSuccess: 'The element has been deleted successfully',
    taskSettingUpdateSuccess: 'Group onboarding task settings has been successfully updated.',
    taskSettingUpdateError: 'An error occurred while updating group onboarding task settings.',
    onboardingLoadError: 'An error occurred while loading group onboarding data.',
    userUpdateSuccess: 'User has been updated successfully',
    apiError: 'There seems to be a problem with the server, please try again later or contact administrator.',
    archiveSuccess: 'The group has been archived successfully.',
    archiveError: 'An error occurred while archiving group.',
    noPageTitleWarning: 'The page title is required.'
  },
  confirm: {
    title: 'Confirmation',
    archiveGroup: 'Are you sure to archive this group?',
    removePage: 'Are you sure to remove this page?',
    removeSection: 'Are you sure to remove this section?',
    removeElement: 'Are you sure to remove this element?',
    removeUser: 'Are you sure to remove this user from group?',
    setAsManager: 'Are you sure to make this user as Manager?',
    revokeManager: 'Are you sure to revoke Manager role from this user?',
    setAsOwner: 'Are you sure to make this user as Owner?',
  },
  btn: {
    addPage: 'Add Page',
    makeDefaultPage: 'Make a Default Page',
    alreadyDefaultPage: 'Group Default Page',
    addRole: 'Add Role',
    addSection: 'Add Section',
    addElement: 'Add Element',
    users: 'Manage Users',
    admins: 'Manage Admin',
    addUser: 'Add User',
    addOwner: 'Add Owner',
    notification: 'notification settings',
    pageSetting: 'Page Setting',
    onboardingTaskSetting: 'Onboarding Task Setting',
    owner: 'ownership group setting',
    roleManagement: 'Role Management',
    setAsManager: 'Set As Manager',
    revokeManager: 'Revoke Manager Role',
    removeUser: 'Remove User'
  },
};
