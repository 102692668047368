import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';

const apiUrl = `${SERVER_URL}/userTask`;

class UserTaskService {

  setupOnboarding = async (userId: string, groupId: string, session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/setupOnboarding`, {
        userId,
        groupId
      }, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in UserTask / setupOnboarding method`, error);
      return null;
    }
  }

  list = async (userId: string, groupId: string, session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/list`, {
        userId,
        groupId
      }, {
        headers: {
          'ocSession': session,
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in UserTask / list method`, error);
      return null;
    }
  }

  callEndpoint = async(url: string, token:string='') => {
    try {
      let token_add = url.includes('?') ? '&' : '?' + `token=${token}`;
      const res = await axios.get(`${SERVER_URL}${url}${token_add}`, {
        headers: {
          'ocSession': token,
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in UserTask / callEndpoint method`, error);
      return null;
    }
  }

  markDone = async(userTaskId: string, taskId:string, session: string) => {
    try {
      const res = await axios.post(`${apiUrl}/markDone`, {
        userTaskId,
        taskId
      }, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in UserTask / markDone method`, error);
      return null;
    }
  }
}

export default UserTaskService;
