import {primary} from "../Constants";


const markdownStyles = {
    blockquote: {
        marginBottom:10,
        borderLeftColor: primary
    },
}
export default markdownStyles;
