import { useEffect, useState, useCallback } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView, Platform, TouchableOpacity } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { AntDesign, FontAwesome5, Fontisto } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useToast } from 'react-native-toast-notifications';


import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';


import InvitedService from 'lib/Services/invited.service';
import BaseDataService from 'lib/Services/baseData.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, GroupUser, GroupStoreType, User, ConfirmationDialogType, UserStoreType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupUsersList'>;
  route: RouteProp<RouteStackParamList, 'groupUsersList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

const invitedService = new InvitedService();
const groupDataService = new BaseDataService('group');
const userGroupDataService = new BaseDataService('user_group');

function GroupUsersList(props: Props) {
  const { route } = props;
  const { params: { groupId } } = route;
  const isFocused = useIsFocused();
  const { navigation: { navigate, goBack } } = props;
  const { store: {
    groupStore: { activeGroup, fetchGroupUsers, isCurrentUserManager, isCurrentUserOwner, activeGroupUser, setActiveGroupUser, owners, managers, groupUsers },
    userStore: { session }
  } } = props;

  const [groupUsersList, setGroupUsersList] = useState<GroupUser[]>([]);
  const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogType>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [activeUser, setActiveUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  // - Get all tasks list
  // - Get tasks selected for group
  const init = useCallback(async () => {
    // No permissions to browse users list and search for users
    if (!isCurrentUserManager && !isCurrentUserOwner) {
      toast.show(Contents.common.toast.insufficientPermission, { type: 'danger' });
      goBack();
      return;
    }
    setLoading(true)
    await fetchGroupUsers(groupId, session);
    setLoading(false);
  }, [groupId]);

  const onEditUserRole = (groupUser: GroupUser) => {
    setActiveGroupUser(groupUser);
    navigate('groupUserForm', { groupId });
  }

  const onEditManager = (user: GroupUser) => {
    const groupUser = groupUsers.find((gUser: GroupUser) => gUser.userId === user.userId);
    if (groupUser) {
      setActiveGroupUser(groupUser);
      navigate('groupUserForm', { groupId });
    }
  }

  /* User row action handlers, openining confirmation dialog */
  const onTriggerSetManager = (groupUser: GroupUser) => {
    setActiveGroupUser(groupUser);
    setConfirmationDialogType('setManager');
    setConfirmationDialogMessage(Contents.group.confirm.setAsManager);
  }

  const onTriggerRevokeManager = (user: User) => {
    setActiveUser(user);
    setConfirmationDialogType('revokeManager');
    setConfirmationDialogMessage(Contents.group.confirm.revokeManager);
  }

  const onTriggerDeleteUser = (groupUser: GroupUser) => {
    setActiveGroupUser(groupUser);
    setConfirmationDialogType('delete');
    setConfirmationDialogMessage(Contents.group.confirm.removeUser);
  }


  // Main User Action handler
  const onHandleDefaultUserActions = async() => {
    if (!activeGroupUser || !activeGroup) return;
    setLoading(true);
    const managerIds = managers.map(manager => manager.userId);
    switch(confirmationDialogType) {
      case 'delete':
        await userGroupDataService.delete(activeGroupUser.id, session);
        break;
      case 'setManager':
        let newManagerIds = [...new Set([...managerIds, activeGroupUser.userId])];
        await groupDataService.update(activeGroup.id, { managers: newManagerIds }, session);
        break;
    }

    init();
    setConfirmationDialogType('');
  }

  const onRevokeManager = async () => {
    if (!activeUser || !activeGroup) return;
    setLoading(true);

    const managerIds = managers.map((manager: GroupUser) => manager.userId);
    let updatedManagerIds = managerIds.filter((managerId: string) => managerId !== activeUser.id);
    await groupDataService.update(activeGroup.id, { managers: updatedManagerIds }, session);
    setLoading(false);
  }

  const onSendTestInvitationMessage = async (groupUser: GroupUser) => {
    const res = await invitedService.sendInvitationMessage(session, groupUser.userId, `You have been invited to ${activeGroup.legalName}, owned by PWT Holdings, LLC`);
    console.log("res status", res);
  }

  useEffect(() => {
    const managerIds = managers.map((manager: GroupUser) => manager.userId);

    console.log('group users', groupUsers)

    if (groupUsers && groupUsers.length > 0) {
      let list = groupUsers
        .filter((groupUser: User) => managerIds.includes(groupUser.id) === false)
        .sort((a: User, b: User) => a.name > b.name ? 1 : -1);
      setGroupUsersList(list);
    } else {
      setGroupUsersList([]);
    }
  }, [groupUsers, managers]);


  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
      {
        loading ?
          <GroupStoreViewSkeleton heading={Contents.group.header.users} />
        :
          <>
            <HeadViewWithSubTitle
              title={ Contents.group.header.users }

              />
            <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
              <View style={commonStyles.container}>
                {
                  /* Owners List */
                  isCurrentUserOwner ?
                    <View style={commonStyles.baseMarginBottom}>
                      <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>
                        { Contents.group.text.usersList.owners }
                      </Text>
                      {
                        (owners && owners.length > 0) ?
                          owners.map(groupUser => (
                            <View key={groupUser.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                              <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ groupUser.user.name.join(' ') }</Text>
                            </View>
                          ))
                        :
                        <Text style={commonStyles.baseFontSize}>{ Contents.group.text.usersList.noOwnerExists }</Text>
                      }
                    </View>
                  :
                    <></>
                }
                {
                  /* Managers List */
                  (isCurrentUserOwner || isCurrentUserManager) ?
                    <View style={commonStyles.baseMarginBottom}>
                      <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>
                        { Contents.group.text.usersList.managers }
                      </Text>
                      {
                        (managers && managers.length > 0) ?
                          managers.map(groupManager => (
                            <View key={groupManager.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                              <TouchableOpacity onPress={() => onEditManager(groupManager)}>
                                <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ groupManager.user.name.join(' ') }</Text>
                              </TouchableOpacity>
                              <View style={[commonStyles.flex, commonStyles.flexRow]}>
                                {
                                  isCurrentUserOwner &&
                                    <FontAwesome5 name="keycdn" size={24} color="black" style={commonStyles.smallMarginRight}
                                      onPress={() => onTriggerRevokeManager(groupManager)} />
                                }
                              </View>
                            </View>
                          ))
                        :
                        <Text style={[commonStyles.baseFontSize]}>{ Contents.group.text.usersList.noManagerExists }</Text>
                      }
                    </View>
                  :
                    <></>
                }
                <View style={commonStyles.baseMarginBottom}>
                  <View style={commonStyles.baseMarginBottom}>
                    <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>
                      { Contents.group.text.usersList.users }
                    </Text>
                    {
                      groupUsersList && groupUsersList.length > 0 ?
                        groupUsersList.map(groupUser => (
                          <View key={groupUser.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                            <TouchableOpacity onPress={() => onEditUserRole(groupUser)}>
                              <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ groupUser.user.name.join(' ') }</Text>
                            </TouchableOpacity>
                            <View style={[commonStyles.flex, commonStyles.flexRow]}>
                              {
                                isCurrentUserOwner &&
                                <Fontisto name="key" size={24} color="white" style={commonStyles.smallMarginRight}
                                  onPress={() => onTriggerSetManager(groupUser)} />
                              }
                              <FontAwesome5 name="sms" size={24} color="black" onPress={() => onSendTestInvitationMessage(groupUser)} />
                              <AntDesign name='delete' size={24} color='black' onPress={() => onTriggerDeleteUser(groupUser)}/>
                            </View>
                          </View>
                        ))
                        :
                          <Text style={commonStyles.baseFontSize}>{ Contents.group.text.usersList.noUserExists }</Text>
                      }
                  </View>

                </View>
              </View>
              <View style={commonStyles.buttonContainer}>
                <DefaultButton onPress={() => navigate('groupLookupInviteUser', { groupId })} fullWidth>
                  { Contents.group.btn.addUser }
                </DefaultButton>
              </View>
            </ScrollView>
            <BackButton />
            <AwesomeAlert
              show={!!activeGroupUser && (confirmationDialogType === 'setManager' || confirmationDialogType === 'delete')}
              showProgress={false}
              title={ Contents.store.confirm.title }
              message={ confirmationDialogMessage }
              closeOnTouchOutside={true}
              closeOnHardwareBackPress={false}
              showCancelButton={true}
              showConfirmButton={true}
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setConfirmationDialogType('');
                setActiveGroupUser(null);
              }}
              onConfirmPressed={onHandleDefaultUserActions}
            />
            <AwesomeAlert
              show={confirmationDialogType === 'revokeManager'}
              showProgress={false}
              title={ Contents.store.confirm.title }
              message={ confirmationDialogMessage }
              closeOnTouchOutside={true}
              closeOnHardwareBackPress={false}
              showCancelButton={true}
              showConfirmButton={true}
              confirmButtonColor="#DD6B55"
              onCancelPressed={() => {
                setConfirmationDialogType('');
                setActiveUser(null);
              }}
              onConfirmPressed={onRevokeManager}
            />
          </>
        }
      </KeyboardAvoidingView>
    </View>
  );
}
const GroupUsersListWithStore = withErrorBoundary(inject('store')(observer(GroupUsersList)));
export default GroupUsersListWithStore
