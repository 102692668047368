import { View, ActivityIndicator } from 'react-native';
import { primary } from 'utils/Constants';
import { commonStyles } from 'styles';

export default () => {
  return (
    <View style={commonStyles.spinnerContainer}>
      <ActivityIndicator size='large' color={primary} />
    </View>
  );
};
