import {useState} from 'react';
import {Platform, KeyboardAvoidingView, View} from 'react-native';
import {Text, TextInput, Button} from 'react-native-paper';
import type {NavigationProp, RouteProp} from '@react-navigation/native';
import {inject, observer} from 'mobx-react';
import {useToast} from 'react-native-toast-notifications';

import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import {
    ScreensParamList,
    UserStoreType,
    RouteStackParamList
} from 'types';

import Contents from 'utils/contents';
import {isEmpty} from 'utils';
import DefaultButton from 'components/Button/DefaultButton';
import {commonStyles, componentsDefaultStyles} from 'styles';
import styles from './styles';
import AuthService from '../../lib/Services/auth.service';

type Props = {
    navigation: NavigationProp<ScreensParamList, 'confirmationCode'>;
    route: RouteProp<RouteStackParamList, 'confirmationCode'>;
    store: { userStore: UserStoreType };
};

const authService = new AuthService();

function ConfirmationCode(props: Props) {
    const {
        navigation: {navigate},
        store: {
            userStore: {setSession, verifyUserSession}
        }
    } = props;
    const {
        route: {
            params: {verificationType, verificationText}
        }
    } = props;
    const toast = useToast();
    const [focus, setFocus] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');

    const onSubmit = async () => {
        if (!isEmpty(verificationCode)) {
            const res = await authService.checkVerificationCode(
                verificationType,
                verificationText,
                verificationCode
            );
            if (res.status === 'error') {
                toast.show(res.message, {type: 'danger'});
            } else if (res.status === 'approved') {
                setSession(res.session);
                await verifyUserSession(res.session);
                toast.show(
                    Contents.confirmationCodeScreen.toast.verifyUserSessionSuccess,
                    {type: 'success'}
                );
                navigate('dashboard');
            }
        }
    };

    const onResendCode = async () => {
        const res = await authService.sendAuthToken(
            verificationType,
            verificationText
        );
        if (res.status === 'pending') {
            toast.show(
                Contents.verificationFormScreen.txt['code_sent_' + verificationType],
                {type: 'warning'}
            );
        } else {
            toast.show(res.message, {type: 'warning'});
        }
    };

    return (
        <View style={commonStyles.viewport}>
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                style={commonStyles.keyboardAvoidingView}>
                <View style={commonStyles.formContainer}>
                    <View style={[commonStyles.headViewWithNoMargin]}>
                        <Text style={commonStyles.headText}>
                            {Contents?.confirmationCodeScreen?.txt?.header}
                        </Text>
                    </View>
                    <View style={[styles.formWrapper]}>
                        <TextInput
                            style={commonStyles.largeMarginTop}
                            mode='outlined'
                            left={<TextInput.Icon name='safe'/>}
                            label={
                                Contents?.confirmationCodeScreen?.placeholders?.verification
                            }
                            value={verificationCode}
                            onChangeText={(str) => setVerificationCode(str)}
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            onSubmitEditing={onSubmit}
                            returnKeyType='done'
                        />
                    </View>
                    <View style={[commonStyles.baseMarginTop, {alignSelf: 'center'}]}>
                        <View style={commonStyles.containerButtonRow}>
                            <DefaultButton
                                style={[commonStyles.baseMarginTop]}
                                onPress={onSubmit}>
                                {Contents?.confirmationCodeScreen?.btn?.confirm}
                            </DefaultButton>
                            <DefaultButton
                                mode='outlined'
                                style={[
                                    commonStyles.smallMarginBottom, commonStyles.baseMarginTop
                                ]}
                                onPress={onResendCode}>
                                {Contents?.confirmationCodeScreen?.btn?.resendCode}
                            </DefaultButton>
                        </View>
                    </View>
                    <View style={{alignSelf: 'center'}}>
                        <Button
                            style={[componentsDefaultStyles.Button, styles.button]}
                            mode='text'
                            onPress={() =>
                                navigate('verificationForm', {type: '', value: ''})
                            }>
                            {Contents?.confirmationCodeScreen?.btn?.reset}
                        </Button>
                    </View>
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}

export default withErrorBoundary(inject('store')(observer(ConfirmationCode)));
