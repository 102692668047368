export default {
  text: {
    header: 'Dashboard',
    store: 'STORE(S)',
    group: 'GROUP(S)',
    ownershipGroup: 'OWNERSHIP GROUP(S)',
    managerGroup: 'MANAGER GROUP(S)',
    user: 'User Dashboard',
    manager: 'Manager Dashboard',
    owner: 'Owner Dashboard',
    externalLinks: 'External Links',
    donation: 'Donation & Fundraisers',
    donationHelp: 'This is where you send people to learn about donations',
    managementTools: 'Management tools',
    managementToolsHelp: 'With great power comes greater responsibility...',
  },
  cardAction: {
    accept: 'Accept'
  },
  leftSidebar: {
    signOut: 'Sign Out'
  }
}
