/* External Dependencies */
import useSWRNative from '@nandorojo/swr-react-native'
/* Helper, Misc Data */
import { SERVER_URL } from 'utils/Constants';
import { fetchData } from 'utils';

const apiUrl = `${SERVER_URL}/page`;

function useGroupPageDetail(groupId: string, pageId: string) {
  let url = `${apiUrl}/group/${groupId}/${pageId}`;
  const { data } = useSWRNative(url, fetchData, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });
  const groupPageDetail = data && data[0] ? data[0] : null;
  return { groupPageDetail };
}

export default useGroupPageDetail;
