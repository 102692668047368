import { View, Text, TouchableOpacity } from 'react-native';
import { StyleSheet } from 'react-native';

import { StatisticsCardProps } from 'types';

import Content from 'utils/contents';
import { placeholder, gold, green, white } from 'utils/Constants';
import { commonStyles } from 'styles';

export default function StatisticsCard({
  name,
  invited,
  onBoarding,
  done,
  onPress
}: StatisticsCardProps) {
  return (
    <View style={[styles.container, commonStyles.whiteBackground, commonStyles.shadowedView]} testID="statistics-card">
      <TouchableOpacity onPress={onPress}>
        {
          name &&
          <View>
            <Text style={commonStyles.baseFontSize}>{name}</Text>
          </View>
        }
        <View style={styles.points}>
          <View style={styles.point}>
            <View
              style={[styles.circleContainer, { backgroundColor: placeholder }]}
            >
              <Text style={styles.circleTxt}>{invited}</Text>
            </View>
            <Text>{Content.statisticsCard.txt.invited}</Text>
          </View>
          <View style={styles.point}>
            <View style={[styles.circleContainer, { backgroundColor: gold }]}>
              <Text style={styles.circleTxt}>{onBoarding}</Text>
            </View>
            <Text>{Content.statisticsCard.txt.onBoarding}</Text>
          </View>
          <View style={styles.point}>
            <View style={[styles.circleContainer, { backgroundColor: green }]}>
              <Text style={styles.circleTxt}>{done}</Text>
            </View>
            <Text>{Content.statisticsCard.txt.done}</Text>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: white,
    borderWidth: 1,
    borderColor: placeholder,
    padding: 10,
    marginBottom: 5,
    shadowColor: 'black',
    shadowOpacity: 0.26,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    elevation: 3,
    alignSelf: 'center',
  },
  text: {
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: 0.25
  },
  points: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 10
  },
  point: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  circleContainer: {
    padding: 5,
    borderRadius: 100,
    backgroundColor: 'red',
    marginRight: 5,
    minWidth: 25
  },
  circleTxt: {
    fontSize: 12,
    fontWeight: '500',
    color: 'white',
    textAlign: 'center'
  }
});
