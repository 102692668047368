import { View, StyleProp, ViewStyle } from 'react-native';

import LayoutItem from '../LayoutItem';

import { StaticPageItemType, OneColumnItem } from 'types';

import { commonStyles } from 'styles';

type Props = {
  item: OneColumnItem;
  onPress: (data: StaticPageItemType) => void;
  style?: StyleProp<ViewStyle>;
};

function HalfColumnItemLayout({
  item,
  onPress,
  style
}: Props) {
  return (
    <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.smallMarginBottom, style]}>
      <View style={[commonStyles.flexWrap, commonStyles.smallMarginRight]}>
        <LayoutItem item={item} onPress={() => onPress(item)} />
      </View>
      <View style={[commonStyles.flexWrap, commonStyles.smallMarginLeft]}>
      </View>
    </View>
  );
}

export default HalfColumnItemLayout;
