import { useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { TextInput } from 'react-native-paper';
import { FormBuilder } from 'react-native-paper-form-builder';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import { useForm } from 'react-hook-form';
import DefaultButton from 'components/Button/DefaultButton';

import { ElementRecordType, OptionType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';
import { elementFormConfig, elementLinkConfig } from 'utils/FormConfig';


type Props = {
  element: ElementRecordType;
  onUpdateElement: (element: ElementRecordType) => void;
  pageOptions: OptionType<string>[]
};


function ElementForm(props: Props) {
  const { element, onUpdateElement, pageOptions } = props; // from parent
  const { control, setFocus, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      elementType: 'text',
      elementText: '',
      elementLink: '',
      targetPage: ''
    },
    mode: 'onChange',
  });
  const { elementType } = watch();

  const [formConfig, setFormConfig] = useState<Omit<LogicProps, 'control'>>([]);

  const buildFormConfig = useCallback((type: string) => {
    const targetPageConfig = {
      type: 'autocomplete',
      name: 'targetPage',
      defaultValue: '',
      textInputProps: {
        label: Contents.store.placeholders.targetPage,
        autoComplete: false,
      },
      options: pageOptions,
    };
    if (type === 'text') setFormConfig(elementFormConfig);
    if (type === 'link') setFormConfig([...elementFormConfig, elementLinkConfig ]);
    if (type === 'innerLink') setFormConfig([...elementFormConfig, targetPageConfig ]);
    // setTimeout(() => reset({ elementType: type, elementText: element.text, elementLink: element.link || '', target: element.targetPage }), 0)
  }, [pageOptions]);

  const init = useCallback(async () => {
    if (element) {
      buildFormConfig(element.type);
      reset({ elementType: element.type, elementText: element.text, elementLink: element.link || '', targetPage: element.targetPage });
    }
  }, [element, buildFormConfig]);

  const onSave = (body: any) => {
    const newElement = { ...element, type: body.elementType, text: body.elementText };
    if (body.elementType === 'link') newElement.link = body.elementLink;
    if (body.elementType === 'innerLink') newElement.targetPage = body.targetPage;
    onUpdateElement(newElement);
  };

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    buildFormConfig(elementType);
  }, [elementType, buildFormConfig]);

  console.log('form Config', formConfig, element);

  return (
    <View style={commonStyles.basePaddingHorizontal}>
      <FormBuilder
        control={control}
        setFocus={setFocus}
        formConfigArray={formConfig}
        />
      <DefaultButton onPress={handleSubmit(onSave)} fullWidth>
        { Contents.common.btn.update }
      </DefaultButton>
    </View>
  )
}


export default ElementForm;
