import Contents from '../contents';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import timezones from 'timezones-list';
import * as Localization from 'expo-localization';
let timezoneList = timezones.map(function(item) {
  return {
    'value': item['tzCode'],
    'label': item['label']
  }
});
timezoneList = timezoneList.sort((a, b) => (a.label > b.label) ? 1 : -1)


const formConfig: Omit<LogicProps, 'control'>[] = [
  {
    type: 'text',
    name: 'firstName',
    textInputProps: {
      label: Contents.profileScreen.placeholders.firstName,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'lastName',
    textInputProps: {
      label: Contents.profileScreen.placeholders.lastName,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'phoneNumber',
    rules: {
      required: {
        value: true,
        message: Contents.profileScreen.validationErrors.phoneNumber,
      },
    },
    textInputProps: {
      label: Contents.profileScreen.placeholders.phoneNumber,
      autoComplete: false
    },
  },
  {
    type: 'email',
    name: 'email',

    rules: {
      required: {
        value: true,
        message: Contents.profileScreen.validationErrors.emailRequired,
      },
      pattern: {
        value:
          /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
          message: Contents.profileScreen.validationErrors.emailPattern
      },
    },
    textInputProps: {
      label: Contents.profileScreen.placeholders.email,
      autoComplete: false
    },
  },

  {
    type: 'autocomplete',
    name: 'timezone',
    defaultValue: Localization.timezone,
    textInputProps: {
      label: Contents.profileScreen.placeholders.timezone,
      autoComplete: false,
    },
    options: timezoneList,
  }
];
export default formConfig;
