import { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { useNavigation, useRoute, ParamListBase } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { Appbar } from 'react-native-paper';
import { inject, observer } from 'mobx-react';
import { Ionicons, MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';

import { GroupStoreType, ScreensParamList, RouteStackParamList, UserStoreType, PageRecordType } from 'types';

import { commonStyles } from 'styles';
import Color from 'utils/Styles/color';

type CustomHeaderProps = {
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

function CustomHeader(props: CustomHeaderProps) {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const route = useRoute();
  const { goBack, openDrawer } = navigation;

  const { store: {
    groupStore: { activeGroup },
    userStore: { dashboard }
  } } = props;

  const [routeType, setRouteType] = useState<boolean>(false);

  useEffect(() => {
    const routeName = route?.name;
    let type ='';
    if (routeName === 'dashboard' || routeName === 'profile') type = 'common';
    if (routeName === 'endUserStorePageList' || routeName === 'storePageList' || routeName === 'storePageView') type ='store';
    setRouteType(type);
  }, [route?.name]);

  return (
    <Appbar.Header
      theme={{ colors: { primary: Color.screenBackground } }}
      style={[styles.appbarHeader, {backgroundColor: '#fff'}]}>
      {
        route?.name === 'nativeWebView' ?
          <>
            <TouchableOpacity
              style={[commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter]}
              onPress={goBack}>
              <Ionicons name="chevron-back" size={24} color={Color.primaryGreyColor} />
              <Text style={commonStyles.whiteText}>Back</Text>
            </TouchableOpacity>
            <Text style={[styles.titleWrapper, commonStyles.smallHeadingFontSize]}>{ route?.params?.label || '' }</Text>
            <View></View>
          </>
        :
          <View style={[commonStyles.fullWidth, commonStyles.flex, commonStyles.spaceBetween, commonStyles.itemsCenter]}>
            <TouchableOpacity
              onPress={() => {
                openDrawer();
              }}>
              <MaterialCommunityIcons
                name='menu'
                size={32}
                color={Color.primaryGreyColor}
                style={{marginLeft:10}}
              />
            </TouchableOpacity>
            {
              routeType !== 'common' &&
              <Text style={[commonStyles.boldText, commonStyles.baseFontSize]}>{activeGroup?.legalName}</Text>
            }
            {
              (dashboard?.user_groups && dashboard?.user_groups.length > 1) &&
                <TouchableOpacity style={styles.rightButton} onPress={() => navigation.getParent('RightDrawer').openDrawer()}>
                  <FontAwesome5 name="store-alt" size={24} color={Color.primaryGreyColor} />
                </TouchableOpacity>
            }
          </View>
      }
    </Appbar.Header>
  );
}

const styles = StyleSheet.create({
  appbarHeader: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between'
  },
  leftButton: {
    lineHeight: 24,
    position: 'absolute',
    color: '#aaaaaa',
    opacity: 1,
    left: 10,
  },
  titleWrapper: {
    position: 'absolute',
    top: 15,
    left: 50,
    right: 50,
    textAlign: 'center'
  },
  logoImage: {
    width: 40,
    height: 40
  },
  rightButton: {
    marginRight: 10
  },

});


const CustomHeaderWithStore = inject('store')(observer(CustomHeader));

export default CustomHeaderWithStore;
