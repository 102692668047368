import React, { useEffect, useState } from 'react';
import { View, KeyboardAvoidingView, ScrollView, Platform } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import { Ionicons } from '@expo/vector-icons';
import DraggableFlatList, {
  RenderItemParams,
  OpacityDecorator,
} from 'react-native-draggable-flatlist';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import CheckBox from 'components/Checkbox';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';


import GroupTaskService from 'lib/Services/groupTask.service';
import { GroupStoreType, UserStoreType, ScreensParamList, RouteStackParamList, OrderEntry } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupTaskSetting'>;
  route: RouteProp<RouteStackParamList, 'groupTaskSetting'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

type Task = {
  id: string;
  name: string;
  active: boolean;
};

const groupTaskService = new GroupTaskService();

function GroupTaskSetting(props: Props) {
  const { route: { params: { groupId } } } = props;
  const { navigation: { goBack } } = props;
  const { store: { groupStore, userStore } } = props;
  const { activeGroup } = groupStore;
  const { session } = userStore;

  const [tasksList, setTasksList] = useState<any[]>([]);
  const [selectedTasksList, setSelectedTasksList] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();

  const updateSetting = async () => {
    try {
      setLoading(true);
      const groupTaskMap = await groupTaskService.getTasksInGroup(groupId, session);
      let updatedTaskMap = groupTaskMap;
      const groupTasks = convertGroupTasksFromResponse(groupTaskMap);

      // Activate, deactivate First
      const oldActiveGroupTasks = groupTasks.filter((groupTask: any) => groupTask.active !== false);
      const oldActiveGroupTasksIds = oldActiveGroupTasks.map((groupTask: any) => groupTask.id);

      const removingTasksIds = oldActiveGroupTasksIds.filter((groupTaskId: string) => selectedTasksList.indexOf(groupTaskId) === -1);
      const addingTasksIds = selectedTasksList.filter((groupTaskId: string) => oldActiveGroupTasksIds.indexOf(groupTaskId) === -1);

      if (removingTasksIds.length > 0) updatedTaskMap = await groupTaskService.deactivate(groupId, removingTasksIds, session);
      if (addingTasksIds.length > 0) updatedTaskMap = await groupTaskService.activate(groupId, addingTasksIds, session);

      // Update the order
      const orderEntries: OrderEntry[] = [];
      tasksList.forEach((task: Task, index: number) => {
        if (task.id !== groupTasks[index].id) {
          orderEntries.push({ id: task.id, order: index + 1 });
        }
      })
      if (orderEntries.length > 0) {
        updatedTaskMap = await groupTaskService.updateOrder(groupId, orderEntries, session);
      }

      setTasksList(convertGroupTasksFromResponse(updatedTaskMap));
      toast.show(Contents.group.toast.taskSettingUpdateSuccess);
    } catch(error) {
      console.log('error in updateSetting', error);
      toast.show(Contents.group.toast.taskSettingUpdateError, { type: 'error' })
    } finally {
      setLoading(false);
    }
  };

  const convertGroupTasksFromResponse = (groupTaskMap: any) => {
    return Object.keys(groupTaskMap)
      .map(key => ({ ...groupTaskMap[key], id: key, order: Number(groupTaskMap[key].order) || -1 }))
      .sort((a, b) => (a.order > b.order || a.order === -1) ? 1 : -1);
  }

  // - Get all tasks list
  // - Get tasks selected for group
  const init = async () => {
    setLoading(true);
    let groupTaskMap = await groupTaskService.getTasksInGroup(groupId, session);
    if (!groupTaskMap || Object.keys(groupTaskMap).length < 1) {
      groupTaskMap = await groupTaskService.initialize(groupId, session);
    }
    const groupTasks = convertGroupTasksFromResponse(groupTaskMap);

    const localSelectedTasksList: string[] = [];
    groupTasks.forEach((groupTask: any) => {
      if (groupTask.active !== false) localSelectedTasksList.push(groupTask.id);
    });

    setSelectedTasksList(localSelectedTasksList);
    setTasksList(groupTasks);
    setLoading(false);
  }

  const toggleSelectedTasksList = (task: Task) => {
    if (selectedTasksList.indexOf(task.id) !== -1)
      setSelectedTasksList(selectedTasksList.filter(t => t !== task.id));
    else
      setSelectedTasksList([...selectedTasksList, task.id]);
  }

  const renderItem = ({ item, drag, isActive }: RenderItemParams<Task>) => {
    return (
      <OpacityDecorator>
        <CheckBox
          wrapperStyle={{ marginVertical: 5, 'justifyContent': 'space-between' }} label={item.name}
          status={selectedTasksList.indexOf(item.id) === -1 ? 'unchecked' : 'checked'}
          suffixComponent={<Ionicons name='settings' size={24} color='black' />}
          disabled={isActive}
          onPress={() => toggleSelectedTasksList(item) }
          onLongPress={drag}
          />
      </OpacityDecorator>
    );
  }

  useEffect(() => {
    init();
  }, []);


  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle
          title={ Contents.group.header.taskSetting }

          />
        {
          loading ?
            <GenericViewSkeleton />
          :
            <>
              <ScrollView style={commonStyles.flatView} contentContainerStyle={ commonStyles.flexGrow }>
                <View style={commonStyles.container}>
                  <DraggableFlatList
                    data={tasksList}
                    onDragEnd={({ data }) => setTasksList(data)}
                    keyExtractor={(task) => task.id}
                    renderItem={renderItem}
                  />
                </View>
                <View style={commonStyles.buttonContainer}>
                  <DefaultButton onPress={updateSetting} fullWidth>
                    { Contents.common.btn.update }
                  </DefaultButton>
                </View>
              </ScrollView>
              <BackButton />
            </>
        }
      </KeyboardAvoidingView>
    </View>
  );
}

const GroupTaskSettingWithStore = withErrorBoundary(inject('store')(observer(GroupTaskSetting)));
export default GroupTaskSettingWithStore;
