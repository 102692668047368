import { useState, useEffect, useCallback } from 'react';
import { View, ScrollView, Text } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { RouteProp } from '@react-navigation/native';
import { FloatingAction } from 'react-native-floating-action';
import { inject, observer } from 'mobx-react';

import DashboardUser from './DashboardUser';
import DashboardManager from './DashboardManager';
import DashboardOwner from './DashboardOwner';
import DashboardInviteCard from 'components/DashboardInviteCard';
import DashboardSkeleton from 'components/Skeleton/DashboardSkeleton';

import { ScreensParamList, UserStoreType } from 'types';
import { commonStyles } from 'styles';
import { primary } from 'utils/Constants';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'dashboard'>;
  store: { userStore: UserStoreType };
};


const actions = [
  {
    text: 'Create Group',
    icon: require('../../../assets/writing.png'),
    name: 'create_group',
    position: 1
  }
];

const requiredFields = ['email', 'phoneNumber', 'timezone'];


const NO_DASHBOARD_DATA_VIEW = -1;
const USER_DASHBOARD_VIEW = 0;
const MANAGER_DASHBOARD_VIEW = 1;
const OWNER_DASHBOARD_VIEW = 2;

function Dashboard({
  navigation: { navigate },
  store: {
    userStore: { user, dashboard, fetchDashboard, fetchUser, session }
  }
}: Props) {
  const isFocused = useIsFocused();
  const [welcomeText, setWelcomeText] = useState<string>('');
  const [currentView, setCurrentView] = useState<number>(NO_DASHBOARD_DATA_VIEW);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(null);
  if (error) throw error;

  const isProfileReady = () => {
    const fields = requiredFields.filter(field => !user[field]);
    return (fields.length === 0);
  }

  const initDashboard = useCallback(async () => {
    if (user?.id) {
      if (isProfileReady() === false) {
        navigate('createProfile');
        return;
      }
      if (!user.emailVerified) {
        navigate('verificationForm', { type: 'email', value: user.email });
        return;
      }
      if (!user.phoneNumberVerified) {
        navigate('verificationForm', { type: 'phone', value: user.phoneNumber });
        return;
      }

      const isSilentLoading = user?.id === dashboard?.userId;
      if (!isSilentLoading) setLoading(true);

      try {
        await fetchUser(user.id, session);
      } catch(error) {
        console.log('inside initDashboard / fetchUser', error)
        setError(error);
        return;
      }
      if (user.firstName || user.lastName)
        setWelcomeText(`Welcome ${user.firstName || ''} ${user.lastName || ''}!`);
      else
        setWelcomeText(`Welcome ${user.name.join(' ')}!`);

      try {
        await fetchDashboard();
      } catch(error) {
        console.log('inside initDashboard/fetchDashboard', error)
        setError(error);
        return;
      }
    } else {
      setLoading(true);
      await fetchDashboard();
    }
    setLoading(false);
  }, [user]);

  const setupCurrentView = useCallback(() => {
    if (dashboard) {
      if (dashboard.owned_groups && dashboard.owned_groups.length > 0) {
        setCurrentView(OWNER_DASHBOARD_VIEW);
        return;
      }
      if ((dashboard.manager_groups && dashboard.manager_groups.length > 0) ||
        (dashboard.manager_stores && dashboard.manager_stores.length > 0)) {
        setCurrentView(MANAGER_DASHBOARD_VIEW);
        return;
      }
      if ((!dashboard.group_tasks || dashboard.group_tasks.length === 0) &&
          (!dashboard.user_groups || dashboard.user_groups.length === 0) &&
          (!dashboard.user_stores || dashboard.user_stores.length === 0) &&
          (!dashboard.owned_groups || dashboard.owned_groups.length === 0) &&
          (!dashboard.invited_list || dashboard.invited_list.length === 0) &&
          (!dashboard.user_stores || dashboard.user_stores.length === 0)) {
        setCurrentView(NO_DASHBOARD_DATA_VIEW);
        fetchDashboard();
      } else
        setCurrentView(USER_DASHBOARD_VIEW);
    } else {
      setCurrentView(NO_DASHBOARD_DATA_VIEW)
      fetchDashboard();
    }
  }, [JSON.stringify(dashboard)]);

  useEffect(() => {
    if (isFocused && dashboard) {
      const groupTasksCount = dashboard.group_tasks ? dashboard.group_tasks.length : 0;
      const ownedGroupsCount = dashboard.owned_groups ? dashboard.owned_groups.length : 0;
      const groupsCount = dashboard.groups ? dashboard.groups.length : 0;
      const storesCount = dashboard.stores ? dashboard.stores.length : 0;

      // Auto navigation in case only related with
      if ((groupsCount === 1) && (storesCount === 0) && dashboard.invited_list.length === 0) {
        const groupId = dashboard.groups[0].id;
        if (groupTasksCount === 1) {
          navigate('groupOnboarding', { groupId });
          return
        } else {
          if (ownedGroupsCount === 1) {
            navigate('groupOwnerView', { groupId });
            return;
          } else {
            navigate('endUserGroupPageList', { groupId });
          }
        }
      }
    }
    // decide which view to display
    if (isFocused) setupCurrentView();
  }, [isFocused, JSON.stringify(dashboard)]);

  useEffect(() => {
    if (isFocused) {
      initDashboard();
    }
  }, [isFocused])

  return (
    <View style={[commonStyles.viewport]}>
      <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
        <View style={commonStyles.container} testID='dashboard-container'>
          <View style={[commonStyles.headView, commonStyles.textCenter, commonStyles.noMarginTop]}>
            <Text style={[commonStyles.headText]}>
              Dashboard
            </Text>
          </View>
          {
            loading ?
              <DashboardSkeleton welcomeText={welcomeText} />
            :
              <>
                {
                  currentView === NO_DASHBOARD_DATA_VIEW &&
                    <Text style={[commonStyles.headSubText, commonStyles.whiteText]}>You have not been invited to any groups /stores.</Text>
                }
                {
                  dashboard && dashboard.invited_list && dashboard.invited_list.length > 0 ?
                    dashboard.invited_list.map((invited: any) => (
                      <DashboardInviteCard
                        key={invited.id}
                        group={invited.group}
                      />
                    ))
                  :
                    <></>
                }
                {
                  currentView === USER_DASHBOARD_VIEW &&
                    <DashboardUser />
                }
                {
                  currentView === MANAGER_DASHBOARD_VIEW &&
                    <DashboardManager />
                }
                {
                  currentView === OWNER_DASHBOARD_VIEW &&
                    <DashboardOwner />
                }
              </>
          }
        </View>
      </ScrollView>
      {
        user &&
        <FloatingAction
          actions={actions}
          onPressItem={() => navigate('ownershipGroup')}
          showBackground={false}
          color={primary}
        />
      }
    </View>
  );
}

const DashboardWithStore = withErrorBoundary(inject('store')(observer(Dashboard)));
export default DashboardWithStore;
