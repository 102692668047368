import { useEffect, useState, useCallback } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform, TouchableOpacity } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { Button } from 'react-native-paper';
import { AntDesign, FontAwesome5, Fontisto } from '@expo/vector-icons';
import { inject, observer } from 'mobx-react';
import AwesomeAlert from 'react-native-awesome-alerts';

import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';


import StoreService from 'lib/Services/store.service';
import BaseDataService from 'lib/Services/baseData.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, StoreUser, StoreStoreType, UserStoreType, User, ConfirmationDialogType } from 'types';

import { commonStyles, componentsDefaultStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeUsersList'>;
  route: RouteProp<RouteStackParamList, 'storeUsersList'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};

const storeService = new StoreService();
const storeDataService = new BaseDataService('store');

function StoreUsersList(props: Props) {
  const { route } = props;
  const { params: { groupId, storeId } } = route;

  const isFocused = useIsFocused();
  const { navigation: { navigate, goBack } } = props;

  const { store: { storeStore, userStore } } = props;
  const { activeStore, fetchStore, activeStoreUser, setActiveStoreUser, fetchStoreUsers, isCurrentUserManager, isCurrentUserOwner,
    activeStoreUsers, activeStoreManagers, activeStoreOwners } = storeStore;
  const { session } = userStore;

  const [storeUsersList, setStoreUsersList] = useState<StoreUser[]>([]);
  const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogType>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [activeUser, setActiveUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  // - Get all tasks list
  // - Get tasks selected for store
  const init = useCallback(async () => {
    // No permissions to browse users list and search for users
    if (!isCurrentUserManager && !isCurrentUserOwner) {
      toast.show(Contents.common.toast.insufficientPermission, { type: 'danger' });
      goBack();
      return;
    }

    setLoading(true)
    await fetchStoreUsers(storeId, session);
    setLoading(false);
  }, [storeId, session, fetchStoreUsers]);

  const onEditUserRole = (storeUser: StoreUser) => {
    setActiveStoreUser(storeUser);
    navigate('storeUserForm', { storeId });
  }

  const onEditManager = (storeUser: StoreUser) => {
    if (storeUser) {
      setActiveStoreUser(storeUser);
      navigate('storeUserForm', { storeId });
    }
  }

  /* User row action handlers, openining confirmation dialog */
  const onTriggerSetManager = (storeUser: StoreUser) => {
    setActiveStoreUser(storeUser);
    setConfirmationDialogType('setManager');
    setConfirmationDialogMessage(Contents.store.confirm.setAsManager);
  }

  const onTriggerRevokeManager = (user: User) => {
    setActiveUser(user);
    setConfirmationDialogType('revokeManager');
    setConfirmationDialogMessage(Contents.store.confirm.revokeManager);
  }

  const onTriggerDeleteUser = (storeUser: StoreUser) => {
    setActiveStoreUser(storeUser);
    setConfirmationDialogType('delete');
    setConfirmationDialogMessage(Contents.store.confirm.removeUser);
  }

  // Main User Action handler
  const onHandleDefaultUserActions = async() => {
    if (!activeStoreUser || !activeStore) return;
    setLoading(true);
    const managers = activeStoreManagers || [];
    const managerIds = managers.map((manager: StoreUser) => manager.id);
    switch(confirmationDialogType) {
      case 'delete':
        setConfirmationDialogType('');
        setActiveStoreUser(null);
        await storeService.removeStoreFromGroupUser(groupId, storeId, activeStoreUser.userId);
        break;
      case 'setManager':
        setConfirmationDialogType('');
        setActiveStoreUser(null);
        let newManagerIds = [...new Set([...managerIds, activeStoreUser.userId])];
        await storeDataService.update(activeStore.id, { managers: newManagerIds }, session);
        break;
    }
    await fetchStore(storeId, session);
    await fetchStoreUsers(storeId, session);
    setLoading(false)
  }

  const onRevokeManager = async () => {
    if (!activeUser || !activeStore) return;
    setLoading(true);
    setConfirmationDialogType('')
    const managers = activeStoreManagers || [];
    const managerIds = managers.map((manager: StoreUser) => manager.id);
    let updatedManagerIds = managerIds.filter((managerId: string) => managerId !== activeUser.id);
    await storeDataService.update(activeStore.id, { managers: updatedManagerIds }, session);
    await fetchStore(storeId, session);
    await fetchStoreUsers(storeId, session);
    setLoading(false);
  }


  useEffect(() => {
    const managerIds = activeStoreManagers.map((manager: StoreUser) => manager.userId);
    if (activeStoreUsers && activeStoreUsers.length > 0) {
      let list = activeStoreUsers
        .filter((storeUser: StoreUser) => managerIds.includes(storeUser.userId) === false)
      setStoreUsersList(list);
    }
  }, [activeStoreUsers, activeStoreManagers]);

  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);


  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle
          title={ Contents.store.header.usersList }
          subTitle={activeStore.name} />
        {
          loading ?
            <GenericViewSkeleton />
          :
            <>
              <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
                <View style={commonStyles.container}>
                  <View style={commonStyles.flexGrow}>
                    {
                      isCurrentUserOwner ?
                        <View style={commonStyles.baseMarginBottom}>
                          <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>{ Contents.store.text.usersList.owners }</Text>
                          {
                            (activeStoreOwners && activeStoreOwners.length > 0) ?
                              activeStoreOwners.map(storeUser => (
                                <View key={storeUser.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                                  <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ storeUser.user.name.join(' ') }</Text>
                                </View>
                              ))
                            :
                            <Text style={commonStyles.baseFontSize}>{ Contents.store.text.usersList.noOwnerExists }</Text>
                          }
                        </View>
                      :
                        <></>
                    }
                    {
                      (isCurrentUserOwner || isCurrentUserManager) ?
                        <View style={commonStyles.baseMarginBottom}>
                          <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>{ Contents.store.text.usersList.managers }</Text>
                          {
                            (activeStoreManagers && activeStoreManagers.length > 0) ?
                              activeStoreManagers.map(storeManager => (
                                <View key={storeManager.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                                  <TouchableOpacity onPress={() => onEditManager(storeManager)}>
                                    <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ storeManager.user.name.join(' ') }</Text>
                                  </TouchableOpacity>
                                  <View style={[commonStyles.flex, commonStyles.flexRow]}>
                                    <FontAwesome5 name="keycdn" size={24} color="black" style={commonStyles.smallMarginRight}
                                      onPress={() => onTriggerRevokeManager(storeManager)} />
                                  </View>
                                </View>
                              ))
                            :
                            <Text style={commonStyles.baseFontSize}>{ Contents.store.text.usersList.noManagerExists }</Text>
                          }
                        </View>
                      :
                        <></>
                    }
                    <View style={commonStyles.baseMarginBottom}>
                      <View style={commonStyles.baseMarginBottom}>
                        <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>{ Contents.store.text.usersList.users }</Text>
                        {
                          storeUsersList && storeUsersList.length > 0 ?
                            storeUsersList.map(storeUser => (
                              <View key={storeUser.id} style={[commonStyles.listItem, commonStyles.flex, commonStyles.spaceBetween]}>
                                <TouchableOpacity onPress={() => onEditUserRole(storeUser)}>
                                  <Text style={[commonStyles.primaryText, commonStyles.baseFontSize]}>{ storeUser.user.name.join(' ') }</Text>
                                </TouchableOpacity>
                                <View style={[commonStyles.flex, commonStyles.flexRow]}>
                                  <Fontisto name="key" size={24} color="black" style={commonStyles.smallMarginRight}
                                    onPress={() => onTriggerSetManager(storeUser)} />
                                  <AntDesign name='delete' size={24} color='black' onPress={() => onTriggerDeleteUser(storeUser)}/>
                                </View>
                              </View>
                            ))
                            :
                              <Text style={commonStyles.baseFontSize}>{ Contents.store.text.usersList.noUserExists }</Text>
                          }
                      </View>

                    </View>
                  </View>
                  <View style={commonStyles.buttonContainer}>
                    <DefaultButton onPress={() => navigate('storeLookupInviteUser', { groupId, storeId })} fullWidth>
                      { Contents.group.btn.addUser }
                    </DefaultButton>
                  </View>
                </View>
              </ScrollView>
              <BackButton />
            </>
        }
        <AwesomeAlert
          show={!!activeStoreUser && (confirmationDialogType === 'setManager' || confirmationDialogType === 'delete')}
          showProgress={false}
          title={ Contents.store.confirm.title }
          message={ confirmationDialogMessage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogType('');
            setActiveStoreUser(null);
          }}
          onConfirmPressed={onHandleDefaultUserActions}
        />
        <AwesomeAlert
          show={confirmationDialogType === 'revokeManager'}
          showProgress={false}
          title={ Contents.store.confirm.title }
          message={ confirmationDialogMessage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogType('');
            setActiveUser(null);
          }}
          onConfirmPressed={onRevokeManager}
        />
      </KeyboardAvoidingView>
    </View>
  );
}

export default withErrorBoundary(inject('store')(observer(StoreUsersList)));
