/* External Dependencies */
import useSWRNative from '@nandorojo/swr-react-native'
/* Helper, Misc Data */
import { SERVER_URL } from 'utils/Constants';
import { fetchData } from 'utils';

const apiUrl = `${SERVER_URL}/page`;

function useGroupPages(groupId: string) {
  let url = `${apiUrl}/group/${groupId}`;
  const { data: groupPages } = useSWRNative(url, fetchData, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });
  return { groupPages };
}

export default useGroupPages;
