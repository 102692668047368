import * as Constants from 'utils/Constants';
import {primary} from "../../Constants";
export const Button = {
    // borderColor: Constants.primary,
    // outlineColor: Constants.primary,
    // borderRadiusLeft: 50,
    // borderRadiusRight: 50,
    // width: 250,
    // backgroundColor: primary,
    fontSize: '18px',
    fontWeight: 'bold',
    paddingVertical: 5,
    paddingHorizontal: 30,
    marginLeft: 5,
    marginRight: 5,
    marginHorizontal: 'auto'
};

export const DangerButton = {
    borderColor: Constants.red,
    outlineColor: Constants.red,
    backgroundColor: Constants.red
}

export const ButtonLabel = {
    fontSize: 18,

};

export const DisabledButtonLabel = {
    color: Constants.placeholder
};

export const WhiteButtonLabel = {
    color: Constants.white
};
