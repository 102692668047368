import { StyleSheet, Dimensions, Platform } from 'react-native'
import {
  white,
  primary,
  MOBILE_MAX_VW,
  DESKTOP_CONTAINER_WIDTH,
  placeholder,
} from 'utils/Constants'
import Color from './color';
import flexStyles from './_flex'
import spacingStyles, { baseSpacingValue, largeSpacingValue } from './_spacing'
import typographyStyles from './_typography'
import markdownStyles from './_markdown'

const { width, height } = Dimensions.get('screen')

const commonStyles = StyleSheet.create({
  ...flexStyles,
  ...spacingStyles,
  ...typographyStyles,
  markdown: {
    ...markdownStyles
  },
  viewport: {
    width: '100%',
    alignItems: 'center',
    flex: 1,
    flexGrow: 1,
    backgroundColor: Color.screenBackground,
    position: 'relative',
    overflow: 'hidden',
  },
  containerVerticalCenter: {
    justifyContent: 'center',
    display: 'flex',
  },
  containerButtonRow: {
    alignSelf: 'center',
    flexDirection: 'row',
  },
  container: {
    flex: 1,
    flexGrow: 1,
    maxWidth:
      Platform.OS === 'web' && width > MOBILE_MAX_VW
        ? DESKTOP_CONTAINER_WIDTH
        : width,
    width: '100%',
    margin: '0 auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
  formContainer: {
    flex: 1,
    flexGrow: 1,
    width:
      Platform.OS === 'web' && width > MOBILE_MAX_VW
        ? DESKTOP_CONTAINER_WIDTH
        : width,
    justifyContent: 'space-start',
    alignSelf: 'center',
    paddingHorizontal: 30,
    marginVertical: 120,
  },
  buttonContainer: {
    marginTop: baseSpacingValue,
    marginHorizontal: largeSpacingValue
  },
  relative: {
    position: 'relative',
  },
  headView: {
    marginTop: 15,
    marginBottom: 15,

  },
  headViewWithNoMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  headViewWithSubView: {
    width:
        Platform.OS === 'web' && width > MOBILE_MAX_VW
            ? DESKTOP_CONTAINER_WIDTH
            : width,
    marginTop: 0,
    marginBottom: 10,
    textAlign: 'left',
  },
  headText: {
    fontSize: 40,
    paddingLeft: 10,
    lineHeight: 54,
    textAlign: 'left',
    fontWeight: '700',
  },
  headSubView: {
    marginBottom: 10,
  },
  flatView: {
    width: '100%',
    alignSelf: 'center'
  },
  headSubText: {
    fontSize: 20,

    textAlign: 'center',
    fontWeight: '700',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '15px' : 15,
  },
  subHeaderText: {
    fontSize: 16,
    marginTop: 15,
    marginBottom: 5,
  },
  formWrapper: {
    marginTop:10,

    padding: 35,
    paddingBottom:30,
    marginBottom: 20,
    backgroundColor: '#fff',
    borderRadius:8,
  },
  shadowedView: {
    shadowColor: 'black',
    shadowOpacity: 0.16,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 2,
    elevation: 3,
  },
  subTitle: {
    fontSize: 24,
    lineHeight: 35,
    fontWeight: '700',
    textAlign: 'center',
  },
  primaryText: {
    color: primary,
  },
  primaryBackground: {
    backgroundColor: primary,
  },
  primaryBorderColor: {
    borderStyle: 'solid',
    borderColor: primary,
  },
  backBtn: {
    width: 200,
    marginHorizontal: 'auto',
    color: white,
    marginVertical: 15
  },
  spinnerContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: -20,
    marginTop: -20,
    zIndex: 100,
  },
  buttonText: {
    color: primary,
    fontSize: 14,
    fontWeight: '500',
  },
  buttonDisabled: {
    backgroundColor: Color.brandLightGrey,
    color: Color.primaryGreyColor,

  },
  listItem: {
    borderBottomColor: placeholder,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingVertical: 10,
  },
  keyboardAvoidingView: {
    flex: 1,
    width: '100%',
  },
  fullWidth: {
    width: '100%'
  },
  blackBackground: {
    backgroundColor: '#333'
  },
  grayBackground: {
    backgroundColor: Color.primaryGreyColor
  },
  disabledBackground: {
    backgroundColor: Color.brandLightGrey
  },
  redBackground: {
    backgroundColor: Color.brandRed
  },
  whiteText: {
    color: 'white'
  },
  grayText: {
    color: 'rgba(10, 21, 81, 0.7)'
  },
  whiteBackground: {
    backgroundColor: white
  },
  normalBorderRadius: {
    borderRadius: 10
  },
  smallBorderRadius: {
    borderRadius: 4
  },
	otherView: {
		width: '100%',
		alignSelf: 'center',
		paddingVertical: 10
	},
	otherText: {
		fontSize: 14,
		fontWeight: '700',
		marginBottom: 20
	},
})
export default commonStyles
