import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import DropDown from 'react-native-paper-dropdown';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';

import { ScreensParamList, RouteStackParamList, UserStoreType, OptionType, Store, GroupStoreType } from 'types';
import BaseDataService from 'lib/Services/baseData.service';
import InvitedService from 'lib/Services/invited.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';
import { inviteNewUserFormConfig } from 'utils/FormConfig';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'inviteNewUser'>;
  route: RouteProp<RouteStackParamList, 'inviteNewUser'>;
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};

const invitedDataService = new BaseDataService('invited');
const invitedService = new InvitedService();;


function InviteUser(props: Props) {
  const { navigation: { goBack } } = props;
  const { route: { params: { groupId } } } = props;
  const { store: { userStore, groupStore } } = props;
  const { user, session } = userStore;
  const { activeGroup, groupStores } = groupStore;

  const {control, setFocus, handleSubmit, formState: { isDirty, isValid }} = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    mode: 'onChange',
  });
  const toast = useToast();
  const [showMultiSelectDropDown, setShowMultiSelectDropDown] = useState(false);
  const [storeDropdownValues, setStoreDropdownValues] = useState<OptionType<string>[]>([]);
  const [selectedStores, setSelectedStores] = useState <string>('');

  const inviteUser = async (body: any) => {
    const uid = user.id;
    const stores = selectedStores.trim().split(',').filter(storeId => storeId.length > 0);
    const result = await invitedDataService.create({ ...body, group: groupId, stores, invitedBy: uid, createdAt: new Date() }, session);
    if (result.status === 'success') {
      const res = await invitedService.sendInvitationMessage(session, uid, `You have been invited to ${activeGroup.legalName}, owned by PWT Holdings, LLC`);
      if (res.status === 'success') {
        toast.show(Contents.inviteNewUserScreen.toast.invitationSentSuccess + body.firstName, { type: 'success' });
        return;
      }
    }

    toast.show(result.message, { type: 'error' });
  };

  useEffect(() => {
    if (groupStores) {
      const options = groupStores.map((store: Store) => ({ label: store.name, value: store.id }));
      setStoreDropdownValues(options);
    }
  }, [groupStores]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >

        <HeadViewWithSubTitle
          title={ Contents.group.header.inviteUser }

          />
        <View style={[commonStyles.container]}>
          <View style={commonStyles.flexGrow}>
            <FormBuilder
              control={control}
              setFocus={setFocus}
              formConfigArray={inviteNewUserFormConfig}
            />
            <DropDown
              label={'Access to Stores'}
              mode={'outlined'}
              visible={showMultiSelectDropDown}
              showDropDown={() => setShowMultiSelectDropDown(true)}
              onDismiss={() => setShowMultiSelectDropDown(false)}
              value={selectedStores}
              setValue={setSelectedStores}
              list={storeDropdownValues}
              multiSelect
            />
          </View>
          <View style={commonStyles.buttonContainer}>
            <DefaultButton disabled={!isDirty || !isValid} fullWidth
              onPress={handleSubmit(inviteUser)}>
              { Contents.inviteNewUserScreen.btn.save }
            </DefaultButton>
          </View>
        </View>
        <BackButton />
      </KeyboardAvoidingView>
    </View>
  );
}

const InviteUserWithStore = withErrorBoundary(inject('store')(observer(InviteUser)));

export default InviteUserWithStore;
