import {StyleSheet, Platform} from 'react-native';
import { height} from 'utils/Constants';

export default StyleSheet.create({
    drawerContent: {
        flex: 1,
        borderWidth: 0,
        // width: 500,
        backgroundColor: '#000',
        height: Platform.OS === 'web' ? '100vh' : height
    },
    drawerContentRight: {
        flex: 1,
        borderWidth: 0,
        right: 0,
        backgroundColor: '#000',
        height: Platform.OS === 'web' ? '100vh' : height,
    },
    drawerSection: {
        marginVertical: 15,
        borderWidth: 0
    },
    drawerItem: {
        marginLeft: 0,
        marginRight: 0,
        borderRadius: 0,
        paddingLeft: '20px'
    },
    drawerLabel: {
        color: '#fff',
        fontSize: 24,
        fontWeight:'bold'
    },
    groupsLabel: {
        color: '#777',
        fontSize: 18,
        fontWeight:'normal'
    }
});
