import { useState, useCallback } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import UserViewPagesLisLayout from 'components/Skeleton/UserViewPagesLisLayout';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import BackButton from 'components/Button/BackButton';

import { GroupStoreType, ScreensParamList, RouteStackParamList, UserStoreType, PageRecordType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useEffect } from 'react';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupPageList'>;
  route: RouteProp<RouteStackParamList, 'groupPageList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};


function GroupPageList(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { groupId } } } = props;
  const { store: { groupStore, userStore } } = props;
  const { activeGroup, groupPages, setActivePage, fetchGroupPages } = groupStore;
  const { session } = userStore;
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState<boolean>(false);


  const onOpenGroupView = (page: PageRecordType) => {
    setActivePage(page);
    navigate('groupPageView', { groupId });
  }

  const init = useCallback(async () => {
    setLoading(true);
    await fetchGroupPages(session);
    setLoading(false);
  }, [groupId, session]);

  useEffect(() => {
    if (isFocused) {
      init();
    }
  }, [isFocused, init])

  return (
    <View style={[commonStyles.viewport]} testID='end-user-group-page-list'>
      {
        loading ? <UserViewPagesLisLayout heading={ Contents.group.header.groupPageList } />
        :
        <>
          <HeadViewWithSubTitle title={ Contents.group.header.groupPageList } />
          <ScrollView style={commonStyles.flatView} contentContainerStyle={ commonStyles.flexGrow }>
            <View style={commonStyles.container}>
              {
                groupPages.map((page: PageRecordType) => (
                  <TouchableOpacity key={page.id} onPress={() => onOpenGroupView(page)}>
                    <Text style={[commonStyles.smallMarginVertical, commonStyles.primaryText]}>{ page.title }</Text>
                  </TouchableOpacity>
                ))
              }
            </View>
          </ScrollView>
          <BackButton />
        </>
      }
    </View>
  );
}

const GroupPageListWithStore = withErrorBoundary(inject('store')(observer(GroupPageList)));

export default GroupPageListWithStore;
