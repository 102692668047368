/* External Dependencies */
import useSWRNative from '@nandorojo/swr-react-native'
/* Helper, Misc Data */
import { SERVER_URL } from 'utils/Constants';
import { fetchData } from 'utils';

const apiUrl = `${SERVER_URL}/store`;

function useStoreDetail(storeId: string, session: string) {
  const url = `${apiUrl}/${storeId}/detail/${session}`;
  const { data: storeDetail } = useSWRNative(storeId ? url : null, fetchData, {
    revalidateOnFocus: false,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });
  return { storeDetail };
}

export default useStoreDetail;