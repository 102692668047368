import React, { useState, useEffect } from 'react';
import { SafeAreaView, View } from 'react-native';
import { useRoute, useNavigationState } from '@react-navigation/native'
import { DrawerItem, DrawerContentScrollView } from '@react-navigation/drawer';
import { Drawer, Divider, Button } from 'react-native-paper';
import { inject, observer } from 'mobx-react';
import { Feather, Ionicons } from '@expo/vector-icons';

import { ElementRecordType } from 'types';

import styles from './style';
import { Colors } from 'styles';

import Contents from '../Utils/contents';

const menus = [
  { label: 'Profile', path: 'profile' }
];

function LeftDrawerContent(props: any) {
  const { navigation, route } = props;
  const { store: {
    userStore: { logout, user },
    groupStore: { activeGroup, activeGroupPageMap, setActivePageById, activePage, isCurrentUserManager, isCurrentUserOwner }
  } } = props;
  const routes = useNavigationState(state => state.routes)

  const [routeType, setRouteType] = useState<string>('');
  const [routeSlug, setRouteSlug] = useState<string>('');

  useEffect(() => {
    let histories = routes[0]?.state?.history;
    let newRouteType = '', newRouteSlug = '';
    if (histories && histories.length > 0) {
      histories = histories.filter(history => history.type === 'route');
      const routeKey = histories[histories.length - 1].key;
      if (routeKey) {
        if (routeKey.indexOf('groupPageView') !== -1) {
          newRouteType = 'groupPage';
          newRouteSlug = activePage?.id;
        } else if (routeKey.indexOf('storePage') !== -1) {
          newRouteType = 'storePage';
          newRouteSlug = routeKey;
        } else {
          newRouteSlug = routeKey.split('-')[0]
        }
      }
    }
    setRouteSlug(newRouteSlug);
    setRouteType(newRouteType);
  }, [routes]);


  const onLogout = async() => {
    navigation.closeDrawer();
    await logout();
    navigation.navigate('welcome');
  }

  const onNavigateInnerPage = (itemData: ElementRecordType) => {
    setActivePageById(itemData.targetPage);
    navigation.navigate('groupPageView');
  }

  const onOpenManagerGroup = () => {
    if (activeGroup && activeGroup.id)
      if (isCurrentUserManager)
        navigation.navigate('groupManagerView', { groupId: activeGroup.id, asGroup: true });
      if (isCurrentUserOwner)
        navigation.navigate('groupOwnerView', { groupId: activeGroup.id });
  }


  return (
    <DrawerContentScrollView
      contentContainerStyle={[
        styles.drawerContent,
      ]}
      {...props}>
      <SafeAreaView>
        <View
          style={[
            styles.drawerContent,
              ]}
        >
          <View style={{flexDirection:'row',justifyContent:'space-between',marginTop:'2px',marginBottom:'3px'}}>
            <Button mode='link' style={{paddingLeft: 0}}><Feather name="x" size={25} style={{color: Colors.screenBackground}} onPress={navigation.closeDrawer} /></Button>
            <Button mode='link' style={{paddingRight: 0}}><Ionicons name="ios-person-circle-outline" size={25}  onPress={() => {
              navigation.navigate('profile');
            }} />
            </Button>
          </View>
          <Divider style={{backgroundColor: '#333'}} />
          {
            user &&
            <Drawer.Section style={styles.drawerSection}>
              <DrawerItem
                activeBackgroundColor={'#151515'}
                label="D"
                focused={routeSlug == 'dashboard'}
                onPress={() => navigation.navigate('dashboard')}
                labelStyle={styles.drawerLabel}
                style={ styles.drawerItem }
              />

              {
                user.emailVerified && activeGroupPageMap && activeGroupPageMap.length > 0 &&
                  <>

                  { activeGroupPageMap.map((pageItem: ElementRecordType) => (
                    <DrawerItem
                      activeBackgroundColor={'#151515'}
                      key={pageItem.slug}
                      label={pageItem.text}
                      focused={routeSlug == pageItem.targetPage}
                      onPress={() => onNavigateInnerPage(pageItem)}
                      labelStyle={styles.drawerLabel}
                      style={ styles.drawerItem }
                    />
                  ))}

                  </>
              }

              {
                (isCurrentUserManager || isCurrentUserOwner) && 
                  <DrawerItem
                    activeBackgroundColor={'#151515'}
                    label="Manage Group"
                    onPress={ onOpenManagerGroup }
                    labelStyle={styles.drawerLabel}
                    style={ styles.drawerItem }
                  />
              }


              {
                user.emailVerified &&
                menus.map((item) => (
                  <DrawerItem
                    key={item.label}
                    label={item.label}
                    activeBackgroundColor={'#151515'}
                    onPress={() => {
                      navigation.navigate(item.path);
                    }}
                    focused={routeSlug == 'profile'}
                    labelStyle={styles.drawerLabel}
                    style={ styles.drawerItem }
                  />
                ))
              }
              <DrawerItem
                label={Contents.dashboardScreen.leftSidebar.signOut}
                onPress={onLogout}

                labelStyle={styles.drawerLabel}
                style={ styles.drawerItem }
              />
            </Drawer.Section>
          }
        </View>
      </SafeAreaView>
    </DrawerContentScrollView>);

}

const LeftDrawerContentWithStore = inject('store')(observer(LeftDrawerContent));

export default LeftDrawerContentWithStore;
