import { useEffect } from 'react';
import {
  Platform,
  View,
  ScrollView,
  KeyboardAvoidingView
} from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';

import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';

import { ScreensParamList, BodyType, UserStoreType, GroupStoreType } from 'types';
import BaseDataService from 'lib/Services/baseData.service';
import useStoreDetail from 'lib/Hooks/useStoreDetail';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';
import { storeFormConfig } from 'utils/FormConfig';



const storeDataService = new BaseDataService('store');

type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeForm'>;
  route: RouteProp<ScreensParamList, 'storeForm'>;
  store: { userStore: UserStoreType, storeStore: StoreStoreType };
};

const defaultValues: BodyType = {
  name: '',
  city: '',
  state: '',
  timezone: '',
  zipcode: ''
}

function StoreForm(props: Props) {
  const {
    navigation: { goBack },
    route: { params: { groupId, storeId } },
    store: { userStore, storeStore }
  } = props;
  const {control, setFocus, handleSubmit, reset, formState: { isValid }} = useForm({
    defaultValues,
    mode: 'onChange',
  });
  const toast = useToast();
  const isFocused = useIsFocused();

  const { user, session } = userStore;
  const { activeStore } = storeStore;

  const { storeDetail } = useStoreDetail(storeId, session);

  const next = async (body: BodyType) => {
    const userId = user.id;
    if (!userId) return;
    let result;

    if (storeId) {
      result = await storeDataService.update(storeId, {...body, group: groupId, owners: [userId]}, session);

      if (result && result.updated) {
        toast.show(Contents.store.toast.storeUpdateSuccess, { type: 'success' });
      }
    } else {
      result = await storeDataService.create({...body, group: groupId, owners: [userId]}, session);
      if (result.status === 'success') {
        toast.show(Contents.store.toast.storeCreateSuccess, { type: 'success' });
      }
    }
  };

  useEffect(() => {
    if (isFocused) reset(storeDetail || defaultValues);
  }, [isFocused, storeDetail]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
          <View style={commonStyles.container}>

            <HeadViewWithSubTitle
              title={ storeId ? Contents.store.header.editStore : Contents.store.header.addStore }
              subTitle={ storeId ? activeStore.name : '' } />
            {
              (storeId && !storeDetail) ?
                <GenericViewSkeleton />
              :
              <>
                <View style={[commonStyles.basePaddingVertical, commonStyles.smallMarginBottom, commonStyles.flexGrow]}>
                  <FormBuilder
                    control={control}
                    setFocus={setFocus}
                    formConfigArray={storeFormConfig}
                  />
                </View>
                <DefaultButton mode="contained" disabled={!isValid} onPress={handleSubmit(next)} fullWidth>
                  { Contents.store.btn.save }
                </DefaultButton>
                <BackButton />

              </>
            }
          </View>
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

const StoreFormWithStore = withErrorBoundary(inject('store')(observer(StoreForm)));
export default StoreFormWithStore;
