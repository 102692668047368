import { SafeAreaView, View } from 'react-native';
import { DrawerItem, DrawerContentScrollView } from '@react-navigation/drawer';
import {Button, Divider, Drawer, Text, Headline} from 'react-native-paper';
import { inject } from 'mobx-react';

import styles from './style';
import {Feather} from "@expo/vector-icons";
import React from "react";

function RightDrawerContent(props: any) {
  const { navigation } = props;
  const { store: {
    userStore: { dashboard, user, session },
    groupStore: { fetchRole, activeGroup }
  } } = props;

  const onOpenNormalGroup = (userGroup: any) => {
    if (userGroup?.roleId) fetchRole(userGroup?.roleId, session);
    navigation.closeDrawer();
    navigation.navigate('endUserGroupPageList', { groupId: userGroup.groupId });
  }

  if (!session) return null;

  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={[
        styles.drawerContent,
      ]}>
      <SafeAreaView>
        <View
          style={[
            styles.drawerContentRight,
          ]}
        >
            <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <Button mode='link' style={{paddingLeft: 0}}><Feather name="x" size={25}  onPress={navigation.closeDrawer} /></Button>

            </View>
          {
            (user && user.emailVerified && dashboard?.user_groups) &&
            <Drawer.Section style={[styles.drawerSection, {marginTop: 0}]}>

                <Divider style={{backgroundColor: '#333', marginTop:0}} />
                <DrawerItem
                    key='groupsTitle'
                    label='Groups'
                    labelStyle={styles.groupsLabel}
                />
              {
                dashboard?.user_groups.map((item) => (
                  <DrawerItem
                    key={item.id}
                    label={item.group.legalName}
                    onPress={() => onOpenNormalGroup(item)}
                    activeBackgroundColor={'#151515'}
                    focused={item.groupId && activeGroup?.id === item.groupId}
                    labelStyle={styles.drawerLabel}
                    style={ styles.drawerItem }
                  />
                ))
              }
            </Drawer.Section>
          }
        </View>
      </SafeAreaView>
    </DrawerContentScrollView>);

}

const RightDrawerContentWithStore = inject('store')(RightDrawerContent);
export default RightDrawerContentWithStore
