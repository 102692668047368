import { useState, useEffect, useCallback } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import UserViewPagesLisLayout from 'components/Skeleton/UserViewPagesLisLayout';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import BackButton from 'components/Button/BackButton';


import { GroupStoreType, ScreensParamList, RouteStackParamList, UserStoreType, PageRecordType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';
import { SectionRecordType, ElementRecordType } from '../../../lib/Types';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'endUserGroupPageList'>;
  route: RouteProp<RouteStackParamList, 'endUserGroupPageList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};


function EndUserGroupPageList(props: Props) {
  const { navigation: { navigate } } = props;
  const { route: { params: { groupId } } } = props;
  const { store: { groupStore, userStore } } = props;
  const { activeGroup, setActivePage, pages, fetchGroup, fetchGroupPages, groupPages, setPageMap } = groupStore;
  const { session } = userStore;
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState<boolean>(false);


  const onOpenGroupPageView = (page: PageRecordType) => {
    setActivePage(page);
    navigate('groupPageView', { groupId });
  }

  const init = useCallback(async () => {
    setLoading(true)
    if (activeGroup?.id !== groupId) await fetchGroup(groupId, session);
    if (activeGroup?.id) {
      await fetchGroupPages(session);
    }
    setLoading(false);
  }, [activeGroup?.id, groupId, session]);

  const buildGroupPageMap = ((page: PageRecordType) => {
    let elements: ElementRecordType[] = [];
    page.section.forEach((section: SectionRecordType) => elements = [...elements, ...(section.elements || [])]);
    elements = elements.filter((element: ElementRecordType) => element.type === 'innerLink')
      .sort((a: ElementRecordType, b: ElementRecordType) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1 );
    setPageMap(elements)
  });

  useEffect(() => {
    if (activeGroup.defaultPage && groupPages && isFocused && activeGroup?.id === groupId) {
      const page = groupPages.find((page: PageRecordType) => page.id === activeGroup.defaultPage);
      if (page) {
        setActivePage(page);
        buildGroupPageMap(page);
        navigate('groupPageView', { groupId });
      }
    }
  }, [activeGroup?.id, groupId, activeGroup?.defaultPage, groupPages, isFocused]);

  useEffect(() => {
    isFocused && init();
  }, [isFocused, init])


  return (
    <View style={[commonStyles.viewport]}>
      {
        loading ? <UserViewPagesLisLayout heading={ Contents.group.header.endUserGroupPageList } />
        :
        <>
          <HeadViewWithSubTitle title={ Contents.group.header.endUserGroupPageList } />
          <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
            <View style={commonStyles.container}>
              {
                (pages || groupPages || []).map((page: PageRecordType) => (
                  <TouchableOpacity key={page.id} onPress={() => onOpenGroupPageView(page)}>
                    <Text style={[commonStyles.smallMarginVertical, commonStyles.primaryText]}>{ page.title }</Text>
                  </TouchableOpacity>
                ))
              }
            </View>
          </ScrollView>
          <BackButton />
        </>
      }
    </View>
  );
}

const EndUserGroupPageListWithStore = withErrorBoundary(inject('store')(observer(EndUserGroupPageList)));
export default EndUserGroupPageListWithStore;
