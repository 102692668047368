import { View, Text, ScrollView } from 'react-native';
import type { NavigationProp } from '@react-navigation/native';

import StoreLocationComponent from 'components/StoreLocationComponent';

import { StoreLocation } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';
import styles from './styles';

type StoreLocationPropType = {
  title: string;
  location: StoreLocation
}

const stores: StoreLocationPropType[] = [
  {
    title: 'Leesburg',
    location: {
      address: '1008 Edwards Ferry Rd Ste 135',
      city: 'Leesburg',
      state: 'VA',
      zipcode: '20176',
      phone: '(571) 440-7006',
      email: 'va.leesburg@crumbl.com',
      openedDate: 'May 2021'
    }
  },
  {
    title: 'Reston',
    location: {
      address: '11686 Plaza America Dr',
      city: 'Reston',
      state: 'VA',
      zipcode: '20190',
      phone: '(571) 346-2322',
      email: 'va.reston@crumbl.com',
      openedDate: 'April 2022'
    }
  },
  {
    title: 'Fairfax',
    location: {
      address: '13075 Fair Lakes Shopping Center',
      city: 'Fairfax',
      state: 'VA',
      zipcode: '20190',
      phone: '(571) 307-6221',
      email: 'va.fairfax@crumbl.com',
      openedDate: 'Aug 2022'
    }
  },
];

function LocationsPage() {

  return (
    <View style={[commonStyles.viewport]}>
      <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
        <View style={commonStyles.container}>
            <View style={commonStyles.smallMarginBottom}>
              <Text style={[commonStyles.subLargeFontSize, commonStyles.boldText]}>
                { Contents.staticPageScreen.header.locationsPage }
              </Text>
              <Text style={[commonStyles.baseFontSize, commonStyles.smallMarginBottom]}>
                { Contents.staticPageScreen.text.locationsPage }
              </Text>
            </View>
            {
              stores.map((store, i) => (
                <View key={i}>
                  <StoreLocationComponent title={store.title} location={store.location} />
                  { i !== (stores.length - 1) ? <View style={styles.storeSeperator}></View> : <></> }
                </View>
              ))
            }
        </View>
      </ScrollView>
    </View>
  );
}

export default withErrorBoundary(LocationsPage);
