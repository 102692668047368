import Contents from '../contents';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import timezones from 'timezones-list';
import * as Localization from 'expo-localization';
let timezoneList = timezones.map(function(item) {
  return {
    'value': item['tzCode'],
    'label': item['label']
  }
});
timezoneList = timezoneList.sort((a, b) => (a.label > b.label) ? 1 : -1)

const names: Omit<LogicProps, 'control'>[] = [
  {
    type: 'text',
    name: 'firstName',
    textInputProps: {
      label: Contents.createProfileScreen.placeholders.firstName,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'lastName',
    textInputProps: {
      label: Contents.createProfileScreen.placeholders.lastName,
      autoComplete: false
    },
  },
];

const phoneNumber: Omit<LogicProps, 'control'> = {
  type: 'text',
  name: 'phoneNumber',

  rules: {
    required: {
      value: true,
      message: Contents.createProfileScreen.validationErrors.phoneNumber,
    },
  },
  textInputProps: {
    label: Contents.createProfileScreen.placeholders.phoneNumber,
    disabled: true,
    autoComplete: false
  },
};

const email: Omit<LogicProps, 'control'> = {
  type: 'email',
  name: 'email',

  rules: {
    required: {
      value: true,
      message: Contents.createProfileScreen.validationErrors.emailRequired,
    },
    pattern: {
      value:
        /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
        message: Contents.createProfileScreen.validationErrors.emailPattern
    },
  },
  textInputProps: {
    label: Contents.createProfileScreen.placeholders.email,
    disabled: true,
    autoComplete: false,
  },
};



const timezone: Omit<LogicProps, 'control'> = {
  type: 'select',
  name: 'timezone',
  defaultValue: Localization.timezone,
  textInputProps: {
    label: Contents.createProfileScreen.placeholders.timezone,
    autoComplete: false,
  },
  options: timezoneList,
};


export default { names, phoneNumber, email, timezone};
