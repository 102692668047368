import { View, ScrollView } from 'react-native';
import type { NavigationProp } from '@react-navigation/native';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';

import { ColumnItemType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import SectionLayout from 'components/ItemLayout/SectionLayout';

const blocksData: ColumnItemType[] = [
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.crewToolsScreen.button.timesheetCorrection,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/28', linkType: 'internal' },
      {
        type: 'link', text: Contents.crewToolsScreen.button.incidentReport,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/33', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.crewToolsScreen.button.desiredHoursRequest,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/31', linkType: 'internal' },
      {
        type: 'link', text: Contents.crewToolsScreen.button.reimbursementRequest,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/32', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      {
        type: 'link', text: Contents.crewToolsScreen.button.taxExemptionForm,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/30', linkType: 'internal' },
      {
        type: 'link', text: Contents.crewToolsScreen.button.companyFeedbackForm,
        link: 'https://www.jotform.com/app/cookiecrew/crewapp/29', linkType: 'internal' }
    ]
  },
  {
    layoutType: 'half',
    items: {
      type: 'link', text: Contents.crewToolsScreen.button.cashTipReport,
      link: 'https://www.jotform.com/app/cookiecrew/crewapp/55', linkType: 'internal'
    }
  },
];

const crewToolsSectionData = {
  title: Contents.crewToolsScreen.text.crewTools,
  description: Contents.crewToolsScreen.text.crewToolsHelp,
  blocksData
}


function CrewTools() {
  return (
    <View style={[commonStyles.viewport]}>
      <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
        <View style={commonStyles.container}>
          <SectionLayout sectionData={crewToolsSectionData} />
        </View>
      </ScrollView>
    </View>
  );
}

export default withErrorBoundary(CrewTools);
