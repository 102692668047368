import { View, ActivityIndicator } from 'react-native';
import { primary } from 'utils/Constants';

export default () => {
  return (
    <View
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        flex: 1,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(200, 200, 200, 0.6)'
      }}
    >
      <ActivityIndicator size='large' color={primary} />
    </View>
  );
};
