export default {
  header: {
    ownerStoreView: 'Manage',
    pageSetting: 'Store Page Setting',
    usersList: 'Store Users List',
    rolesList: 'Store Roles List',
    rolesForm: 'Store Roles Form',
    pagesList: 'Store Pages List',
    endUserStorePagesList: 'Store Pages List',
    pagesForm: 'Store Pages Form',
    sectionForm: 'Store Section Form',
    elementForm: 'Store Element Form',
    onboarding: 'Store Onboarding',
    lookupInviteUser: 'Lookup To Invite',
    editStoreUserRole: 'Edit User',
    addStore: 'Create Store',
    editStore: 'Edit Store',
  },
  text: {
    usersList: {
      managers: 'Managers',
      owners: 'Owners',
      users: 'Users',
      noOwnerExists: 'There is no owner in store yet.',
      noManagerExists: 'There is no manager in store yet.',
      noUserExists: 'There is no user in store yet.'
    },
    lookupInvite: {
      alreadyStoreMember: 'The user is already a store member.',
      notGroupMember: 'The user is not a group member.',
    }
  },
  placeholders: {
    name: 'Name',
    state: 'State',
    city: 'City',
    timezone: 'Timezone',
    zipcode: 'Zipcode',
    pageName: 'Page Name',
    sectionTitle: 'Section Title',
    roleName: 'Role Name',
    targetPage: 'Target Page',
    storeId: 'Store ID',
    storeSlug: 'Store Slug',
    storeEmail: 'Store Email',
  },
  btn: {
    save: 'Save',
    back: 'Back',
    edit: 'Edit Store',
    addRole: 'Add Role',
    addPage: 'Add Page',
    addSection: 'Add Section',
    addElement: 'Add Element',
    pageSetting: 'Page Setting',
    manageUsers: 'Manage Users',
    revokeManager: 'Revoke Manager Role',
    setAsManager: 'Set As Manager',
    roleManagement: 'Role Management',
    inviteToStore: 'Invite To Store'
  },
  validationErrors: {
    nameRequired: 'Name is required'
  },
  confirm: {
    title: 'Confirmation',
    removePage: 'Are you sure to remove this page?',
    removeSection: 'Are you sure to remove this section?',
    removeElement: 'Are you sure to remove this element?',
    removeRole: 'Are you sure to remove this role?',
    removeUser: 'Are you sure to remove this user from store?',
    setAsManager: 'Are you sure to make this user as Manager?',
    revokeManager: 'Are you sure to revoke Manager role from this user?',
  },
  toast: {
    storeCreateSuccess: 'A new store has been created successfully.',
    storeUpdateSuccess: 'The store has been updated successfully.',
    roleUpdateSuccess: 'The role has been updated successfully',
    roleCreateSuccess: 'The role has been created successfully',
    roleDeleteSuccess: 'The role has been deleted successfully',
    pageUpdateSuccess: 'The page has been updated successfully',
    pageCreateSuccess: 'The page has been created successfully',
    pageDeleteSuccess: 'The page has been deleted successfully',
    sectionUpdateSuccess: 'The section has been updated successfully',
    sectionCreateSuccess: 'The section has been created successfully',
    sectionDeleteSuccess: 'The section has been deleted successfully',
    elementUpdateSuccess: 'The element has been updated successfully',
    elementCreateSuccess: 'The element has been created successfully',
    elementDeleteSuccess: 'The element has been deleted successfully',
    taskSettingUpdateSuccess: 'Store onboarding task settings has been successfully udpated.',
    taskSettingUpdateError: 'An error occured while updating group onboarding task settings.',
    onboardingLoadError: 'An error occured while loading group onboarding data.',
    userUpdateSuccess: 'User has been updated successfully',
    apiError: 'There seems to be a problem with the server, please try again later or contact administrator.',
    appendToStoreSuccess: 'User has been granted access to the store.',
  }
}
