import axios from 'axios';
import {SERVER_URL} from 'utils/Constants';

const apiUrl = `${SERVER_URL}/auth`;

class AuthService {

    sendAuthToken = async (type: string, identifier: string) => {
        try {
            const res = await axios.get(`${apiUrl}/${type}/${identifier}`);
            return res.data;
        } catch (error) {
            console.log(`error in authService / sendAuthToken method`, error);
            return null;
        }
    }
    checkVerificationCode = async (type: string, identifier: string, token: string) => {
        try {
            const res = await axios.get(`${apiUrl}/${type}/${identifier}/${token}`);
            return res.data;
        } catch (error) {
            console.log(`error in authService / verifyAuthToken method`, error);
            return null;
        }
    }
    getSessionDetail = async (sessionToken:string) => {
        try {
            const res = await axios.get(`${apiUrl}/session?token=${sessionToken}`);
            return res.data;
        } catch (error) {
            console.log(`error in authService / getSessionDetail method`, error);
            return null;
        }
    }
    logout = async () => {
        try {
            const res = await axios.get(`${apiUrl}/logout`);
            return res.data;
        } catch (error) {
            console.log(`error in authService / logout method`, error);
            return null;
        }
    }
    changeAuthDetail = async(authMethod: string, value: string) => {
        try {
            const res = await axios.get(`${apiUrl}/change/${authMethod}/${value}`);
            return res.data;
        } catch (error) {
            console.log(`error in authService / getSessionDetail method`, error);
            return null;
        }        
    }
}

export default AuthService;
