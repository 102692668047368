const inviteNewUserScreen = {
  txt: {
    header: 'Invite User'
  },
  placeholders: {
    phone: 'Mobile Phone',
    email: 'Email Address',
    password: 'Password',
    confirm: 'Conformation Code'
  },
  btn: {
    save: 'Save'
  },
  toast: {
    invitationSentSuccess: 'An invitation has been successfully sent to '
  }
};

export default inviteNewUserScreen;