import { useEffect, useState, useCallback } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform, TouchableOpacity } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { TextInput, Button } from 'react-native-paper';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { default as slugify } from 'slugify';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';

import Loading from 'components/Loading';

import PageService from 'lib/Services/page.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, PageRecordType, SectionRecordType, StoreStoreType, UserStoreType } from 'types';
import { commonStyles } from 'styles';
import Contents from 'utils/contents';


const pageService = new PageService();


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storePageForm'>;
  route: RouteProp<RouteStackParamList, 'storePageForm'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};

function StorePageForm(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { storeId, pageId } } } = props;
  const { store: { userStore, storeStore } } = props;
  const { activeStore, pages, setActivePage, activePage } = storeStore;
  const { session } = userStore;

  const [loading, setLoading] = useState<boolean>(false);
  const [pageName, setPageName] = useState<string>('');
  const [sectionList, setSectionList] = useState<SectionRecordType[]>([]);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [sectionTitle, setSectionTitle] = useState<string>('');
  const [activeSection, setActiveSection] = useState<SectionRecordType | null>(null);
  const [confirmToRemoveSection, setConfirmToRemoveSection] = useState<boolean>(false);
  const [confirmToRemovePage, setConfirmToRemovePage] = useState<boolean>(false);

  const toast = useToast();

  console.log('store page form', props);

  const init = useCallback(async () => {
    if (pageId && pages) {
      const activePage = pages.find((page: PageRecordType) => page.id === pageId);
      if (activePage) setActivePage(activePage);
    }
  }, [pages, pageId]);

  const onCreatePage = async() => {
    const data = {
      title: pageName,
      section: sectionList
    };
    try {
      const res = await pageService.createPage('store', storeId, data, session);
      if (res) {
        toast.show(Contents.store.toast.pageCreateSuccess, { type: 'success' });
        navigate('storePageSetting', { storeId });
      } else {
        toast.show(Contents.store.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.store.toast.apiError, { type: 'error' });
    }
  }

  const onUpdatePage = async() => {
    if (!pageId) return;
    const data = {
      title: pageName,
      section: sectionList
    };
    try {
      const res = await pageService.updatePage('store', storeId, pageId, data, session);
      if (res) {
        toast.show(Contents.store.toast.pageUpdateSuccess, { type: 'success' });
        navigate('storePageSetting', { storeId });
      } else {
        toast.show(Contents.store.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.store.toast.apiError, { type: 'error' });
    }
  }

  const onSave = async () => {
    if (pageId) {
      await onUpdatePage();
    } else {
      await onCreatePage();
    }
  }

  const onDelete = async() => {
    try {
      if (pageId) {
        const res = await pageService.deletePage('store', storeId, pageId, session);
        setConfirmToRemovePage(false);
        if (res) {
          toast.show(Contents.store.toast.pageDeleteSuccess, { type: 'success' });
          navigate('storePageSetting', { storeId });
        } else {
          toast.show(Contents.store.toast.apiError, { type: 'error' });
        }
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.store.toast.apiError, { type: 'error' });
    }
    setLoading(false);
  }

  const onAddSection = async() => {
    if (!sectionTitle) return;
    const newSection: SectionRecordType = { slug: slugify(sectionTitle), title: sectionTitle };
    setActivePage({ ...activePage, section: [...sectionList, newSection] }, true);
    setSectionTitle('');
    setAdding(false);
  }

  const onOpenSectionForm = (item: SectionRecordType) => {
    navigate('storeSectionForm', { storeId, pageId: pageId || '', activeSection: item })
  }

  const onDeleteSection = () => {
    if (activeSection) {
      const updatedSections: SectionRecordType[] = sectionList.filter(s => s.slug !== activeSection.slug);
      setConfirmToRemoveSection(false)
      setActiveSection(null);
      setActivePage({ ...activePage, section: updatedSections }, true);
    } else
      setConfirmToRemoveSection(false)
  }

  const renderItem = ({ item, drag, isActive }: RenderItemParams<SectionRecordType>) => {
    return (
      <View
        style={[commonStyles.smallPaddingHorizontal, commonStyles.smallPaddingVertical, commonStyles.flex, commonStyles.itemsCenter, commonStyles.spaceBetween]}
      >
        <TouchableOpacity onLongPress={drag} style={commonStyles.flexGrow}>
          <Text>{ item.title }</Text>
        </TouchableOpacity>
        <Ionicons name='settings' size={24} style={commonStyles.smallPaddingHorizontal} color='black' onPress={() => onOpenSectionForm(item)}/>
        <AntDesign name='delete' size={24} color='black' onPress={() => { setActiveSection(item); setConfirmToRemoveSection(true)}} />
      </View>
    );
  }
  useEffect(() => {
    setPageName(activePage?.title || '');
    setSectionList(activePage?.section || [])
    setConfirmToRemoveSection(false)
    setActiveSection(null)
  }, [activePage]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >

        <HeadViewWithSubTitle
          title={ Contents.store.header.pagesForm }
          subTitle={ activeStore.name || '' }
          />
        <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
          <View style={commonStyles.container}>
            <View style={commonStyles.flexGrow}>
              <TextInput
                mode='outlined'
                style={{ marginBottom: 15 }}
                label={Contents.store.placeholders.pageName}
                value={pageName}
                onChangeText={(str) => setPageName(str)}
              />
              {
                pageId?
                  <View style={[commonStyles.flexGrow, commonStyles.basePaddingBottom]}>
                    {
                      isAdding ?
                        <View style={[commonStyles.flex, commonStyles.spaceBetween, commonStyles.itemsCenter]}>
                          <TextInput
                            mode='outlined'
                            style={commonStyles.flexGrow}
                            label={Contents.store.placeholders.sectionTitle}
                            value={sectionTitle}
                            onChangeText={(str) => setSectionTitle(str)}
                          />
                          <TouchableOpacity onPress={onAddSection} style={[commonStyles.smallPaddingHorizontal]}>
                            <AntDesign name='check' size={24} color='black' />
                          </TouchableOpacity>
                          <TouchableOpacity onPress={() => { setAdding(false); setSectionTitle('') }} style={[commonStyles.smallPaddingHorizontal]}>
                            <AntDesign name='close' size={24} color='black' />
                          </TouchableOpacity>
                        </View>
                      :
                        <DefaultButton onPress={() => setAdding(true)} fullWidth>
                          { Contents.store.btn.addSection }
                        </DefaultButton>
                    }
                    {
                      <DraggableFlatList
                        data={sectionList}
                        onDragEnd={({ data }) => setActivePage({ ...activePage, section: data })}
                        keyExtractor={(section) => section.slug}
                        renderItem={renderItem}
                      />
                    }
                  </View>
                :
                <View style={[commonStyles.flexGrow, commonStyles.basePaddingBottom]}></View>
              }
            </View>
            <View style={commonStyles.buttonContainer}>
              <DefaultButton onPress={onSave} fullWidth>
                { pageId ? Contents.common.btn.update : Contents.store.btn.save }
              </DefaultButton>
              {
                pageId ?
                <DangerousActionButton onPress={() => setConfirmToRemovePage(true)} fullWidth>
                  { Contents.common.btn.delete }
                </DangerousActionButton>
                :
                <></>
              }
            </View>
          </View>
        </ScrollView>
        <BackButton />
        { loading ? <Loading /> : <></> }
        <AwesomeAlert
          show={confirmToRemoveSection}
          title={ Contents.store.confirm.title }
          message={ Contents.store.confirm.removeSection }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmToRemoveSection(false);
            setActiveSection(null);
          }}
          onConfirmPressed={() => {
            onDeleteSection();
          }}
        />
        <AwesomeAlert
          show={confirmToRemovePage}
          title={ Contents.store.confirm.title }
          message={ Contents.store.confirm.removePage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmToRemovePage(false);
          }}
          onConfirmPressed={() => {
            onDelete();
          }}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

export default withErrorBoundary(inject('store')(observer(StorePageForm)));
