export default {
  header: {
    locationsPage: 'Our Locations',
  },
  text: {
    locationsPage: 'This is where all the magic happens. :)'    
  },
  button: {
    dashboard: {
      generalCrewTools: 'General Crew Tools',
      managementTools: 'Management Tools',
      crumblLocations: 'Our Crumbl Locations',
      externalLinksCrewApp: 'Crew App（Crumbl Corp)',
      externalLinksOnPay: 'On Pay (Payroll)',
      externalLinksSortly: 'Sortly(Inventory)',
      externalLinksWhenIWork: 'WhenIWork (Time Tracking)',
      fundraisingRequests: 'Fundraising Requests',
      donationRequests: 'Donation Requests',
    },
    crewTools: {
      timesheetCorrection: 'Timesheet Correction',
      incidentReport: 'Accident / Incident Report',
      desiredHoursRequest: 'Desired Hours Request',
      reimbursementRequest: 'Reimbursement Request',
      taxExemptionForm: 'Tax Exemption Form',
      companyFeedbackForm: 'Company Feedback Form',
      cashTipReport: 'Cash Tip Report',
    },
    managementTools: {
      ballingTimesReport: 'Balling Times Report',
      mixingTimesReport: 'Mixing Times Report',
      cashTipReport: 'Cash Tip Report',
      cashDepositReport: 'Cash Deposit Report',
      managerialReport: 'Managerial Report',
      shiftLeadReport: 'Shift Lead Report',
      shipmentReport: 'Shipment Report',
      reimbursementRequest: 'Reimbursement Request',
      ingredientsCalendar: 'Ingredients Calendar',
      MStats: 'MStats',
      cookieCrewInternal: 'CookieCrew Internal',
    },
    locationsPage: {
      callStore: 'Call the Store',
      emailStore: 'E-Mail the Store'
    }
  },  
}