import Contents from '../contents';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import timezones from 'timezones-list';
import * as Localization from 'expo-localization';
let timezoneList = timezones.map(function(item) {
  return {
    'value': item['tzCode'],
    'label': item['label']
  }
});
timezoneList = timezoneList.sort((a, b) => (a.label > b.label) ? 1 : -1)


const formConfig: Omit<LogicProps, 'control'>[] = [
  {
    type: 'text',
    name: 'name',
    rules: {
      required: {
        value: true,
        message: Contents.store.validationErrors.nameRequired,
      },
    },
    textInputProps: {
      label: Contents.store.placeholders.name,
      autoComplete: false
    },
  },
  {
    type: 'autocomplete',
    name: 'state',
    textInputProps: {
      label: Contents.store.placeholders.state,
      autoComplete: true
    },
    options: [
      {
        value: 'AL',
        label: 'Alabama'
      },
      {
        value: 'AK',
        label: 'Alaska'
      },
      {
        value: 'AZ',
        label: 'Arizona'
      },
      {
        value: 'AR',
        label: 'Arkansas'
      },
      {
        value: 'CA',
        label: 'California'
      },
      {
        value: 'CO',
        label: 'Colorado'
      },
      {
        value: 'CT',
        label: 'Connecticut'
      },
      {
        value: 'DE',
        label: 'Delaware'
      },
      {
        value: 'DC',
        label: 'District Of Columbia'
      },
      {
        value: 'FL',
        label: 'Florida'
      },
      {
        value: 'GA',
        label: 'Georgia'
      },
      {
        value: 'HI',
        label: 'Hawaii'
      },
      {
        value: 'ID',
        label: 'Idaho'
      },
      {
        value: 'IL',
        label: 'Illinois'
      },
      {
        value: 'IN',
        label: 'Indiana'
      },
      {
        value: 'IA',
        label: 'Iowa'
      },
      {
        value: 'KS',
        label: 'Kansas'
      },
      {
        value: 'KY',
        label: 'Kentucky'
      },
      {
        value: 'LA',
        label: 'Louisiana'
      },
      {
        value: 'ME',
        label: 'Maine'
      },
      {
        value: 'MD',
        label: 'Maryland'
      },
      {
        value: 'MA',
        label: 'Massachusetts'
      },
      {
        value: 'MI',
        label: 'Michigan'
      },
      {
        value: 'MN',
        label: 'Minnesota'
      },
      {
        value: 'MS',
        label: 'Mississippi'
      },
      {
        value: 'MO',
        label: 'Missouri'
      },
      {
        value: 'MT',
        label: 'Montana'
      },
      {
        value: 'NE',
        label: 'Nebraska'
      },
      {
        value: 'NV',
        label: 'Nevada'
      },
      {
        value: 'NH',
        label: 'New Hampshire'
      },
      {
        value: 'NJ',
        label: 'New Jersey'
      },
      {
        value: 'NM',
        label: 'New Mexico'
      },
      {
        value: 'NY',
        label: 'New York'
      },
      {
        value: 'NC',
        label: 'North Carolina'
      },
      {
        value: 'ND',
        label: 'North Dakota'
      },
      {
        value: 'OH',
        label: 'Ohio'
      },
      {
        value: 'OK',
        label: 'Oklahoma'
      },
      {
        value: 'OR',
        label: 'Oregon'
      },
      {
        value: 'PA',
        label: 'Pennsylvania'
      },
      {
        value: 'RI',
        label: 'Rhode Island'
      },
      {
        value: 'SC',
        label: 'South Carolina'
      },
      {
        value: 'SD',
        label: 'South Dakota'
      },
      {
        value: 'TN',
        label: 'Tennessee'
      },
      {
        value: 'TX',
        label: 'Texas'
      },
      {
        value: 'UT',
        label: 'Utah'
      },
      {
        value: 'VT',
        label: 'Vermont'
      },
      {
        value: 'VA',
        label: 'Virginia'
      },
      {
        value: 'WA',
        label: 'Washington'
      },
      {
        value: 'WV',
        label: 'West Virginia'
      },
      {
        value: 'WI',
        label: 'Wisconsin'
      },
      {
        value: 'WY',
        label: 'Wyoming'
      }
    ]
  },
  {
    type: 'text',
    name: 'city',
    textInputProps: {
      label: Contents.store.placeholders.city,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'zipcode',
    textInputProps: {
      label: Contents.store.placeholders.zipcode,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'storeId',
    textInputProps: {

      label: Contents.store.placeholders.storeId,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'storeSlug',
    textInputProps: {
      label: Contents.store.placeholders.storeSlug,
      autoComplete: false
    },
  },
  {
    type: 'text',
    name: 'storeEmail',
    textInputProps: {
      label: Contents.store.placeholders.storeEmail,
      autoComplete: false
    },
  },
  {
    type: 'autocomplete',
    name: 'timezone',
    defaultValue: Localization.timezone,
    textInputProps: {
      label: Contents.profileScreen.placeholders.timezone,
      autoComplete: false,
    },
    options: timezoneList,
  }
]
export default formConfig;
