import { View, Text, TouchableOpacity, StyleProp, ViewStyle, TextStyle } from 'react-native';
import { commonStyles } from 'styles';
import { Children } from 'types';

type Props = {
  children: Children;
  buttonStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  onPress?: () => void;
};


function DashboardButton(props: Props) {
  const { children, onPress, buttonStyle, labelStyle } = props;

  return (
    <View style={[commonStyles.blackBackground, commonStyles.fullWidth, commonStyles.smallBorderRadius,
      commonStyles.flex, commonStyles.flexGrow, commonStyles.itemsCenter, commonStyles.justifyCenter, buttonStyle]}> 
      <TouchableOpacity style={[commonStyles.selfCenter, commonStyles.flexRow, commonStyles.smallPaddingVertical, commonStyles.basePaddingHorizontal]} 
      onPress={onPress}>
        <Text style={[commonStyles.flexWrap, commonStyles.selfCenter,
          commonStyles.whiteText, commonStyles.textCenter, commonStyles.baseFontSize, commonStyles.baseLineHeight, labelStyle]}> 
          { children }
        </Text>
      </TouchableOpacity>
    </View>
  );
}

export default DashboardButton
