const flexStyles = {
  flex: {
    display: 'flex',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexGrow: {
    flexGrow: 1,
  },
  verticalCenter: {
    alignItems:'center',
    justifyContent:'center',
    height: '100%'
  },
  itemsStretch: {
    alignItems: 'stretch'
  },
  itemsCenter: {
    alignItems: 'center',
  },
  itemsEnd: {
    alignItems: 'flex-end',
  },
  selfStretch: {
    alignSelf: 'stretch',
  },
  selfCenter: {
    alignSelf: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  flexEnd: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  textLeft: {
    textAlign: 'left',
  },
  textCenter: {
    textAlign: 'center',
  },
  flexWrap: {
    flex: 1,
    flexWrap: 'wrap'
  }
}
export default flexStyles
