import React from "react";
import { Text, ViewStyle } from "react-native";
import SkeletonLoader from "expo-skeleton-loader";
import { commonStyles } from 'styles';

type DashboardCardSkeletonProps = {
  size?: number,
  style?: ViewStyle,
}
const DashboardCardSkeleton: React.FC<DashboardCardSkeletonProps> = ({
}) => (
  <SkeletonLoader boneColor="#aaaaaa" style={[commonStyles.basePaddingVertical, commonStyles.basePaddingHorizontal, commonStyles.whiteBackground, commonStyles.baseMarginHorizontal, commonStyles.baseMarginBottom]}>
    <SkeletonLoader.Container style={[commonStyles.flex, commonStyles.flexRow, commonStyles.spaceBetween]}>
      <SkeletonLoader.Item style={{ width: 150, height: 20 }} />
      <Text style={commonStyles.buttonText}>Open</Text>
    </SkeletonLoader.Container>
  </SkeletonLoader>
);

export default DashboardCardSkeleton;