import { Text } from "react-native";
import SkeletonLoader from "expo-skeleton-loader";
import DashboardCardSkeleton from "./DashboardCardSekelton";
import { commonStyles } from 'styles';

type DashboardSkeletonProps = {
  welcomeText: string
};

const DashboardSkeleton: React.FC<DashboardSkeletonProps> = ({ welcomeText }) => (
  <SkeletonLoader style={[commonStyles.noPaddingTop]}>
    {
      !welcomeText ?
      <SkeletonLoader.Container style={[commonStyles.flex, commonStyles.flexRow, commonStyles.justifyCenter, commonStyles.itemsCenter, commonStyles.baseMarginBottom]}>
        <Text style={[commonStyles.headText]}>Welcome </Text>
        <SkeletonLoader.Item style={{ width: 150, height: 20 }} />
      </SkeletonLoader.Container>
      :
      <></>
    }
    
    <DashboardCardSkeleton />
    <DashboardCardSkeleton />
    <DashboardCardSkeleton />
    
  </SkeletonLoader>
);

export default DashboardSkeleton;