import { TreeElement, NodeElement, ElementRecordType, SectionRecordType, PageRecordType } from '../Types';

const TreeService = {
  generateTree (pages: any) {
    const nodes: NodeElement[] = [];
    const buildElementBranch = (element: ElementRecordType): TreeElement => {
      return {
        id: element.slug,
        name: element.text,
        type: 'text-link'
      }
    }
  
    const buildSectionBranch = (section: SectionRecordType): TreeElement => {
      let children: TreeElement[] = [];
      if (section.elements && section.elements.length > 0) children = section.elements.map(buildElementBranch);
      children.forEach((element: TreeElement) => {
        nodes.push({
          nodeId: element.id,
          parentId: section.slug,
          type: 'text-link'
        })
      })
      return {
        id: section.slug,
        name: section.title,
        type: 'section',
        children
      }
    }
  
    const buildPageBranch = (pageId: string, pageRecord: PageRecordType): TreeElement=> {
      let children: TreeElement[] = [];
      if (pageRecord.section && pageRecord.section.length > 0) children = pageRecord.section.map(buildSectionBranch);
      children.forEach((section: TreeElement) => {
        nodes.push({
          nodeId: section.id,
          parentId: pageId,
          type: 'section'
        })
      })
      return {
        id: pageId,
        name: pageRecord.title,
        type: 'page',
        children
      }
    }
  
    const tree = Object.keys(pages).map((key: string) => buildPageBranch(key, pages[key]))
    tree.forEach((page: TreeElement) => {
      nodes.push({
        nodeId: page.id,
        parentId: null,
        type: 'page'
      })
    });
 
    return { tree, nodes };
  },

  convertTreeToRecords(tree: TreeElement[], nodes: NodeElement[], pages: any) {
    const buildMap = (nodes: NodeElement[], childType: string) => {
      const map = nodes
        .filter((node: NodeElement) => node.type === childType)
        .reduce((acc: any, cur: NodeElement) => {
          const key = cur.parentId;
          if (key) {
            return {...acc, [key]: [...(acc[key] || []), cur.nodeId]}
          }
          return acc;
        }, {});
      return map;
    }

    const buildEmptyPageMap = (nodes: NodeElement[]) => {
      const map = nodes.filter((node: NodeElement) => node.type === 'page')
        .reduce((acc: any, cur: NodeElement) => {
          return {...acc, [cur.nodeId]: [] }
        }, {});
        return map;
    }

    const getFilteredElements = (elementIds: string[], elements: ElementRecordType[]): ElementRecordType[] => {
      return elements.filter(element => elementIds.indexOf(element.slug) !== -1);
    }

    const getFilteredSections = (sectionIds: string[], sections: SectionRecordType[]): SectionRecordType[] => {
      return sections.filter(element => sectionIds.indexOf(element.slug) !== -1)
        .map(section => {
          let elements: ElementRecordType[] = [];
          const elementIds = sectionMap[section.slug];
          if (elementIds && section.elements) elements = getFilteredElements(elementIds, section.elements);
          return {...section, elements};
        });
    }
    const sectionMap = buildMap(nodes, 'text-link');
    let pageMap = buildMap(nodes, 'section');
    const emptyPageMaps = buildEmptyPageMap(nodes);
    pageMap = {...emptyPageMaps, ...pageMap};
    const pageIds = Object.keys(pageMap);
    const mappedPages = Object.keys(pages)
      .filter(pageKey => pageIds.indexOf(pageKey) !== -1)
      .map(pageKey => {
        const page = pages[pageKey];
        let section: SectionRecordType[] = [];
        const sectionIds = pageMap[pageKey];
        if (sectionIds && page.section) section = getFilteredSections(sectionIds, page.section);
        return { ...page, id: pageKey, section }
      });

    return mappedPages;
  },

  getNodesById(id: string, nodes: NodeElement[]): NodeElement | undefined {
    return nodes.find(n => n.nodeId === id);
  },
  
  getParentNode(node: NodeElement, nodes: NodeElement[]): NodeElement | undefined{
    return nodes.find(n => n.nodeId === node.parentId);
  },

  getParentNodes(node: NodeElement, nodes: NodeElement[]): NodeElement[] {
    let parents: NodeElement[] = [];
    let parentNode: NodeElement | undefined = node;
    while (parentNode) {
      parentNode = this.getParentNode(parentNode, nodes);
      if (parentNode) parents = [...parents, parentNode];
    }
    return parents;
  },

  getChildrenNodes(node: NodeElement, nodes: NodeElement[]): NodeElement[] {
    let descendants: NodeElement[] = [node];
    const children = nodes.filter(n => n.parentId === node.nodeId);
    if (children && children.length > 0) {
      children.forEach(child => {
        const grandChildren = this.getChildrenNodes(child, nodes);
        descendants = [...descendants, ...grandChildren];
      });
    }
    return descendants;
  }
}

export default TreeService;