import { useEffect, useState, useCallback } from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import UserViewPagesLisLayout from 'components/Skeleton/UserViewPagesLisLayout';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import BackButton from 'components/Button/BackButton';

import withErrorBoundary from 'lib/HoC/withErrorBoundary';
import { ScreensParamList, RouteStackParamList, StoreStoreType, PageRecordType, UserStoreType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storePageList'>;
  route: RouteProp<RouteStackParamList, 'storePageList'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};

function StorePageList(props: Props) {
  const { route } = props;
  const { params: { storeId } } = route;
  const { navigation: { navigate, goBack } } = props;

  const { store: { storeStore, userStore } } = props;
  const { fetchStore, fetchStorePages, pages, activeStore, setActivePage } = storeStore;
  const { session } = userStore;

  const [loading, setLoading] = useState<boolean>(false);

  const onOpenPage = (page: PageRecordType) => {
    setActivePage(page);
    navigate('storePageView', { storeId });
  }

  // - Get all tasks list
  // - Get tasks selected for store
  const init = useCallback(async () => {
    setLoading(true);
    await fetchStore(storeId, session);
    await fetchStorePages(storeId, session);
    setLoading(false);
  }, [session, storeId]);


  useEffect(() => {
    init();
  }, [init]);

  return (
    <View style={[commonStyles.viewport]} testID='end-user-store-page-list'>
      {
        loading ? <UserViewPagesLisLayout />
        :
        <>
          <HeadViewWithSubTitle title={ Contents.store.header.pagesList } subTitle={ activeStore?.name || '' } />
          <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
            <View style={commonStyles.container}>
              {
                pages.map((page: PageRecordType) => (
                  <TouchableOpacity key={page.id} onPress={() => onOpenPage(page)}>
                    <Text style={[commonStyles.smallMarginVertical, commonStyles.primaryText]}>{ page.title }</Text>
                  </TouchableOpacity>
                ))
              }
            </View>
          </ScrollView>
          <BackButton />
        </>
      }
    </View>
  );
}

const StorePageListWithStore = withErrorBoundary(inject('store')(observer(StorePageList)));
export default StorePageListWithStore
