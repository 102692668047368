import { StyleSheet } from 'react-native';

export default StyleSheet.create({
	logoContainer: {
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'stretch'
	},
	logo: {
		width: 250,
		height: 250,
		alignSelf: 'center'
		// marginTop: ''
	},
	dashboardLogoImage: {
		width: 90,
		height: 90
	},
	welcomeButton: {
		marginTop: 100,
		alignSelf: 'center'
	},

	otherView: {
		width: '100%',
		alignSelf: 'center',
		paddingVertical: 10
	},
	otherText: {
		fontSize: 14,
		fontWeight: '700',
		marginBottom: 20
	},
	storeSeperator: {
		marginVertical: 30,
		width: '100%',
		height: 2,
		backgroundColor: '#888'
	},
	blockquote: {marginBottom:10},
});
