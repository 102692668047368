const typographyStyles = {
  smallText: {
    fontSize: 12,
  },
  normalText: {
    fontSize: 14,
  },
  baseFontSize: {
    fontSize: 16,
  },
  smallHeadingFontSize: {
    fontSize: 20,
  },
  subLargeFontSize: {
    fontSize: 24
  },
  largeFontSize: {
    fontSize: 28,
  },
  boldText: {
    fontWeight: 'bold',
  },
  baseLineHeight: {
    lineHeight: 20
  }
}
export default typographyStyles
