import { View, Text } from 'react-native';
import { commonStyles } from 'styles';

type Props = {
  title: string;
  subTitle: string;
};

function HeadViewWithSubTitle(props: Props) {
  const { title, subTitle } = props;
  
  return (
    <>
      <View style={commonStyles.headSubView}>
      {
        subTitle &&
          <Text style={[commonStyles.headSubText]}>
            { subTitle }
          </Text>
      }
      </View>
      <View style={[commonStyles.headViewWithSubView]}>
        <Text style={[commonStyles.headText]}>
            { title }
        </Text>
      </View>

    </>
  );
}

export default HeadViewWithSubTitle;
