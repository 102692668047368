import { View, Image, ScrollView } from 'react-native';
import type { NavigationProp } from '@react-navigation/native';
import { Button } from 'react-native-paper';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList } from 'types';

import { commonStyles, componentsDefaultStyles } from 'styles';
import styles from './styles';
import Contents from 'utils/contents';

type Props = {
	navigation: NavigationProp<ScreensParamList, 'welcome'>;
};


function Welcome({ navigation: { navigate } }: Props) {
	return (
		<View style={[commonStyles.viewport]}>
			<ScrollView
				style={[componentsDefaultStyles.ScrollView]}
				contentContainerStyle={commonStyles.verticalCenter}>
				<View style={styles.welcomeView}>
					<View style={[styles.logoContainer]}>
						<Image
							resizeMode='contain'
							source={require('../../../assets/splash.png')}
							style={[styles.logo]}
						/>
					</View>
					<DefaultButton
						onPress={() =>
							navigate('verificationForm', { type: '', value: '' })
						}
						style={[styles.welcomeButton, commonStyles.baseMarginTop]}
					>
						{Contents.welcomeScreen.btx}
					</DefaultButton>
				</View>
			</ScrollView>
		</View>
	);
}

const WelcomeWithStore = withErrorBoundary(Welcome);
export default WelcomeWithStore;