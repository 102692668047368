import { useEffect } from 'react';
import { View, Text, ScrollView } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { useIsFocused } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import StatisticsCard from 'components/StatisticsCard';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GlobalLoading from 'components/GlobalLoading';

import {
  GroupStoreType,
  ScreensParamList,
  RouteStackParamList,
  UserStoreType
} from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { getStoreName } from 'utils';

import Contents from 'utils/contents';
import styles from './styles';
import { commonStyles } from 'styles';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupOwnerView'>;
  route: RouteProp<RouteStackParamList, 'groupOwnerView'>;
  store: { groupStore: GroupStoreType; userStore: UserStoreType };
};

function GroupManagerView(props: Props) {
  const {
    navigation: { navigate, goBack }
  } = props;
  const {
    route: {
      params: { groupId }
    }
  } = props;
  const {
    store: { groupStore, userStore }
  } = props;
  const { activeGroup, fetchGroup, groupStores } = groupStore;
  const { dashboard, session } = userStore;
  const isFocused = useIsFocused();

  useEffect(() => {
    if (session) fetchGroup(groupId, session);
  }, [fetchGroup, groupId, session]);

  useEffect(() => {
    if (isFocused && dashboard && dashboard.group_tasks) {
      const groupTask = dashboard.group_tasks.find((groupTask: any) => groupTask.groupId === groupId);
      if (groupTask) {
        navigate('groupOnboarding', { groupId });
      }
      return;
    }
  }, [isFocused, dashboard])

  if (!activeGroup) return <GlobalLoading />;
  return (
    <View style={[commonStyles.viewport]} testID='group-manager-view'>
      <HeadViewWithSubTitle title={ Contents.group.header.groupOwnerView } subTitle={ activeGroup?.legalName || '' } />
      <ScrollView>
        <View style={styles.innerView}>
          {activeGroup.statistics && (
            <StatisticsCard
              invited={activeGroup.statistics.invited}
              onBoarding={activeGroup.statistics.onboarding}
              done={activeGroup.statistics.done}
            />
          )}
          <View style={commonStyles.otherView}>
            <Text style={commonStyles.otherText}>
              {Contents.group.text.groupViewStore}
            </Text>
          </View>
          {groupStores &&
            groupStores.length > 0 &&
            groupStores.map((store: any) => (
              <StatisticsCard
                key={store.id}
                name={getStoreName(store)}
                invited={store.statistics?.invited}
                onBoarding={store.statistics?.onboarding}
                done={store.statistics?.done}
              />
            ))}
        </View>
        <View style={commonStyles.buttonContainer}>
          <DefaultButton style={[commonStyles.baseMarginTop]} onPress={() => navigate('groupUsersList', { groupId })}>
            {Contents.group.btn.users}
          </DefaultButton>
        </View>
      </ScrollView>
      <BackButton />
    </View>
  );
}

const GroupManagerViewWithStore = withErrorBoundary(inject('store')(observer(GroupManagerView)));

export default GroupManagerViewWithStore;
