import { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';
import type { RouteProp } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import TaskAccordionListItem from 'components/TaskAccordionListItem';
import IFrameTaskScreen from './IFrameTaskScreen';
import DefaultTaskScreen from './DefaultTaskScreen';
import BackButton from 'components/Button/BackButton';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';

import { UserStoreType, ScreensParamList, RouteStackParamList, Task, GroupStoreType } from 'types';
import UserTaskService from 'lib/Services/userTask.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import styles from '../styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupOnboarding'>;
  route: RouteProp<RouteStackParamList, 'groupOnboarding'>;
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};


const userTaskService = new UserTaskService();

function GroupOnboardingView(props: Props) {
  const { navigation: { goBack } } = props;
  const { route: { params: { groupId } } } = props;
  const {
    store: {
      userStore: { user, session },
      groupStore: { activeGroup }
    }
  } = props;
  const isFocused = useIsFocused();
  const toast = useToast();

  const [onboardingTasks, setOnboardingTasks] = useState<Task[]>([]);
  const [openedTasks, setOpenedTasks] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const errorHandler = (methodName: string, error: any | null) => {
    console.error(`Group Onboarding View  ${methodName}`, error);
    setLoading(false);
    toast.show(Contents.onboarding.toast.commonError, { type: 'error' });
    return;
  }

  const validateOneTask = async (task: Task) => {
    let res;
    try {
      res = await userTaskService.callEndpoint(task.endpoint, session);
    } catch(error) {
      errorHandler('validateOneTask', error);
      return false;
    }
    if (!task.done && res && (res.status === 'success' || res.signed)) {
      // setLoading(true);
      res = await userTaskService.markDone(task.user_task_id, task.id, session);
      setLoading(false);
      forceRefresh({ ...task, ...res });
      return (res && res.done === true);
    }

    return false;

  }

  const validateTasks = async(tasks: Task[]) => {
    try {
      const activeTasksToValidate = tasks.filter((task: Task) => task.validate && !task.done && task.active)
      if (activeTasksToValidate && activeTasksToValidate.length > 0) {
        setLoading(true);
        let validatedTasksCount = 0;
        for (const task of activeTasksToValidate) {
          const isValid = await validateOneTask(task);
          if (isValid) validatedTasksCount++;
        }
        if (validatedTasksCount > 0) await init();
      }

    } catch(error) {
      errorHandler('validateTasks', error);
    }
  }

  const init = async () => {
    setLoading(true);
    try {
      const data = await userTaskService.list(user.id, groupId, session);
      if (data && Object.keys(data).length > 0) {
        const tasks = Object.keys(data)
          .map((key: string) => {
            return data[key] as Task;
          })
          .filter((task: Task) => task.active)
          // REMEMBER: commented it out for now as the sorting is done on backend.
          // .sort((a: Task, b: Task) => a.order - b.order);
        setOnboardingTasks(tasks);

        const uncompletedTasks = tasks.filter((task: Task) => !task.done)
        setOpenedTasks([uncompletedTasks[0].id]);

        await validateTasks(tasks);
      }
    } catch(error) {
      console.log("Error in group onboarding view init", error);
      toast.show(Contents.group.toast.onboardingLoadError, { type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const onToggleOpenedTasks = (task: Task) => {
    if (!task.done && task.endpoint) {
      validateOneTask(task);
    }
    if (task.done === false) return;
    const taskId = task.id;
    const filteredTasks = openedTasks.filter((id: string) => id !== taskId);
    if (filteredTasks.length === openedTasks.length)
      setOpenedTasks([...filteredTasks, taskId]);
    else
      setOpenedTasks([...filteredTasks]);
  }

  const forceRefresh = (updatedTask: Task) => {
    if (updatedTask) {
      const updatedTasks = onboardingTasks.map((task: Task) => task.id === updatedTask.id ? updatedTask : task);
      setOnboardingTasks(updatedTasks);
    }
  }

  useEffect(() => {
    isFocused && init();
  }, [isFocused]);

  return (
    <View style={[commonStyles.viewport]}>
      <ScrollView style={commonStyles.flatView} showsVerticalScrollIndicator={false} contentContainerStyle={ commonStyles.flexGrow }>
        <View style={commonStyles.container}>
          <HeadViewWithSubTitle
            title={Contents.group.header.onboarding.toUpperCase()}
            />
          { loading ?
              <GenericViewSkeleton />
            :
              <>
                <View style={[styles.onboardingWrapper, commonStyles.flexGrow, commonStyles.normalBorderRadius]}>
                  {
                    onboardingTasks.map((task: Task) => {
                      return (
                        <TaskAccordionListItem
                          key={task.id} title={task.name}
                          status={task.done ? 'Done' : 'Pending'} type={task.type} icon={task.icon} iconLibrary={task.iconLibrary}
                          open={openedTasks.includes(task.id)} toggleOpen={() => onToggleOpenedTasks(task)}
                          >
                          {
                            task.type === 'iframe' ?
                              <IFrameTaskScreen task={task} user={user} forceRefresh={forceRefresh} open={openedTasks.includes(task.id)} />
                            :
                              <DefaultTaskScreen task={task} user={user} open={openedTasks.includes(task.id)} onboardingTasks={onboardingTasks} />
                          }
                        </TaskAccordionListItem>
                      );
                    })
                  }
                </View>
                <BackButton />
              </>
          }
        </View>
      </ScrollView>
    </View>
  );
}

const GroupOnboardingViewWithStore = withErrorBoundary(inject('store')(observer(GroupOnboardingView)));

export default GroupOnboardingViewWithStore;
