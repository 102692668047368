import { Linking } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native'
import type { NavigationProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import DoubleColumnItemsLayout from '../ItemLayout/DoubleColumnItemsLayout';
import HalfColumnItemLayout from '../ItemLayout/HalfColumnItemLayout';

import { ColumnItemsType, LayoutItem, StoreStoreType, GroupStoreType, UserStoreType } from 'types';
import FullWidthItemLayout from './FullWidthItemLayout';

type Props = {
  itemsData: ColumnItemsType[];
  store: { storeStore: StoreStoreType, groupStore: GroupStoreType, userStore: UserStoreType };
};

function ItemLayout(props: Props) {
  const { itemsData } = props;
  const { store: { userStore, storeStore, groupStore } } = props;
  const { session } = userStore;
  const { setActivePageById: setStoreActivePageById } = storeStore;
  const { setActivePageById: setGroupActivePageById } = groupStore;

  const navigation = useNavigation();
  const route = useRoute();

  const navigateToWebView = async (itemData: LayoutItem) => {
    const { text, url, link, linkType, type } = itemData; 
    const target = url || link;
    if (type === 'innerLink') {
      const routeName = route?.name;
      if (routeName.indexOf('store') !== -1) {
        await setStoreActivePageById(itemData.targetPage, session);
        navigation.navigate('storePageView');
      } else {
        setGroupActivePageById(itemData.targetPage);
        navigation.navigate('groupPageView');
      }
    } else {
      if (linkType === 'external' || itemData.target === 'blank') {
        if (target) Linking.openURL(target);
      } else if (linkType === 'navigate')
        navigation.navigate(target);
      else
        navigation.navigate('nativeWebView', { label: text, target });
    }
  }

  return (
    <>
      {
        itemsData && itemsData.map((itemLayout, i) => {
          if (itemLayout.layoutType === 'half') 
            return <HalfColumnItemLayout 
              key={i}
              item={itemLayout.items}
              onPress={navigateToWebView}
              />;
          if (itemLayout.layoutType === 'full') 
            return <FullWidthItemLayout 
              key={i}
              item={itemLayout.items}
              onPress={navigateToWebView}
              />;
          return (
            <DoubleColumnItemsLayout 
              key={i}
              items={itemLayout.items}
              onPress={navigateToWebView}
              />
          )
        })
      }
    </>
  );
}

const ItemLayoutWithStore = inject('store')(observer(ItemLayout));
export default ItemLayoutWithStore;
