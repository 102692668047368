import { useState, useEffect, useCallback } from 'react';
import { View, Text, ScrollView } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import StatisticsCard from 'components/StatisticsCard';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';

import { ScreensParamList, RouteStackParamList, StoreStoreType, UserStoreType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeView'>;
  route: RouteProp<RouteStackParamList, 'storeView'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};


function StoreView(props: Props) {
  const { navigation: { navigate } } = props;
  const { route: { params: { groupId, storeId } } } = props;
  const { store: { storeStore, userStore } } = props;
  const { fetchStore, activeStore } = storeStore;
  const { session } = userStore;

  const [loading, setLoading] = useState(false);


  const toEditStorePage = () => {
    if (activeStore) navigate('storeForm', { groupId, storeId });
  }

  const toStoreUsersList = () => {
    navigate('storeUsersList', { groupId, storeId });
  }

  const init = useCallback(async () => {
    setLoading(true);
    await fetchStore(storeId, session);
    setLoading(false);
  }, [storeId, session]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <View style={[commonStyles.viewport]} testID='store-manager-view'>
      {
        (!activeStore || loading) ?
          <GroupStoreViewSkeleton heading={Contents.store.header.ownerStoreView}  />
        :
          <>
            <HeadViewWithSubTitle
              title={ Contents.store.header.ownerStoreView }
              subTitle={activeStore.name} />

            <ScrollView style={[commonStyles.flexGrow, commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical, commonStyles.smallMarginBottom]}
              contentContainerStyle={commonStyles.flexGrow}
            >
              <View style={commonStyles.flexGrow}>
                <StatisticsCard
                  invited={activeStore.statistics.invited}
                  onBoarding={activeStore.statistics.onboarding}
                  done={activeStore.statistics.done}
                />
              </View>
              <View style={[commonStyles.baseMarginTop, commonStyles.largeMarginHorizontal]}>
                <DefaultButton onPress={toEditStorePage} fullWidth>
                  { Contents.store.btn.edit.toUpperCase() }
                </DefaultButton>
                <DefaultButton onPress={toStoreUsersList} fullWidth>
                  { Contents.store.btn.manageUsers.toUpperCase() }
                </DefaultButton>
                <DefaultButton onPress={() => navigate('storePageSetting', { storeId })} fullWidth>
                  {Contents.store.btn.pageSetting.toUpperCase()}
                </DefaultButton>
                <DefaultButton onPress={() => navigate('storeRolesList', { storeId })} fullWidth>
                  {Contents.store.btn.roleManagement.toUpperCase()}
                </DefaultButton>
              </View>
            </ScrollView>
            <BackButton />
          </>
      }
    </View>
  );
}

const StoreViewWithStore = withErrorBoundary(inject('store')(observer(StoreView)));
export default StoreViewWithStore
