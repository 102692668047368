import axios from 'axios';
import { SERVER_URL } from 'utils/Constants';

const apiUrl = `${SERVER_URL}/role`;

class RoleService {
  
  getRolesList = async (type: string, id: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/list/${type}/${id}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in RoleService / getRolesList method`, error);
      return null;
    }
  }

  getRoleDetail = async (roleId: string, session: string) => {
    try {
      const res = await axios.get(`${apiUrl}/roleId/${roleId}`, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in RoleService / getRoleDetail method`, error);
      return null;
    }
  }

  createRole = async (newData: any, session: string) => {
    try {
      const res = await axios.put(`${apiUrl}`, newData, {
        headers: {
          'ocSession': session
        }
      });
      return res.data;
    } catch(error) {
      console.log(`error in Role service / createRole method`, error);
      return null;
    }
  }

}

export default RoleService;