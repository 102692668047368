import { View, Text, Image, ScrollView } from 'react-native';
import type { RouteProp } from '@react-navigation/native';
import { Entypo } from '@expo/vector-icons';

import { ScreensParamList, RouteStackParamList, UserStoreType, GroupStoreType, ColumnItemType, SectionLayoutType} from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import styles from './styles';
import { commonStyles } from 'styles';
import Contents from 'utils/contents';
import SectionLayout from 'components/ItemLayout/SectionLayout';

type Props = {
  navigation: NavigationProp<ScreensParamList, 'dashboard'>;
  route: RouteProp<RouteStackParamList, 'dashboard'>;
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};


const shortcutBlockData: ColumnItemsType[] = [
  {
    layoutType: 'double',
    items: [
      { type: 'link', linkType: 'navigate', text: Contents.staticPageScreen.button.dashboard.generalCrewTools, link: 'crewTools' },
      { type: 'link', linkType: 'navigate', text: Contents.staticPageScreen.button.dashboard.managementTools, link: 'managementTools' }
    ]
  },
  {
    layoutType: 'full',
    items: { type: 'link', linkType: 'navigate', text: Contents.staticPageScreen.button.dashboard.crumblLocations, link: 'locationsPage' }
  }
];


const externalLinksBlockData: ColumnItemsType[] = [
  {
    layoutType: 'double',
    items: [
      { type: 'link', text: Contents.staticPageScreen.button.dashboard.externalLinksCrewApp, link: 'https://crew.crumbl.com/', linkType: 'external' },
      { type: 'link', text: Contents.staticPageScreen.button.dashboard.externalLinksOnPay, link: 'https://onpay.com/', linkType: 'external' }
    ]
  },
  {
    layoutType: 'double',
    items: [
      { type: 'link', text: Contents.staticPageScreen.button.dashboard.externalLinksSortly, link: 'https://www.sortly.com/', linkType: 'external' },
      { type: 'link', text: Contents.staticPageScreen.button.dashboard.externalLinksWhenIWork, link: 'https://wheniwork.com/', linkType: 'external' }
    ]
  }
];

const donationBlockData: ColumnItemType[] = [
  {
    layoutType: 'full',
    items: { type: 'link', text: Contents.staticPageScreen.button.dashboard.fundraisingRequests, link: 'https://form.jotform.com/cookiecrew/fundraisers', linkType: 'external' }
  },
  {
    layoutType: 'full',
    items: { type: 'link', text: Contents.staticPageScreen.button.dashboard.donationRequests, link: 'https://form.jotform.com/cookiecrew/donations', linkType: 'external' }
  }
];


const sectionDataList: SectionLayoutType[] = [
  {
    blocksData: shortcutBlockData
  },
  {
    title: Contents.dashboardScreen.text.externalLinks,
    blocksData: externalLinksBlockData
  },
  {
    title: Contents.dashboardScreen.text.donation,
    description: Contents.dashboardScreen.text.donationHelp,
    blocksData: donationBlockData
  }
]

function NewDashboard({
  navigation: { navigate },
}: Props) {


  return (
    <View style={[commonStyles.viewport]} testID='new-dashboard'>
      <ScrollView style={[commonStyles.flatView]}>
        <View style={[commonStyles.container, commonStyles.noPaddingTop]}>
          <View style={[commonStyles.headView, commonStyles.textCenter, commonStyles.noMarginTop]}>
            <Image
              style={[styles.dashboardLogoImage, commonStyles.marginAuto, commonStyles.baseMarginBottom]}
              source={require('../../../assets/cookie-crew-logo.png')} />
            <Text style={[commonStyles.smallHeadingFontSize, commonStyles.boldText, commonStyles.smallMarginBottom]}>
              { Contents.title }
            </Text>
            <Text style={[commonStyles.baseFontSize, commonStyles.baseMarginBottom]}>
              { Contents.internalUseOnly }
            </Text>
          </View>

            {
              sectionDataList.map((sectionData, i) => (
                <SectionLayout key={i} sectionData={sectionData} />
              ))
            }
            <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.justifyCenter]}>
              <Entypo name="youtube-with-circle" size={36} color="rgb(241, 223, 223)" style={commonStyles.smallMarginRight} />
              <Entypo name="twitter-with-circle" size={36} color="rgb(241, 223, 223)" style={commonStyles.smallMarginRight} />
              <Entypo name="facebook-with-circle" size={36} color="rgb(241, 223, 223)" style={commonStyles.smallMarginRight} />
              <Entypo name="instagram-with-circle" size={36} color="rgb(241, 223, 223)" />
            </View>
        </View>
      </ScrollView>
    </View>
  );
}

export default withErrorBoundary(NewDashboard);
