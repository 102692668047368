import { SafeAreaView } from 'react-native-safe-area-context';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { inject, observer } from 'mobx-react';

import { UserStoreType } from 'types';

import LeftDrawerContent from './LeftDrawerContent';
import CustomHeader from './CustomHeader';
import Welcome from '../Screens/Common/Welcome';
import Dashboard from '../Screens/Dashboard/Dashboard';
import NewDashboard from '../Screens/Common/NewDashboard';
import Profile from '../Screens/Common/Profile';
import VerificationForm from '../Screens/Auth/VerificationForm';
import ConfirmationCode from '../Screens/Auth/ConfirmationCode';
import OwnershipGroup from '../Screens/Auth/OwnershipGroup';
import CreateProfile from '../Screens/Auth/CreateProfile';
import GroupOwnerView from '../Screens/Group/GroupOwnerView';
import GroupManagerView from '../Screens/Group/GroupManagerView';
import GroupTaskSetting from '../Screens/Group/Task/GroupTaskSetting';
import GroupPageSettingList from '../Screens/Group/Page/GroupPageSettingList';
import GroupPageForm from '../Screens/Group/Page/GroupPageForm';
import GroupPageView from '../Screens/Group/Page/GroupPageView';
import GroupPageList from '../Screens/Group/Page/GroupPageList';
import GroupSectionForm from '../Screens/Group/Page/GroupSectionForm';
import GroupRolesList from '../Screens/Group/Role/GroupRolesList';
import GroupRoleForm from '../Screens/Group/Role/GroupRoleForm';
import EndUserGroupPageList from '../Screens/Group/Page/EndUserGroupPageList';
import StoreForm from '../Screens/Store/StoreForm';
import StoreView from '../Screens/Store/StoreView';
import StorePageSetting from '../Screens/Store/Page/StorePageSetting';
import StorePageForm from '../Screens/Store/Page/StorePageForm';
import StorePageList from '../Screens/Store/Page/StorePageList';
import EndUserStorePageList from '../Screens/Store/Page/EndUserStorePageList';
import StorePageView from '../Screens/Store/Page/StorePageView';
import StoreSectionForm from '../Screens/Store/Page/StoreSectionForm';
import StoreRolesList from '../Screens/Store/Role/StoreRolesList';
import StoreRoleForm from '../Screens/Store/Role/StoreRoleForm';

import InviteNewUser from '../Screens/Group/User/InviteNewUser';
import GroupLookupInviteUser from '../Screens/Group/User/GroupLookupInviteUser';
import GroupOnboardingView from '../Screens/Group/Task/GroupOnboardingView';
import GroupUsersList from '../Screens/Group/User/GroupUsersList';
import GroupAdminList from '../Screens/Group/User/GroupAdminList';
import GroupUserForm from '../Screens/Group/User/GroupUserForm';
import StoreUsersList from '../Screens/Store/User/StoreUsersList';
import StoreUserForm from '../Screens/Store/User/StoreUserForm';
import StoreLookupInviteUser from '../Screens/Store/User/StoreLookupInviteUser';
import ManagementTools from '../Screens/Common/ManagementTools';
import CrewTools from '../Screens/Common/CrewTools';
import LocationsPage from '../Screens/Common/LocationsPage';
import NativeWebView from '../Screens/Common/NativeWebView';
import TestWebView from '../Screens/Common/TestWebView';
import GroupPromoteOwner from '../Screens/Group/User/GroupPromoteOwner';


const { Navigator, Screen } = createNativeStackNavigator();
const { Navigator: DrawerNavigator, Screen: DrawerScreen } =
	createDrawerNavigator();

type Props = {
	store: { userStore: UserStoreType; },
	route: RouteProp;
};

function MainNavigator(props: Props) {
	const {
		store: {
			userStore: { user, activeTask }
		}
	} = props;

	return (	
		<SafeAreaView
			edges={['top']}
			style={{ flexGrow: 1, width: '100%', backgroundColor: '#27282d' }}>
			<Navigator
				headerMode="screen"
				initialRouteName={!user ? 'welcome' : 'home'}
				screenOptions={{ headerShown: false }}>
				{!user ? (
					<>
						<Screen name='welcome' component={Welcome} />
						<Screen name='verificationForm' component={VerificationForm} options={{ title: 'Verification Form' }} />
						<Screen name='confirmationCode' component={ConfirmationCode} options={{ title: 'Confirmation Code' }} />
					</>
				) : (
					<Screen name='home'>
						{(props) => <Drawer {...props} activeTask={activeTask} />}
					</Screen>
				)}
			</Navigator>
		</SafeAreaView>
	);
}

function Drawer(props) {
	const { activeTask } = props;
	return (
		<DrawerNavigator
			useLegacyImplementation
			initialRouteName='dashboard'
			backBehavior='history'
			screenOptions={{
				header: (props: any) => {
					return <CustomHeader {...props} />;
				}
			}}
			drawerContent={(props: any) => <LeftDrawerContent {...props} />}>
			<DrawerScreen name='dashboard' component={Dashboard} options={{ title: 'Dashboard' }} />
			<DrawerScreen name='nativeWebView' component={NativeWebView} 
				options={{ title: activeTask ? `Task: ${activeTask.name}` : 'Native Web View' }} />
			<DrawerScreen name='testWebView' component={TestWebView}/>
			<DrawerScreen name='newDashboard' component={NewDashboard} />
			<DrawerScreen name='managementTools' component={ManagementTools} options={{ title: 'Management Tools' }} />
			<DrawerScreen name='crewTools' component={CrewTools} options={{ title: 'Crew Tools' }} />
			<DrawerScreen name='locationsPage' component={LocationsPage} options={{ title: 'Locations Page' }} />
			<DrawerScreen name='verificationForm' component={VerificationForm} options={{ title: 'Verification Form' }} />
			<DrawerScreen name='confirmationCode' component={ConfirmationCode} options={{ title: 'Confirmation Code' }} />
			<DrawerScreen name='ownershipGroup' component={OwnershipGroup} options={{ title: 'Ownership Group' }} />
			<DrawerScreen name='groupPromoteOwner' component={GroupPromoteOwner} options={{ title: 'Group Promote Owner' }} />
			<DrawerScreen name='groupOwnerView' component={GroupOwnerView} options={{ title: 'Group Owner View' }} />
			<DrawerScreen name='groupManagerView' component={GroupManagerView} options={{ title: 'Group Manager View' }} />
			<DrawerScreen name='storeView' component={StoreView} options={{ title: 'Store View' }} />
			<DrawerScreen name='storeForm' component={StoreForm} options={{ title: 'Store Form' }} />
			<DrawerScreen name='createProfile' component={CreateProfile} options={{ title: 'Create Profile' }} />
			<DrawerScreen name='profile' component={Profile} options={{ title: 'Profile' }} />
			<DrawerScreen
				name='groupLookupInviteUser'
				component={GroupLookupInviteUser}
				options={{ title: 'Group Lookup Invite User' }}
			/>
			<DrawerScreen name='inviteNewUser' component={InviteNewUser}  options={{ title: 'Invite New User' }} />
			<DrawerScreen name='groupTaskSetting' component={GroupTaskSetting} options={{ title: 'Group Task Setting' }} />
			<DrawerScreen name='groupOnboarding' component={GroupOnboardingView} options={{ title: 'Group Onboarding' }}/>
			<DrawerScreen name='groupPageSettingList' component={GroupPageSettingList} options={{ title: 'Group Page Settings List' }} />
			<DrawerScreen name='groupPageForm' component={GroupPageForm} options={{ title: 'Group Page Form' }} />
			<DrawerScreen name='groupPageList' component={GroupPageList} options={{ title: 'Group Page List' }} />
			<DrawerScreen name='endUserGroupPageList' component={EndUserGroupPageList} options={{ title: 'Group Page List for End User' }} />
			<DrawerScreen name='groupPageView' component={GroupPageView} options={{ title: 'Group Page View' }} />
			<DrawerScreen name='groupSectionForm' component={GroupSectionForm} options={{ title: 'Group Section Form' }} />
			<DrawerScreen name='groupRolesList' component={GroupRolesList} options={{ title: 'Group Roles List' }} />
			<DrawerScreen name='groupRoleForm' component={GroupRoleForm} options={{ title: 'Group Role Form' }} />
			<DrawerScreen name='groupUsersList' component={GroupUsersList} options={{ title: 'Group Users List' }} />
			<DrawerScreen name='groupAdminList' component={GroupAdminList} options={{ title: 'Group Admin List' }} />
			<DrawerScreen name='groupUserForm' component={GroupUserForm} options={{ title: 'Group User Form' }} />
			<DrawerScreen name='storePageSetting' component={StorePageSetting} options={{ title: 'Store Page Setting' }} />
			<DrawerScreen name='storePageList' component={StorePageList} options={{ title: 'Store Page List' }} />
			<DrawerScreen name='endUserStorePageList' component={EndUserStorePageList} options={{ title: 'Store Page List for End User' }} />
			<DrawerScreen name='storePageView' component={StorePageView} options={{ title: 'Store Page View' }} />
			<DrawerScreen name='storePageForm' component={StorePageForm} options={{ title: 'Store Page Form' }} />
			<DrawerScreen name='storeSectionForm' component={StoreSectionForm} options={{ title: 'Store Section Form' }} />
			<DrawerScreen name='storeRolesList' component={StoreRolesList} options={{ title: 'Store Roles List' }} />
			<DrawerScreen name='storeRoleForm' component={StoreRoleForm} options={{ title: 'Store Role Form' }} />
			<DrawerScreen name='storeUsersList' component={StoreUsersList} options={{ title: 'Store Users List' }} />
			<DrawerScreen name='storeUserForm' component={StoreUserForm} options={{ title: 'Store User Form' }} />
			<DrawerScreen
				name='storeLookupInviteUser'
				component={StoreLookupInviteUser}
			/>
		</DrawerNavigator>
	);
}

export default inject('store')(observer(MainNavigator));
