import { SectionRecordType } from 'types';

import SectionLayout from './ItemLayout/SectionLayout';


type Props = {
  section: SectionRecordType
  [x:string]: any;
};


function SectionComponent(props: Props) {
  const { section, ...rest } = props;

  const blocksData = [];
  if (section.elements && section.elements.length > 0) {
    for (let i = 0; i < section.elements.length; i++) {
      if (section.elements[i].layoutType !== 'full' && section.elements[i + 1] && section.elements[i + 1].layoutType !== 'full') {
        blocksData.push({
          items: [section.elements[i], section.elements[++i]]
        })
      } else {        
        blocksData.push({
          layoutType: section.elements[i].layoutType === 'full' ? 'full' : 'half',
          items: section.elements[i]
        });
      }
    }
  }
  
  const sectionData = {
    ...section,
    blocksData
  }

  return <SectionLayout sectionData={sectionData} {...rest} />

}

export default SectionComponent;