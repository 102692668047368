import React, { useState, useEffect, useRef, FC } from 'react';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Animated,
  Easing,
} from 'react-native';
import { AntDesign, Entypo, MaterialIcons, FontAwesome, FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import { Children } from 'types';
import { commonStyles } from 'styles';

type AccordionListItemProps = {
  title: string;
  status: string;
  type: string;
  icon: string;
  iconLibrary: string;
  children: Children;
  open: boolean;
  toggleOpen: () => void
}

const TaskAccordionListItem: FC<AccordionListItemProps> = ({ title, children, type, status, open, toggleOpen, icon,iconLibrary }) => {
  const [iconColor, setIconColor] = useState<string>('white');
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState<number>(0);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  });

  const toggleListItem = () => {
    toggleOpen();
  };

  useEffect(() => {
    if (open) {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 1,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(animatedController, {
        duration: 300,
        toValue: 0,
        easing: Easing.bezier(0.4, 0.0, 0.2, 1),
        useNativeDriver: true
      }).start();
    }
  }, [open]);


  useEffect(() => {
    if (status === 'Done') setIconColor('#333333'); else setIconColor('#FFFFFF');
  }, [status]);

  return (
    <>
      <TouchableWithoutFeedback onPress={() => toggleListItem()}>
        <View style={[styles.titleContainer, status === 'Done' ? styles.completedRowStyle : styles.notStartedRowStyle]}>
          <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.itemsCenter, commonStyles.smallPaddingHorizontal]}>

            { iconLibrary && icon && iconLibrary === 'antdesign' &&
                <AntDesign name={icon} size={24} color={iconColor} />
            }
            { iconLibrary && icon && iconLibrary === 'entypo' &&
                <Entypo name={icon} size={24} color={iconColor} />
            }
            { iconLibrary && icon && iconLibrary === 'materialicons' &&
                <MaterialIcons name={icon} size={24} color={iconColor} />
            }
            { iconLibrary && icon && iconLibrary === 'fontawesome' &&
                <FontAwesome name={icon} size={24} color={iconColor} />
            }
            { iconLibrary && icon && iconLibrary === 'fontawesome5' &&
                <FontAwesome5 name={icon} size={24} color={iconColor} />
            }
            { iconLibrary && icon && iconLibrary === 'materialcommunityicons' &&
                <MaterialCommunityIcons name={icon} size={24} color={iconColor} />
            }
            { !(iconLibrary && icon) && type === 'API' && <Entypo name="network" size={24} color={iconColor} />}
            { !(iconLibrary && icon) && type === 'iframe' && <MaterialIcons name="draw" size={24} color={iconColor} />}
            { !(iconLibrary && icon) && type === 'upload' && <Entypo name="upload" size={24} color={iconColor} />}
            <Text style={[styles.titleText, status === 'Done' ? styles.titleText : styles.notStartedTextStyle]}>{title}</Text>
          </View>
          <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
            <MaterialIcons name="keyboard-arrow-down" size={20} color={iconColor} />
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          onLayout={event =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }>
          {children}
        </View>
      </Animated.View>
    </>
  );
};
export default TaskAccordionListItem;

const styles = StyleSheet.create({
  bodyBackground: {
    backgroundColor: '#EFEFEF',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    overflow: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    paddingLeft: '1.5rem',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#EFEFEF',
  },
  titleText: {
    marginLeft: 10
  },
  notStartedRowStyle: {
    backgroundColor: '#999999',
  },
  completedRowStyle: {
    backgroundColor: '#18d7c1',
  },
  notStartedTextStyle: {
    color: '#FFFFFF'
  },
  completedTextStyle: {
    color: '#333333'
  },
  bodyContainer: {
    padding: '1rem',
    paddingLeft: '1.5rem',
    textAlign: 'center',
    bottom: 0,
  },
});
