import { StyleProp, ViewStyle } from 'react-native';
import { Button } from 'react-native-paper';

import { Children } from 'types';
import { commonStyles, componentsDefaultStyles } from 'styles';

type Props = {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  fullWidth?: boolean;
  disabled?: boolean;
  children: Children;
};

function DangerousActionButton({
  onPress,
  style,
  children,
  disabled = false,
  fullWidth = false
}: Props) {

  return (
    <Button
      style={[componentsDefaultStyles.Button, commonStyles.smallMarginBottom, 
        disabled ? commonStyles.grayBackground : commonStyles.redBackground, 
        fullWidth ? commonStyles.fullWidth : '', 
        style]}
      labelStyle={[
        disabled ? commonStyles.grayText : commonStyles.whiteText,
        commonStyles.smallText
      ]}
      onPress={onPress}>
      {children}
    </Button>
  );
}

export default DangerousActionButton;
