import { useState, useEffect } from 'react';
import { View, Text, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native'
import { useToast } from 'react-native-toast-notifications';
import { inject, observer } from 'mobx-react';
import Markdown from 'react-native-markdown-display';

import DefaultButton from 'components/Button/DefaultButton';
import Loading from 'components/Loading';

import { Task, User, UserStoreType } from 'types';
import UserTaskService from 'lib/Services/userTask.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import {commonStyles } from 'styles';
import Contents from 'utils/contents';

type Props = {
  task: Task
  onboardingTasks: Task[]
  user: User
  open: boolean
  store: { userStore: UserStoreType };
};


const userTaskService = new UserTaskService();

function DefaultTaskScreen(props: Props) {
  const { task, open, onboardingTasks } = props;
  const { store: { userStore: { setActiveTask, session } } } = props;
  const [requiredTask, setRequiredTask] = useState<Task>();
  const [allBefore, setAllBefore] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigation = useNavigation();
  const toast = useToast();

  const errorHandler = (error: any | null) => {
    console.error('Default Task Screen callEndpoint', error);
    setLoading(false);
    toast.show(Contents.onboarding.toast.commonError, { type: 'error' });
    return;
  }

  const onCallEndpoint = async (autoRedirect = true) => {
    console.log('need to know about task', task)



    if (task.endpoint) {
      // Set the loading graphic.
      setLoading(true);
      let res;
      // Call the endpoint.
      try {
        res = await userTaskService.callEndpoint(task.endpoint, session);
      } catch(error) {
        errorHandler(error);
        return;
      }
      // Handle the error!
      if (!res) {
        errorHandler('BAD API');
        return;
      }

      console.log("task endpoint", res);
      // Disable Loading.

      setLoading(false);

      if (!task.done) {
        if (res.signed) {
            setLoading(true);
            await userTaskService.markDone(task.user_task_id, task.id, session);
            toast.show(Contents.onboarding.toast.alreadySigned);
            setLoading(false);
          } else if (res.sign_url && autoRedirect) {
            navigation.navigate('nativeWebView', { label: `Task: ${task.name}`, target: res.sign_url });
            setActiveTask(task)
          } else if (!task.done && task.remote_url && autoRedirect) {
            Linking.openURL(task.remote_url)
          }
        }
        checkRequiredTask();
      } else if (task.remote_url && autoRedirect) {
        Linking.openURL(task.remote_url)
      }
  }

  const checkRequiredTask = () => {
    let newRequiredTask = null;
    if (task.required) {
      if (task.required === 'ALL_BEFORE') {
        const currentTaskIndex = onboardingTasks.findIndex((oT: Task) => oT.id === task.id);
        const firstUnfinishedTaskIndex = onboardingTasks.findIndex((oT: Task) => !oT.done);
        setAllBefore(currentTaskIndex > firstUnfinishedTaskIndex + 1);
      } else {
        const requiredTask = onboardingTasks.find((oT: Task) => oT.id === task.required);
        if (requiredTask && requiredTask.active && !requiredTask.done) newRequiredTask = requiredTask;
      }
    }
    setRequiredTask(newRequiredTask);
  }

  useEffect(() => {
    // Auto run task
    if (open && !task.done && !isLoading && task.auto_run) {
      onCallEndpoint(false);
    }
  }, [open]);

  return (
    <View style={[commonStyles.basePaddingVertical, commonStyles.basePaddingHorizontal, commonStyles.relative]}>
    {
      task.disabled ?
        <Text>{ Contents.onboarding.text.notDone }</Text>
      :
        <>

          { (!task.done) && requiredTask &&
              <Text>This task requires that you complete the [{requiredTask.name}] first. Please complete that task and then return here.</Text>
          }
          {
            (!task.done) && !requiredTask && (
              allBefore ?
                <Text>{ Contents.onboarding.text.allBefore }</Text>
              :
                <Markdown style={commonStyles.markdown}>{task.description || ''}</Markdown>
            )
          }

          { (!task.done && !requiredTask && task.before_complete_btn) &&
            <DefaultButton style={[commonStyles.baseMarginTop]} onPress={onCallEndpoint}>
              { task.before_complete_btn }
            </DefaultButton>
          }

          { (task.done && task.description_after) &&
              <Markdown style={commonStyles.markdown}>{task.description_after}</Markdown>
          }
          { (task.done && task.after_complete_btn) &&
            <DefaultButton
              style={[commonStyles.baseMarginTop]} onPress={onCallEndpoint}>
              { task.before_complete_btn }
            </DefaultButton>
          }
          {
            isLoading && <Loading />
          }
        </>
    }
    </View>
  );
}

const DefaultTaskScreenWithStore = withErrorBoundary(inject('store')(observer(DefaultTaskScreen)));
export default DefaultTaskScreenWithStore;
