import { useState, useEffect } from 'react';
import {
  Platform,
  ScrollView,
  View,
  KeyboardAvoidingView
} from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp } from '@react-navigation/native';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import { inject, observer } from 'mobx-react';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';

import { ScreensParamList, OwnershipBodyType, UserStoreType } from 'types';
import Contents from 'utils/contents';
import BaseDataService from 'lib/Services/baseData.service';
import GroupTaskService from 'lib/Services/groupTask.service';
import RoleService from 'lib/Services/role.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import { ownershipGroupFormConfig } from 'utils/FormConfig';


const groupTaskService = new GroupTaskService();
const userGroupService = new BaseDataService('user_group');
const groupService = new BaseDataService('group');
const roleService = new RoleService();

type Props = {
  navigation: NavigationProp<ScreensParamList, 'ownershipGroup'>;
  store: { userStore: UserStoreType };
};

const defaultValues: OwnershipBodyType = {
  legalName: '',
  ein: '',
  email: ''
}

function OwnershipGroup(props: Props) {
  const {
    navigation: { navigate },
    store: { userStore }
  } = props;
  const { user, updateUser, session } = userStore;
  const {control, setFocus, handleSubmit, reset, formState: { isValid }} = useForm({
    defaultValues,
    mode: 'onChange',
  });
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState<boolean>(false);

  const next = async (body: OwnershipBodyType) => {
    var userId = user.id;
    if (!userId) return;
    setLoading(true)
    const result = await groupService.create({...body, owners: [userId]}, session);
    if (result.status === 'success') {
      const newGroupId = result.id;
      await updateUser(userId, {
        owner_groups: [...(user.owner_groups || []), newGroupId],
        updatedAt: new Date()
      }, session);
      await userGroupService.create({ userId, groupId: newGroupId }, session);
      await groupTaskService.initialize(newGroupId, session);
      try {
        await roleService.createRole({ group: newGroupId, name: 'General Crew' }, session);
        await roleService.createRole({ group: newGroupId, name: 'Management Crew' }, session);
      } catch(error) {
        console.error('Error while creating default roles for a new group', error);
      }
      setLoading(false);
      navigate('dashboard')
    }
  };

  useEffect(() => {
		isFocused && reset(defaultValues);
	}, [isFocused]);
  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle
          title={ Contents.ownershipGroupScreen.txt.header }
          subTitle={ Contents.ownershipGroupScreen.txt.paragraph } />
        {
          loading ?
            <GenericViewSkeleton />
          :
            <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
              <View style={commonStyles.container}>
                <View style={commonStyles.flexGrow}>
                  <FormBuilder
                    control={control}
                    setFocus={setFocus}
                    formConfigArray={ownershipGroupFormConfig}
                  />
                </View>
                <View style={commonStyles.buttonContainer}>
                  <DefaultButton disabled={!isValid} onPress={handleSubmit(next)} fullWidth>
                    { Contents.ownershipGroupScreen.btn }
                  </DefaultButton>
                </View>
              </View>
            </ScrollView>
        }
        <BackButton />
      </KeyboardAvoidingView>
    </View>
  );
}
const OwnershipGroupWithStore = withErrorBoundary(inject('store')(observer(OwnershipGroup)));
export default OwnershipGroupWithStore;
