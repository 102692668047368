import { useState } from 'react';
import { View, Text } from 'react-native';
import { useNavigation, ParamListBase } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';

import { inject, observer } from 'mobx-react';

import DashboardGroupStoreCard from 'components/DashboardGroupStoreCard';
import AccordionListItem from 'components/AccordionListItem';

import { RouteStackParamList, UserStoreType, GroupStoreType} from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';

type Props = {
  route: RouteProp<RouteStackParamList, 'dashboard'>;
  store: { userStore: UserStoreType, groupStore: GroupStoreType };
};

type ViewType = 'user' | 'manager' | 'owner'

function DashboardOwner({
  store: {
    userStore: { dashboard, session },
    groupStore: { fetchRole }
  }
}: Props) {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const [activeView, setActiveView] = useState<ViewType>('owner');

  const onOpenStore = (groupId: string, storeId: string) => {
    navigation.navigate('storeView', { groupId, storeId });
  }

  const onOpenEndUserGroup = async (userGroup: any) => {
    const { groupId, roleId } = userGroup;
    if (roleId) fetchRole(roleId, session);
    navigation.navigate('endUserGroupPageList', { groupId });
  }

  const onOpenEndUserStore = (userStore: any) => {
    if (userStore?.roleId) fetchRole(userStore?.roleId, session);
    navigation.navigate('endUserStorePageList', { groupId: userStore.groupId, storeId: userStore.storeId });
  }

  const onOpenOwnerGroup = (groupId: string) => {
    navigation.navigate('groupOwnerView', { groupId });
  }



  return (
    <>
      <AccordionListItem title={Contents.dashboardScreen.text.user}
        open={activeView === 'user'}
        toggleOpen={() => setActiveView('user')}>
        {
          (dashboard && dashboard.user_groups && dashboard.user_groups.length > 0) &&
          (
            <View style={commonStyles.otherView}>
              <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
                {Contents.dashboardScreen.text.group}
              </Text>
              {
                dashboard.user_groups.map((user_group: any) => (
                <DashboardGroupStoreCard
                  key={user_group.group.id}
                  title={user_group.group.legalName}
                  button='Open'
                  onPress={() => onOpenEndUserGroup(user_group)}
                />
                ))
              }
            </View>
          )
        }
        {
          (dashboard && dashboard.user_stores && dashboard.user_stores.length > 0) &&
          (
            <View style={commonStyles.otherView}>
              <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
                {Contents.dashboardScreen.text.store}
              </Text>
              {
                dashboard.user_stores.map((user_store: any) => (
                  <DashboardGroupStoreCard
                    key={user_store.store.id}
                    title={user_store.store.name}
                    button='Open'
                    onPress={() => onOpenEndUserStore(user_store)}
                  />
                ))
              }
            </View>
          )
        }
      </AccordionListItem>
      <AccordionListItem title={Contents.dashboardScreen.text.owner}
        open={activeView === 'owner'}
        toggleOpen={() => setActiveView('owner')}>
        {
          dashboard && dashboard.owned_stores && dashboard.owned_stores.length > 0 &&
          <View style={commonStyles.otherView}>
            <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
              {Contents.dashboardScreen.text.store}
            </Text>
            {
              dashboard.owned_stores.map((store: any) => (
                <DashboardGroupStoreCard
                  key={store.id}
                  title={`${store.name}, ${store.city}, ${store.state}`}
                  button='Open'
                  onPress={() => onOpenStore(store.group, store.id)}
                />)
              )
            }
          </View>
        }
        {
          dashboard && dashboard.owned_groups && dashboard.owned_groups.length > 0 &&
          <View style={commonStyles.otherView}>
            <Text style={[commonStyles.otherText, commonStyles.basePaddingHorizontal]}>
              {Contents.dashboardScreen.text.group}
            </Text>
            {
              dashboard.owned_groups.filter((group: any) => !group.archived)
              .map((group: any) => (
                <DashboardGroupStoreCard
                  key={group.id}
                  title={group.legalName}
                  button='Open'
                  onPress={() => onOpenOwnerGroup(group.id)}
                />)
              )
            }
          </View>
        }
      </AccordionListItem>
    </>
  );
}

const DashboardOwnerWithStore = withErrorBoundary(inject('store')(observer(DashboardOwner)));
export default DashboardOwnerWithStore;
