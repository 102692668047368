//TestWebView.tsx
import React, { useEffect, CSSProperties } from 'react';
import { Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import { useToast } from 'react-native-toast-notifications';

import withErrorBoundary from 'lib/HoC/withErrorBoundary';

const SITE_URL = 'https://jotform.com/cookiecrew/form' //?rurl=localhost:8000/callback'

const TestWebView = (): JSX.Element => {
  const url = SITE_URL;
  const toast = useToast();

  const onHandleData = (dataString: any) => {
    try {
      const data = JSON.parse(dataString);
      console.log('Real Data handling part here based on action', data.action)
      if (data.action === 'hello') {
        toast.show('Form submitted', { type: 'success' });
      }
    } catch(error) {
      console.log('Unparsable data', dataString);
    }
  }

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.onmessage = function(e) {
        onHandleData(e.data)
      };
    }
  }, []);

  const onMessage = ({nativeEvent}) => {
    onHandleData(nativeEvent.data)
  }

  
  if (Platform.OS === 'web') {
    return <iframe src={url} style={styles} x-frame-options={'allow-from http://localhost:8000'} />;
  }
  return <WebView 
    source={{ uri: url }} 
    originWhitelist={['*']}
    onMessage={onMessage}
    javaScriptEnabled
    sharedCookiesEnabled />;
};

const styles: CSSProperties = {
  height: '100%',
  width: '100%',
  zIndex: 10000,
  position: 'static'
};

export default withErrorBoundary(TestWebView);