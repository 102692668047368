import React from 'react'
import { View, Text, SafeAreaView } from 'react-native'
// import AsyncStorage from '@react-native-community/async-storage'
import { FontAwesome } from '@expo/vector-icons';
// import RNRestart from 'react-native-restart'
import { Button } from 'react-native-paper';
import DefaultButton from 'components/Button/DefaultButton'

const withErrorBoundary = (WrappedComponent) => {

  return class CustomErrorBoundary extends React.Component<any, any> {

    state = {
      error: false
    }

    static getDerivedStateFromError () {
      return { error: true };
    }

    // componentDidCatch (error, errorInfo) {
    //   // deal with errorInfo if needed
    // }

    destroyAuthToken = async () => {
      // await AsyncStorage.removeItem('user_settings');
    }

    handleBackToSignIn = async () => {
      // remove user settings
      await this.destroyAuthToken();
      // restart app
      //  RNRestart.Restart();
    }

    render () {

      // const { theme } = this.context;

      if (this.state.error) {
        return (
          <SafeAreaView
            
          >
            <View >
              <View style={{ marginTop: 120, paddingHorizontal: 40 }}>
                <Text style={{ width: '100%', textAlign: 'center' }}>
                  <FontAwesome
                    name='info-circle'
                    size={60}
                  />
                </Text>
                <Text style={{ fontSize: 32, textAlign: 'center', marginVertical: 20 }}>Oops, Something Went Wrong</Text>
                <Text style={{ marginVertical: 10, lineHeight: 23, fontWeight: '500', }}>
                  The app ran into a problem and could not continue. We apologise for any inconvenience this has caused! Please contact us if this issue persists.
                </Text>
              </View>
            </View>
          </SafeAreaView>
        )
      } else {
        return <WrappedComponent {...this.props} />;
      }
    }
  }
}

export default withErrorBoundary;