import { useState } from 'react';
import { View, ScrollView } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import BackButton from 'components/Button/BackButton';
import SectionComponent from 'components/SectionComponent';

import { StoreStoreType, ScreensParamList, RouteStackParamList, ElementRecordType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storePageView'>;
  route: RouteProp<RouteStackParamList, 'storePageView'>;
  store: { storeStore: StoreStoreType };
};


function StorePageView(props: Props) {
  const { navigation: { goBack } } = props;
  const { store: { storeStore } } = props;
  const { activeStore, activePage } = storeStore;
  const [openedSections, setOpenedSections] = useState<string[]>([]);

  const onToggleOpenedSections = (sectionSlug: string) => {
    const filteredSections = openedSections.filter((id: string) => id !== sectionSlug);
    if (filteredSections.length === openedSections.length)
      setOpenedSections([...filteredSections, sectionSlug]);
    else
      setOpenedSections([...filteredSections]);
  }

  if (!activePage) return null;

  console.log('acitve page section', activePage)

  return (
    <View style={[commonStyles.viewport]} testID='end-user-store-page-view'>
      <HeadViewWithSubTitle title={ Contents.store.header.pagesList } subTitle={ activeStore?.name || '' } />
      <ScrollView style={commonStyles.flatView} contentContainerStyle={commonStyles.flexGrow}>
        <View style={commonStyles.container}>
          {
            activePage.section ?
              activePage.section.map(section => (
                <SectionComponent key={section.slug} section={section}
                  open={openedSections.includes(section.slug)} toggleOpen={() => onToggleOpenedSections(section.slug)} />
              ))
            :
              <></>
          }
        </View>
      </ScrollView>
      <BackButton />
    </View>
  );
}

const StorePageViewWithStore = withErrorBoundary(inject('store')(observer(StorePageView)));
export default StorePageViewWithStore;
