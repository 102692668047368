import { View, Text } from 'react-native';

import DashboardButton from './DashboardButton';

import { StoreLocation } from 'types';

import Contents from 'utils/contents';
import { commonStyles } from 'styles';

type Props = {
  title: string;
  location: StoreLocation
};


function StoreLocationComponent(props: Props) {
  const { title, location } = props;

  return (
    <View style={[commonStyles.baseMarginVertical]}>
      <Text style={[commonStyles.largeFontSize, commonStyles.boldText, commonStyles.textCenter, commonStyles.baseMarginBottom]}>{ title }</Text>
      <View style={[commonStyles.textCenter, commonStyles.baseMarginBottom]}>
        <Text style={commonStyles.baseFontSize}>{ location.address }</Text>
        <Text style={commonStyles.baseFontSize}>{ `${location.city}, ${location.state}, ${location.zipcode}` }</Text>
        <Text style={commonStyles.baseFontSize}>{ location.phone }</Text>
        <Text style={commonStyles.baseFontSize}>{ location.email }</Text>
        <Text style={commonStyles.baseFontSize}>Opened: { location.openedDate }</Text>
      </View>
      <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.baseMarginBottom]}>
        <View style={[commonStyles.flexWrap, commonStyles.smallMarginRight]}>
          <DashboardButton>{ Contents.staticPageScreen.button.locationsPage.callStore }</DashboardButton>
        </View>
        <View style={[commonStyles.flexWrap, commonStyles.smallMarginLeft]}>
          <DashboardButton>{ Contents.staticPageScreen.button.locationsPage.emailStore }</DashboardButton>
        </View>
      </View>
    </View>
  );
}

export default StoreLocationComponent
