import {configureFonts, DefaultTheme} from 'react-native-paper';
import {fontConfig} from './fonts'

const Theme = {
    ...DefaultTheme,
    fonts: configureFonts(fontConfig),
    colors: {
        ...DefaultTheme.colors,
        primary: 'teal',
        // accent: 'yellow',
    },
    roundness: 0,
}

export default Theme;
