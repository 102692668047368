import { observable, action, makeObservable, runInAction } from 'mobx';

import GroupService from 'lib/Services/group.service';
import PageService from 'lib/Services/page.service';
import RoleService from 'lib/Services/role.service';
import BaseDataService from 'lib/Services/baseData.service';
import { fillUserCollection } from 'utils';
import { Group, GroupUser, PageRecordType, SectionRecordType, ElementRecordType, GroupRole, Store } from 'types';


class GroupStore {
  groupService: GroupService;
  groupDataService: BaseDataService;
  pageService: PageService;
  roleService: RoleService;
  @observable activeGroupUser: GroupUser | null = null;
  @observable activeGroup: Group | null = null;
  @observable groupPages: PageRecordType[] = []; // Whole pages list of the group
  @observable pages: PageRecordType[] = []; // pages list of the current user role of group
  @observable activeGroupPageMap: ElementRecordType[] = [];
  @observable role: any;
  @observable currentRole: GroupRole; // TODO: Should decide wheter to remove this quite soon.
  @observable roles: GroupRole[] = [];
  @observable activePage: PageRecordType | null = null;
  @observable isCurrentUserManager: boolean = false;
  @observable isCurrentUserOwner: boolean = false;
  @observable groupStores: Store[] = [];
  @observable owners: GroupUser[] = [];
  @observable managers: GroupUser[] = [];
  @observable groupUsers: GroupUser[] = [];
  
  constructor() {
    makeObservable(this);
    this.groupService = new GroupService();
    this.pageService = new PageService();
    this.roleService = new RoleService();
    this.groupDataService = new BaseDataService('group');
  }

  fetchGroup = async(groupId: string, session: string) => {
    const groupDetail = await this.groupService.fetchGroupDetail(groupId, session);
    console.log('fetch group', groupDetail)
    runInAction(() => {
      this.activeGroup = {... groupDetail };
      this.isCurrentUserManager = groupDetail.isManager;
      this.isCurrentUserOwner = groupDetail.isOwner;
    });
  };

  @action updateActiveGroup = (data: any) => {
    this.activeGroup = {...(this.activeGroup|| {}), ...data}
  };

  @action setPageMap = (map: ElementRecordType[]) => {
    this.activeGroupPageMap = map;
  }

  fetchGroupUsers = async(groupId: string, session: string) => {
    let activeGroup = this.activeGroup;
    if (!activeGroup) activeGroup = await this.groupService.fetchGroupDetail(groupId, session);
    const groupUsers = await this.groupService.getGroupUsers(groupId, session);
    let owners: GroupUser[] = [], managers: GroupUser[] = [];
    console.log('active group fetch users', activeGroup, groupUsers)
    if (activeGroup && activeGroup.owners && groupUsers) owners = fillUserCollection(activeGroup.owners, groupUsers, 'userId');
    if (activeGroup && activeGroup.managers && groupUsers) managers = fillUserCollection(activeGroup.managers, groupUsers, 'userId');
    runInAction(() => {
      this.owners = owners;
      this.managers = managers;
      this.groupUsers = groupUsers
    });
  }

  fetchGroupStores = async(groupId: string, session: string) => {
    const groupStores = await this.groupService.getGroupStores(groupId, session);
    runInAction(() => {
      this.groupStores = groupStores;
    });
  }

  fetchGroupPages = async(session: string) => {
    const groupId = this.activeGroup?.id;
    if (!groupId) return;
    const groupPages = await this.pageService.getPages('group', groupId, session);
    const pages: PageRecordType[] = Object.keys(groupPages)
      .map(key => ({ id: key, ...groupPages[key] }));
    runInAction(() => {
      this.groupPages = pages;
      this.pages = pages;
    });
  };

  fetchRole = async(roleId: string, session: string) => { 
    const role = await this.roleService.getRoleDetail(roleId, session);
    runInAction(() => {
      this.role = role;
      this.pages = role?.pages || this.groupPages;
    });
  };

  fetchRoles = async(session: string) => {
    const groupId = this.activeGroup?.id;
    if (!groupId) return;
    const roles = await this.roleService.getRolesList('groupId', groupId, session);
    runInAction(() => {
      this.roles = roles;
    })
  }

  @action setActivePage = async (pageRecord: PageRecordType | null, session: string, toUpdate: boolean = false) => {
    this.activePage = pageRecord ? { ...pageRecord } : null;
    if (toUpdate) {
      if (!this.activeGroup || !pageRecord) return;
      await this.pageService.updatePage('group', this.activeGroup.id, pageRecord.id, pageRecord, session);
    }
  };

  @action setActivePageById = (pageId: string) => {
    if (!this.pages || this.pages.length < 1) return;
    const page = this.pages.find((page:PageRecordType) =>page.id === pageId);
    if (page) this.activePage = page;
  }

  updateActiveSection = async (activeSection: SectionRecordType, session: string) => {
    if (!this.activeGroup || !this.activePage || !this.activePage.section) return;
    const updatedSections = this.activePage.section.map((s: SectionRecordType) => s.slug === activeSection.slug ? activeSection : s);
    const updatedPage = await this.pageService.updatePage('group', this.activeGroup.id, this.activePage.id, {...this.activePage, section: updatedSections}, session);
    runInAction(() => {
      this.activePage = updatedPage;
    });
  }

  @action setActiveGroupUser = async (groupUser: GroupUser | null, toUpdate: boolean = false) => {
    let isManager = false;
    if (this.activeGroup?.managers) {
      const managerIds = this.activeGroup?.managers.map(manager => manager.id);
      if (groupUser?.userId) isManager = managerIds.includes(groupUser?.userId);
    }
    this.activeGroupUser = groupUser ? { ...groupUser, isManager } : null;
  }

  @action archiveGroup = async(session: string) => {
    if (!this.activeGroup || !this.activeGroup.id) return;
    const groupId = this.activeGroup.id;
    const archivedGroup = await this.groupService.archive(groupId, session);
    runInAction(() => {
      this.activeGroup = archivedGroup;
    });
  }

}

export default GroupStore;
