export default {
  txt: {
    header: 'Let\'s get you logged in!',
    verification_header: 'Let\'s verify',
    confirm: 'Confirmation Code',
    code_sent_email: 'An email has been sent with your verification code.',
    code_sent_phone: 'An text message has been sent to your phone with your verification code.',
    code_sent_voice: 'A phone call coming shortly will provide you verification code.',
  },
  placeholders: {
    verificationType: 'Email Address / Phone Number',
    phone: 'Mobile Phone',
    voice: 'Voice Call',
    email: 'Email Address',
    verification: 'Verification Code'
  },
  btn: {
    verify: 'Send Verification Code',
    reset: 'Reset',
    confirm: 'Verify Code'
  }
}
