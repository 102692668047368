import { useState } from 'react';
import { View, ScrollView } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { inject, observer } from 'mobx-react';

import SectionComponent from 'components/SectionComponent';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import BackButton from 'components/Button/BackButton';

import { GroupStoreType, ScreensParamList, RouteStackParamList } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupPageView'>;
  route: RouteProp<RouteStackParamList, 'groupPageView'>;
  store: { groupStore: GroupStoreType };
};


function GroupPageView(props: Props) {
  const { navigation: { goBack } } = props;
  const { store: { groupStore } } = props;
  const { activeGroup, activePage } = groupStore;
  const [openedSections, setOpenedSections] = useState<string[]>([]);

  const onToggleOpenedSections = (sectionSlug: string) => {
    const filteredSections = openedSections.filter((id: string) => id !== sectionSlug);
    if (filteredSections.length === openedSections.length)
      setOpenedSections([...filteredSections, sectionSlug]);
    else
      setOpenedSections([...filteredSections]);
  }

  if (!activePage) return null;

  return (
    <View style={[commonStyles.viewport]}>
      <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
        <View style={commonStyles.container}>
          <HeadViewWithSubTitle title={ activePage.title } />
          <ScrollView style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground]}
            contentContainerStyle={commonStyles.flexGrow}>

            <View style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.basePaddingVertical, commonStyles.flex, commonStyles.basePaddingHorizontal]}>
              {
                activePage.section ?
                  activePage.section.map(section => (
                    <SectionComponent key={section.slug} section={section}
                      open={openedSections.includes(section.slug)} toggleOpen={() => onToggleOpenedSections(section.slug)} />
                  ))
                :
                  <></>
              }
            </View>
          </ScrollView>
          {
            activePage.hasBackButton !== false && <BackButton />
          }
        </View>
      </ScrollView>
    </View>
  );
}

const GroupPageViewWithStore = withErrorBoundary(inject('store')(observer(GroupPageView)));
export default GroupPageViewWithStore;
