export const xxsSpacingValue = 5;
export const xsSpacingValue = 5;
export const smallSpacingValue = 10;
export const baseSpacingValue = 20;
export const largeSpacingValue = 32;
export const xxlSpacingValue = 90;
const spacingStyles = {
    smallPaddingHorizontal: {
        paddingHorizontal: smallSpacingValue
    },
    smallPaddingVertical: {
        paddingVertical: smallSpacingValue
    },
    xxsPaddingVertical: {
        paddingVertical: xxsSpacingValue
    },
    basePaddingHorizontal: {
        paddingHorizontal: baseSpacingValue
    },
    basePaddingVertical: {
        paddingVertical: baseSpacingValue
    },
    basePaddingBottom: {
        paddingBottom: baseSpacingValue
    },
    baseMarginVertical: {
        marginVertical: baseSpacingValue
    },
    baseMarginHorizontal: {
        marginHorizontal: baseSpacingValue
    },
    baseMarginBottom: {
        marginBottom: baseSpacingValue
    },
    baseMarginTop: {
        marginTop: baseSpacingValue
    },
    smallMarginVertical: {
        marginVertical: smallSpacingValue
    },
    smallMarginBottom: {
        marginBottom: smallSpacingValue
    },
    largeMarginTop: {
        marginTop: xxlSpacingValue
    },
    largeMarginBottom: {
        marginBottom: xxlSpacingValue
    },
    largeMarginRight: {
        marginRight: largeSpacingValue
    },
    largeMarginHorizontal: {
        marginHorizontal: largeSpacingValue
    },
    smallMarginHorizontal: {
        marginHorizontal: smallSpacingValue
    },
    xsMarginRight: {
        marginRight: xsSpacingValue
    },
    smallMarginLeft: {
        marginLeft: smallSpacingValue
    },
    smallMarginRight: {
        marginRight: smallSpacingValue
    },
    noMarginTop: {
        marginTop: 0
    },
    marginAuto: {
        marginHorizontal: 'auto'
    },
    noPaddingTop: {
        paddingTop: 0
    },
    noPaddingBottom: {
        paddingBottom: 0
    }
}
export default spacingStyles;
