import { useEffect, useState } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, ScrollView, TouchableOpacity } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import DraggableFlatList, { RenderItemParams, OpacityDecorator } from 'react-native-draggable-flatlist';
import { inject, observer } from 'mobx-react';
import { Ionicons } from '@expo/vector-icons';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import BackButton from 'components/Button/BackButton';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';

import { ScreensParamList, RouteStackParamList, GroupStoreType, PageRecordType, UserStoreType } from 'types';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';
import { primary } from 'utils/Constants';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupPageSettingList'>;
  route: RouteProp<RouteStackParamList, 'groupPageSettingList'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};


function GroupPageSettingList(props: Props) {
  const { route } = props;
  const { params: { groupId } } = route;
  const { navigation: { navigate, goBack } } = props;

  const { store: { groupStore, userStore } } = props;
  const { activeGroup, fetchGroupPages, setActivePage, groupPages } = groupStore;
  const { session } = userStore;

  const [pagesList, setPagesList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const updateSetting = async () => {
    setLoading(true);
    setLoading(false);
  };

  // - Get all tasks list
  // - Get tasks selected for group
  const init = async () => {
    setLoading(true);
    fetchGroupPages(session);
    setLoading(false);
  }

  const onToNewPage = () => {
    setActivePage(null);
    navigate('groupPageForm', { groupId });
  }

  const renderItem = ({ item, drag, isActive }: RenderItemParams<PageRecordType>) => {
    return (
      <OpacityDecorator>
        <View
          style={[commonStyles.smallPaddingHorizontal, commonStyles.smallPaddingVertical, commonStyles.flex, commonStyles.itemsCenter, commonStyles.spaceBetween]}
          >
          <TouchableOpacity onLongPress={drag} style={commonStyles.flexGrow}>
            <Text>{ item.title }</Text>
          </TouchableOpacity>
          <View style={[commonStyles.flex, commonStyles.flexRow]}>
            { activeGroup.defaultPage === item.id && <Ionicons name="home" size={24} color={primary} />}
            <Ionicons name='settings' size={24} color='black' onPress={() => navigate('groupPageForm', { groupId, pageId: item.id })}/>
          </View>
        </View>
      </OpacityDecorator>
    );
  }

  useEffect(() => {
    setPagesList(groupPages);
  }, [groupPages])

  useEffect(() => {
    init();
  }, [route]);


  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
      {
        loading ?
          <GenericViewSkeleton />
        :
          <>
            <HeadViewWithSubTitle title={ Contents.group.header.pageSetting } />
            <ScrollView style={commonStyles.flatView} contentContainerStyle={ commonStyles.flexGrow }>
              <View style={commonStyles.container}>
                <View style={commonStyles.flexGrow}>
                  <DraggableFlatList
                    data={pagesList}
                    onDragEnd={({ data }) => setPagesList(data)}
                    keyExtractor={(page) => page.id}
                    renderItem={renderItem}
                  />
                </View>
                <View style={commonStyles.buttonContainer}>
                  <DefaultButton disabled={loading} onPress={onToNewPage} fullWidth>
                    { Contents.group.btn.addPage }
                  </DefaultButton>
                  <DefaultButton disabled={loading} onPress={updateSetting} fullWidth>
                    { Contents.common.btn.update }
                  </DefaultButton>
                </View>
              </View>

            </ScrollView>
            <BackButton />
          </>
      }
      </KeyboardAvoidingView>
    </View>
  );
}

const GroupPageSettingListWithStore = withErrorBoundary(inject('store')(observer(GroupPageSettingList)));
export default GroupPageSettingListWithStore;
