import React, { useEffect, useState, useCallback } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform, ActivityIndicator } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { TextInput } from 'react-native-paper';
import { inject, observer } from 'mobx-react';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useToast } from 'react-native-toast-notifications';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';
import BackButton from 'components/Button/BackButton';
import GroupStoreViewSkeleton from 'components/Skeleton/GroupStoreViewSkeleton';
import RolesTreeComponent from 'components/RolesTreeComponent';

import TreeService from 'lib/Services/tree.service';
import BaseDataService from 'lib/Services/baseData.service';
import RoleService from 'lib/Services/role.service';
import useStorePages from 'lib/Hooks/useStorePages';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';
import { ScreensParamList, RouteStackParamList, TreeElement, NodeElement, StoreStoreType, UserStoreType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


const roleService = new RoleService();
const roleDataService = new BaseDataService('roles');

type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeRoleForm'>;
  route: RouteProp<RouteStackParamList, 'storeRoleForm'>;
  store: { userStore: UserStoreType, storeStore: StoreStoreType };
};



function StoreRoleForm(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { storeId, roleId, pages, name } } } = props;
  const { store: { userStore: { session }, storeStore: { activeStore }  } } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [roleName, setRoleName] = useState<string>('');
  const [rolesTree, setRolesTree] = useState<TreeElement[]>([]);
  const [nodesList, setNodesList] = useState<NodeElement[]>([]);
  const [selectedNodesList, setSelectedNodesList] = useState<NodeElement[]>([]);
  const [confirmToRemove, setConfirmToRemove] = useState<boolean>(false);

  const { storePages } = useStorePages(storeId);

  const isFocused = useIsFocused();
  const toast = useToast();

  const init = useCallback(async () => {
    if (!storePages) return;
    const { tree, nodes } = TreeService.generateTree(storePages);
    setRolesTree(tree);
    setNodesList(nodes);

    // Handle params from router, for editing role case.
    const pagesObject = pages ? pages.reduce((acc, page) => ({...acc, [page.id]: page}), {}) : {};
    const { nodes: selectedNodes } = TreeService.generateTree(pagesObject);
    setSelectedNodesList(selectedNodes);

    setRoleName(name || '');
  }, [storePages, pages, name]);

  const onSave = async () => {
    if (!storePages) return;
    const mappedPages = TreeService.convertTreeToRecords(rolesTree, selectedNodesList, storePages);
    setLoading(true);
    if (roleId) {
      // Update Role
      try {
        const res = await roleDataService.update(roleId, { store: storeId, name: roleName, pages: mappedPages, roleId }, session);
        if (res) { // TODO: Better check for rest action success
          toast.show(Contents.store.toast.roleUpdateSuccess, { type: 'success' });
        } else {
          toast.show(Contents.store.toast.apiError, { type: 'error' });
        }
      } catch(error) {
        console.log("Error", error);
        toast.show(Contents.store.toast.apiError, { type: 'error' });
      }
    } else {
      // Create a new role
      try {
        const res = await roleService.createRole({ store: storeId, name: roleName, pages: mappedPages }, session);
        if (res) {
          toast.show(Contents.store.toast.roleCreateSuccess, { type: 'success' });
          navigate('storeRolesList', { storeId });
        } else {
          toast.show(Contents.store.toast.apiError, { type: 'error' });
        }
      } catch(error) {
        console.log("Error", error);
        toast.show(Contents.store.toast.apiError, { type: 'error' });
      }
    }
    setLoading(false);
  }

  const onDelete = async () => {
    if (!roleId) return;
    setLoading(true);
    // Create a new role
    try {
      const res = await roleDataService.delete(roleId, session);
      if (res) {
        toast.show(Contents.store.toast.roleDeleteSuccess, { type: 'success' });
        navigate('storeRolesList', { storeId });
      } else {
        toast.show(Contents.store.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.store.toast.apiError, { type: 'error' });
    }
    setLoading(false);
  }

  useEffect(() => {
    isFocused && init();
  }, [isFocused, init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        {
          loading ?
            <GroupStoreViewSkeleton heading={ Contents.store.header.rolesForm } />
          :
            <>
              <HeadViewWithSubTitle
                title={ Contents.store.header.rolesForm }
                subTitle={ activeStore.name || '' }
                />
              <ScrollView style={[commonStyles.normalBorderRadius, commonStyles.whiteBackground, commonStyles.basePaddingHorizontal, commonStyles.basePaddingVertical]}
                contentContainerStyle={commonStyles.flexGrow}>
                <View style={commonStyles.flexGrow}>
                  <TextInput
                    mode='outlined'
                    style={{ marginBottom: 15 }}
                    label={Contents.store.placeholders.roleName}
                    value={roleName}
                    onChangeText={(str) => setRoleName(str)}
                  />
                  <RolesTreeComponent
                    rolesTree={rolesTree}
                    nodesList={nodesList} selectedNodesList={selectedNodesList}
                    updateSelectedNodesList={setSelectedNodesList}
                  />
                </View>
                <View style={commonStyles.buttonContainer}>
                  <DefaultButton onPress={onSave} fullWidth>
                    { roleId ? Contents.common.btn.update : Contents.common.btn.save }
                  </DefaultButton>
                  {
                    roleId ?
                    <DangerousActionButton onPress={() => setConfirmToRemove(true)} fullWidth>
                      { Contents.common.btn.delete }
                    </DangerousActionButton>
                    :
                    <></>
                  }
                </View>
              </ScrollView>
              <BackButton />
            </>
        }
        <AwesomeAlert
          show={confirmToRemove}
          title={ Contents.store.confirm.title }
          message={ Contents.store.confirm.removeRole }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmToRemove(false);
          }}
          onConfirmPressed={onDelete}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

const StoreRoleFormWithStore = withErrorBoundary(inject('store')(observer(StoreRoleForm)));
export default StoreRoleFormWithStore
