import { useEffect, useState, useCallback } from 'react';
import { View, ScrollView, Text, KeyboardAvoidingView, Platform } from 'react-native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import { FormBuilder } from 'react-native-paper-form-builder';
import { useForm } from 'react-hook-form';
import type { LogicProps } from 'react-native-paper-form-builder/dist/Types/Types';
import { useToast } from 'react-native-toast-notifications';
import { inject, observer } from 'mobx-react';
import AwesomeAlert from 'react-native-awesome-alerts';

import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';
import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';
import BackButton from 'components/Button/BackButton';

import BaseDataService from 'lib/Services/baseData.service';
import UserTaskService from 'lib/Services/userTask.service';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, StoreStoreType, UserStoreType, ConfirmationDialogType } from 'types';

import { commonStyles } from 'styles';
import Contents from 'utils/contents';


type Props = {
  navigation: NavigationProp<ScreensParamList, 'storeUserForm'>;
  route: RouteProp<RouteStackParamList, 'storeUserForm'>;
  store: { storeStore: StoreStoreType, userStore: UserStoreType };
};

const userTaskService = new UserTaskService();
const userStoreDataService = new BaseDataService('user_store');
const storeDataService = new BaseDataService('store');

function StoreUserForm(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route } = props;
  const { params: { storeId } } = route;

  const { store: { storeStore, userStore } } = props;
  const { activeStoreUser, activeStore, fetchStore, roles, isCurrentUserManager, isCurrentUserOwner, setActiveStoreUser } = storeStore;
  const { session } = userStore;

  const [loading, setLoading] = useState<boolean>(false);
  const [onboardingStatus, setOnboardingStatus] = useState<string>('');
  const [formConfig, setFormConfig] = useState<Omit<LogicProps, 'control'>[]>([]);
  const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogType>('');
  const [confirmationDialogMessage, setConfirmationDialogMessage] = useState<string>('');
  const [ableToUpdate, setAbleToUpdate] = useState<boolean>(false);

  const {control, setFocus, handleSubmit, reset, formState: { isValid }} = useForm({
    defaultValues: {
      roleId: activeStoreUser?.roleId,
    },
    mode: 'onChange',
  });
  const toast = useToast();

  // - Get all tasks list
  // - Get tasks selected for group
  const init = useCallback(async () => {
    if (!activeStoreUser) return;
    setLoading(true);
    setAbleToUpdate(false);
    let roleOptions = roles.map((role: any) => ({value: role.id , label: role.name}))
    roleOptions = [{ value: '', label: '' }, ...roleOptions];

    setFormConfig([{
      type: 'select',
      name: 'roleId',
      defaultValue: activeStoreUser.roleId,
      textInputProps: {
        label: Contents.group.placeholders.role,
        autoComplete: false,
      },
      options: roleOptions,
    }]);

    const data = await userTaskService.list(activeStoreUser.userId, activeStoreUser.groupId, session);

    if (data && Object.keys(data).length > 0) {
      const unfinishedTasks = Object.keys(data).map((key: string) => data[key].done === false);
      setOnboardingStatus(unfinishedTasks.length > 0 ? 'Onboarding' : 'Crew')
    } else {
      setOnboardingStatus('');
    }
    reset({ roleId: activeStoreUser.roleId || '' });

    if (activeStoreUser.isManager)
      setAbleToUpdate(isCurrentUserOwner);
    else
      setAbleToUpdate(isCurrentUserManager || isCurrentUserOwner);

    setLoading(false);
  }, [activeStoreUser]);

  const onUpdateUserForm = async (body: any) => {
    if (!activeStoreUser) return;
    try {
      await userStoreDataService.update(activeStoreUser.id, body, session)
      toast.show(Contents.group.toast.userUpdateSuccess, { type: 'success' });
    } catch (error) {
      console.log('Update user error', error);
      return;
    }
  };

  // Main User Action handler
  const onHandleDefaultUserActions = async() => {
    if (!activeStoreUser) return;
    setLoading(true);
    const managerIds = activeStore.managers || []
    switch(confirmationDialogType) {
      case 'delete':
        await userStoreDataService.delete(activeStoreUser.id, session);
        break;
      case 'setManager':
        let newManagerIds = [...new Set([...managerIds, activeStoreUser.userId])];
        await storeDataService.update(activeStore.id, { managers: newManagerIds });
        break;
    }
    await afterAction();
  }

  const onRevokeManager = async () => {
    if (!activeStoreUser) return;
    setLoading(true);
    const managerIds = activeStore.managers || [];
    let updatedManagerIds = managerIds.filter((managerId: string) => managerId !== activeStoreUser.userId);
    await storeDataService.update(activeStore.id, { managers: updatedManagerIds });
    await afterAction();
  }

  const afterAction = async() => {
    await fetchStore(storeId, session);
    setConfirmationDialogType('');
    navigate('storeUsersList', { storeId });
  }

  /* User row action handlers, openining confirmation dialog */
  const onTriggerSetManager = () => {
    setConfirmationDialogType('setManager');
    setConfirmationDialogMessage(Contents.store.confirm.setAsManager);
  }

  const onTriggerRevokeManager = () => {
    setConfirmationDialogType('revokeManager');
    setConfirmationDialogMessage(Contents.group.confirm.revokeManager);
  }

  const onTriggerDeleteUser = () => {
    setConfirmationDialogType('delete');
    setConfirmationDialogMessage(Contents.group.confirm.removeUser);
  }



  useEffect(() => {
    init();
  }, [init]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        <HeadViewWithSubTitle
          title={ Contents.store.header.editStoreUserRole }
          subTitle={activeStore.name} />
        {
          loading ?
            <GenericViewSkeleton />
          :
            <>
              <ScrollView style={commonStyles.flatView} contentContainerStyle={[commonStyles.flexGrow]}>
                <View style={commonStyles.container}>
                  <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
                    <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
                      Name
                    </Text>
                    <Text style={commonStyles.baseFontSize}>
                      { activeStoreUser?.user.name.join(' ') || '' }
                    </Text>
                  </View>
                  <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
                    <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
                      Email
                    </Text>
                    <Text style={commonStyles.baseFontSize}>
                      { activeStoreUser?.user?.email }
                    </Text>
                  </View>
                  <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
                    <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
                      Phone
                    </Text>
                    <Text style={commonStyles.baseFontSize}>
                      { activeStoreUser?.user?.phoneNumber }
                    </Text>
                  </View>
                  <View style={[commonStyles.flex, commonStyles.flexRow, commonStyles.basePaddingHorizontal, commonStyles.smallPaddingVertical]}>
                    <Text style={[commonStyles.boldText, commonStyles.baseFontSize, commonStyles.smallMarginRight]}>
                      Status
                    </Text>
                    <Text style={commonStyles.baseFontSize}>
                      { onboardingStatus }
                    </Text>
                  </View>
                  <View style={[commonStyles.formWrapper, commonStyles.basePaddingHorizontal, commonStyles.flexGrow]}>
                    {
                      ableToUpdate &&
                        <FormBuilder
                          control={control}
                          setFocus={setFocus}
                          formConfigArray={formConfig}
                        />
                    }
                  </View>
                </View>
                <View style={commonStyles.buttonContainer}>
                  {
                    isCurrentUserOwner ?
                      activeStoreUser?.isManager ?
                        <DangerousActionButton disabled={!isValid} onPress={onTriggerRevokeManager} fullWidth>
                          { Contents.store.btn.revokeManager }
                        </DangerousActionButton>
                      :
                        <DefaultButton disabled={!isValid} onPress={onTriggerSetManager} fullWidth>
                          { Contents.store.btn.setAsManager }
                        </DefaultButton>
                    :
                      <></>
                  }
                  {
                    ableToUpdate &&
                    <DefaultButton disabled={!isValid} onPress={handleSubmit(onUpdateUserForm)} fullWidth>
                      { Contents.common.btn.save }
                    </DefaultButton>
                  }
                  {
                    !activeStoreUser.isManager ?
                      <DangerousActionButton onPress={onTriggerDeleteUser} fullWidth>
                        { Contents.group.btn.removeUser }
                      </DangerousActionButton>
                    :
                      <></>
                  }
                </View>
              </ScrollView>
              <BackButton />
            </>
        }
        <AwesomeAlert
          show={!!activeStoreUser && (confirmationDialogType === 'setManager' || confirmationDialogType === 'delete')}
          showProgress={false}
          title={ Contents.store.confirm.title }
          message={ confirmationDialogMessage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogType('');
          }}
          onConfirmPressed={onHandleDefaultUserActions}
        />
        <AwesomeAlert
          show={confirmationDialogType === 'revokeManager'}
          showProgress={false}
          title={ Contents.store.confirm.title }
          message={ confirmationDialogMessage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmationDialogType('');
          }}
          onConfirmPressed={onRevokeManager}
        />
      </KeyboardAvoidingView>
    </View>
  );
}

const StoreUserFormWithStore = withErrorBoundary(inject('store')(observer(StoreUserForm)));
export default StoreUserFormWithStore;
