export default {
  txt: {
    header: 'Profile',
  },
  placeholders: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    birthDate: 'Birth Date',
    parentName: 'Emergency Contact Name',
    parentPhoneNumber: 'Emergency Contact Phone Number',
    parentEmail: 'Emergency Contact Email',
    timezone: 'Timezone',
  },
  btn: {
    save: 'Save'
  },
  validationErrors: {
    firstNameRequired: 'First Name is required',
    lastNameRequired: 'Last Name is required',
    emailRequired: 'Email is required',
    emailPattern: 'Email is invalid',
    phoneNumber: 'Phone Number is required',
    birthDate: 'Birth Date is required',
    parentName: 'Emergency Contact Name is required',
    parentPhoneNumber: 'Emergency Contact Phone Number is required',
    parentEmail: 'Emergency Contact Email is required',
  },
  toast: {
    updateSuccess: 'Profile has been updated successfully',
    updateError: 'Update Profile Error'
  }
};