import { useEffect } from 'react';
import { LogBox } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'mobx-react';
import AppLoading from 'expo-app-loading';
import { Provider as PaperProvider } from 'react-native-paper';
import ErrorBoundary from 'react-native-error-boundary'
import { ToastProvider } from 'react-native-toast-notifications';
import { rootStore, trunk } from './src/Mobx';
import RootNavigator from './src/Navigator/RootNavigator';
import { customFonts, Theme, useFonts } from './src/Utils/Styles';
import 'react-native-gesture-handler';

LogBox.ignoreAllLogs(true);

export default function App() {
	let [fontsLoaded] = useFonts(customFonts);

	useEffect(() => {
		const rehydrate = async () => {
			await trunk.init();
		};
		rehydrate();
	}, []);
	if (!fontsLoaded) {
		return <AppLoading />;
	} else {
		return (
			<Provider store={rootStore}>
				<PaperProvider theme={Theme}>
					<SafeAreaProvider>
						<ToastProvider>
							
								<RootNavigator />
							
						</ToastProvider>
					</SafeAreaProvider>
				</PaperProvider>
			</Provider>
		);
	}
}
