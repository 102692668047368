import { useEffect, useState, useCallback } from 'react';
import { View, Text, Switch, KeyboardAvoidingView, Platform, TouchableOpacity, ScrollView } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import type { NavigationProp, RouteProp } from '@react-navigation/native';
import DraggableFlatList, { RenderItemParams } from 'react-native-draggable-flatlist';
import { TextInput } from 'react-native-paper';
import { inject, observer } from 'mobx-react';
import { useToast } from 'react-native-toast-notifications';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import { default as slugify } from 'slugify';

import HeadViewWithSubTitle from 'components/HeadViewWithSubTitle';
import DefaultButton from 'components/Button/DefaultButton';
import DangerousActionButton from 'components/Button/DangerousActionButton';
import BackButton from 'components/Button/BackButton';
import GenericViewSkeleton from 'components/Skeleton/GenericViewSkeleton';

import BaseDataService from 'lib/Services/baseData.service';
import PageService from 'lib/Services/page.service';
import useGroupPageDetail from 'lib/Hooks/useGroupPageDetail';
import withErrorBoundary from 'lib/HoC/withErrorBoundary';

import { ScreensParamList, RouteStackParamList, SectionRecordType } from 'types';
import { PageRecordType, GroupStoreType, UserStoreType } from 'types';
import { commonStyles } from 'styles';
import Contents from 'utils/contents';


const pageService = new PageService();
const groupService = new BaseDataService('group');


type Props = {
  navigation: NavigationProp<ScreensParamList, 'groupPageForm'>;
  route: RouteProp<RouteStackParamList, 'groupPageForm'>;
  store: { groupStore: GroupStoreType, userStore: UserStoreType };
};

function GroupPageForm(props: Props) {
  const { navigation: { navigate, goBack } } = props;
  const { route: { params: { groupId, pageId } } } = props;
  const { store: { groupStore, userStore } } = props;
  const { activeGroup, groupPages, setActivePage, activePage, updateActiveGroup } = groupStore;
  const { session } = userStore;
  const isFocused = useIsFocused();

  const [loading, setLoading] = useState<boolean>(false);
  const [pageName, setPageName] = useState<string>('');
  const [sectionList, setSectionList] = useState<SectionRecordType[]>([]);
  const [isAdding, setAdding] = useState<boolean>(false);
  const [sectionTitle, setSectionTitle] = useState<string>('');
  const [activeSection, setActiveSection] = useState<SectionRecordType | null>(null);
  const [confirmToRemoveSection, setConfirmToRemoveSection] = useState<boolean>(false);
  const [confirmToRemovePage, setConfirmToRemovePage] = useState<boolean>(false);
  const [hasBackButton, setHasBackButton] = useState(true);

  const { groupPageDetail } = useGroupPageDetail(groupId, pageId);

  const toast = useToast();

  const onCreatePage = async() => {
    if (!pageName) {
      toast.show(Contents.group.toast.noPageTitleWarning, { type: 'warning' });
      return;
    }
    const data = {
      title: pageName,
      section: sectionList
    };
    try {
      const res = await pageService.createPage('group', groupId, data, session);
      if (res) {
        toast.show(Contents.group.toast.pageCreateSuccess, { type: 'success' });
        navigate('groupPageSettingList', { groupId });
      } else {
        toast.show(Contents.group.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.group.toast.apiError, { type: 'error' });
    }
  }

  const onUpdatePage = async() => {
    if (!pageId) return;
    if (!pageName) {
      toast.show(Contents.group.toast.noPageTitleWarning, { type: 'warning' });
      return;
    }
    const data = {
      title: pageName,
      section: sectionList
    };
    try {
      const res = await pageService.updatePage('group', groupId, pageId, data, session);
      if (res) {
        toast.show(Contents.group.toast.pageUpdateSuccess, { type: 'success' });
        navigate('groupPageSettingList', { groupId });
      } else {
        toast.show(Contents.group.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.group.toast.apiError, { type: 'error' });
    }
  }

  const onMakeDefaultPage = async () => {
    if (!pageId) return;
    try {
      const res = await groupService.update(groupId, { defaultPage: pageId }, session);
      if (res) {
        updateActiveGroup({ defaultPage: pageId });
        toast.show(Contents.group.toast.pageUpdateSuccess, { type: 'success' });
        navigate('groupPageSettingList', { groupId });
      } else {
        toast.show(Contents.group.toast.apiError, { type: 'error' });
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.group.toast.apiError, { type: 'error' });
    }
  }

  const onSave = async () => {
    if (pageId) {
      await onUpdatePage();
    } else {
      await onCreatePage();
    }
  }

  const onDelete = async() => {
    try {
      if (pageId) {
        const res = await pageService.deletePage('group', groupId, pageId, session);
        setConfirmToRemovePage(false);
        if (res) {
          toast.show(Contents.group.toast.pageDeleteSuccess, { type: 'success' });
          navigate('groupPageSettingList', { groupId });
        } else {
          toast.show(Contents.group.toast.apiError, { type: 'error' });
        }
      }
    } catch(error) {
      console.log("Error", error);
      toast.show(Contents.group.toast.apiError, { type: 'error' });
    }
    setLoading(false);
  }

  const onAddSection = async() => {
    if (!sectionTitle) return;
    const newSection: SectionRecordType = { slug: slugify(sectionTitle), title: sectionTitle };
    setActivePage({ ...activePage, section: [...sectionList, newSection] }, session, true);
    setSectionTitle('');
    setAdding(false);
  }

  const onOpenSectionForm = (item: SectionRecordType) => {
    navigate('groupSectionForm', { groupId, pageId: pageId || '', activeSection: item })
  }

  const onDeleteSection = () => {
    if (activeSection) {
      const updatedSections: SectionRecordType[] = sectionList.filter(s => s.slug !== activeSection.slug);
      setConfirmToRemoveSection(false)
      setActiveSection(null);
      setActivePage({ ...activePage, section: updatedSections }, session, true);
    } else
      setConfirmToRemoveSection(false)
  }

  const toggleSwitch = () => {
    setHasBackButton(hasBackButton => {
      setActivePage({ ...activePage, hasBackButton: !hasBackButton }, session, true);
      return !hasBackButton;
    });
  }

  const renderItem = ({ item, drag, isActive }: RenderItemParams<SectionRecordType>) => {
    return (
      <View
        style={[commonStyles.smallPaddingHorizontal, commonStyles.smallPaddingVertical, commonStyles.flex, commonStyles.itemsCenter, commonStyles.spaceBetween]}
      >
        <TouchableOpacity onLongPress={drag} style={commonStyles.flexGrow}>
          <Text>{ item.title }</Text>
        </TouchableOpacity>
        <Ionicons name='settings' size={24} style={commonStyles.smallPaddingHorizontal} color='black' onPress={() => onOpenSectionForm(item)}/>
        <AntDesign name='delete' size={24} color='black' onPress={() => { setActiveSection(item); setConfirmToRemoveSection(true)}} />
      </View>
    );
  }

  useEffect(() => {
    if (isFocused) {
      setPageName(activePage?.title || '');
      setSectionList(activePage?.section || [])
      setHasBackButton(typeof activePage?.hasBackButton === 'boolean' ? activePage?.hasBackButton : true)
      setConfirmToRemoveSection(false)
      setActiveSection(null)
    }
  }, [isFocused, activePage]);

  useEffect(() => {
    setActivePage(groupPageDetail)
  }, [groupPageDetail]);

  return (
    <View style={[commonStyles.viewport]}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={commonStyles.keyboardAvoidingView}
      >
        {
          loading ?
            <GenericViewSkeleton />
          :
            <>
              <HeadViewWithSubTitle title={ Contents.group.header.pagesForm } />
              <ScrollView style={commonStyles.flatView} contentContainerStyle={ commonStyles.flexGrow }>
                <View style={commonStyles.container}>
                  <TextInput
                    mode='outlined'
                    style={{ marginBottom: 15 }}
                    label={Contents.group.placeholders.pageName}
                    value={pageName}
                    onChangeText={(str) => setPageName(str)}
                  />
                  <View style={[commonStyles.flex, commonStyles.spaceBetween, commonStyles.basePaddingHorizontal, commonStyles.baseMarginBottom]}>
                    <Text style={[commonStyles.baseFontSize]}>
                      Back Button
                    </Text>
                    <Switch
                      trackColor={{ false: "#767577", true: "#81b0ff" }}
                      thumbColor={"#f5dd4b"}
                      ios_backgroundColor="#3e3e3e"
                      onValueChange={toggleSwitch}
                      value={hasBackButton}
                      />
                  </View>
                  {
                    pageId ?
                    <View style={[commonStyles.flexGrow, commonStyles.basePaddingBottom]}>
                      {
                        isAdding ?
                          <View style={[commonStyles.flex, commonStyles.spaceBetween, commonStyles.itemsCenter]}>
                            <TextInput
                              mode='outlined'
                              style={commonStyles.flexGrow}
                              label={Contents.group.placeholders.sectionTitle}
                              value={sectionTitle}
                              onChangeText={(str) => setSectionTitle(str)}
                            />
                            <TouchableOpacity onPress={onAddSection} style={[commonStyles.smallPaddingHorizontal]}>
                              <AntDesign name='check' size={24} color='black' />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => { setAdding(false); setSectionTitle('') }} style={[commonStyles.smallPaddingHorizontal]}>
                              <AntDesign name='close' size={24} color='black' />
                            </TouchableOpacity>
                          </View>
                        :
                          <DefaultButton onPress={() => setAdding(true)} fullWidth>
                            { Contents.group.btn.addSection }
                          </DefaultButton>
                      }
                      {
                        <DraggableFlatList
                          data={sectionList}
                          onDragEnd={({ data }) => setActivePage({ ...activePage, section: data })}
                          keyExtractor={(section) => section.slug}
                          renderItem={renderItem}
                        />
                      }
                    </View>
                    :
                    <View style={[commonStyles.flexGrow, commonStyles.basePaddingBottom]}></View>
                  }
                  <View style={commonStyles.buttonContainer}>
                    <DefaultButton onPress={onSave} fullWidth>
                      { pageId ? Contents.common.btn.update : Contents.common.btn.save }
                    </DefaultButton>
                    <DefaultButton onPress={onMakeDefaultPage} fullWidth disabled={activeGroup.defaultPage === pageId}>
                      { activeGroup.defaultPage === pageId ? Contents.group.btn.alreadyDefaultPage : Contents.group.btn.makeDefaultPage }
                    </DefaultButton>
                    {
                      pageId ?
                      <DangerousActionButton onPress={() => setConfirmToRemovePage(true)} fullWidth>
                        { Contents.common.btn.delete }
                      </DangerousActionButton>
                      :
                      <></>
                    }
                  </View>
                </View>
              </ScrollView>
              <BackButton />
            </>
        }

        <AwesomeAlert
          show={confirmToRemoveSection}
          title={ Contents.group.confirm.title }
          message={ Contents.group.confirm.removeSection }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmToRemoveSection(false);
            setActiveSection(null);
          }}
          onConfirmPressed={() => {
            onDeleteSection();
          }}
        />
        <AwesomeAlert
          show={confirmToRemovePage}
          title={ Contents.group.confirm.title }
          message={ Contents.group.confirm.removePage }
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={true}
          showConfirmButton={true}
          confirmButtonColor="#DD6B55"
          onCancelPressed={() => {
            setConfirmToRemovePage(false);
          }}
          onConfirmPressed={() => {
            onDelete();
          }}
        />
      </KeyboardAvoidingView>
    </View>
  )
}

const GroupPageFormWithStore = withErrorBoundary(inject('store')(observer(GroupPageForm)));
export default GroupPageFormWithStore;
